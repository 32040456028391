import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {PageService} from '../_services/page.service';
import {Page} from '@nxt/model-core';
import {CommonModule} from '@angular/common';
import {LoadableModule} from './dynamic.loader.component';

@Component({
    standalone: true,
    imports: [CommonModule, LoadableModule],
    selector: 'flex-page-component',
    template: `
        <ng-container *ngIf="!(pSvc.loading$ | async) && page?.content?.sections">

            <ng-container *ngFor="let section of page.content.sections">

                <a *ngIf="section?.opts?.anchor" [id]="section.opts.anchor"></a>
                <div *ngIf="!section.column && section.column!==0 && !section.hide"
                     class="{{ section?.opts?.class }}"
                     style="{{ section?.opts?.styles }}"
                     [style.backgroundColor]="section?.opts?.bg_color"
                >
                    <lib-dynamic-loader-component
                            [component]="section.selector"
                            [page]="page" [section]="section"></lib-dynamic-loader-component>
                </div>

            </ng-container>

            <div [class]="page.content.columnWrapper">

                <div *ngFor="let col of page.content.columns; let i = index;" [class]="(col) ? col : ''">

                    <ng-container *ngFor="let section of page.content.sections">

                        <a *ngIf="section?.opts?.anchor" [id]="section.opts.anchor"></a>
                        <div *ngIf="section.column===i && !section.hide"
                             class="{{ section?.opts?.class }}"
                             style="{{ section?.opts?.styles }}"
                             [style.backgroundColor]="section?.opts?.bg_color"
                        >
                            <lib-dynamic-loader-component
                                    [component]="section.selector"
                                    [page]="page" [section]="section"
                            ></lib-dynamic-loader-component>
                        </div>

                    </ng-container>
                </div>

            </div>

            <ng-container *ngFor="let section of page.content.sections">

                <a *ngIf="section?.opts?.anchor" [id]="section.opts.anchor"></a>
                <div *ngIf="section.column==='below' && !section.hide"
                     class="{{ section?.opts?.class }}"
                     style="{{ section?.opts?.styles }}"
                     [style.backgroundColor]="section?.opts?.bg_color"
                >
                    <lib-dynamic-loader-component
                            [component]="section.selector"
                            [page]="page" [section]="section"
                    ></lib-dynamic-loader-component>
                </div>
                
            </ng-container>
        </ng-container>
    `
})
export class FlexComponent {
    @Input() page: Page;

    constructor(public pSvc: PageService) {
    }
}
