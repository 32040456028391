import {Injectable} from '@angular/core';

import { Affiliate} from '@nxt/model-laro';
import {ClientService} from '../../shared/_services/client.service';
import {PageService} from '../../shared/_services/page.service';
import {FireService} from '../../nxt/_services/fire.service';
import {Client} from '@nxt/model-core';

@Injectable()
export class LaroMarketplaceService{
    private affiliates: any = {};

    constructor(
        private pSvc: PageService,
        private fSvc: FireService,
        private cSvc: ClientService
    ) {

    }

    async loadAffiliates(client: Client) {
        if (client?.name_key) {
            if (!this.affiliates[client.name_key] || !this.affiliates[client.name_key]?.length) {
                this.affiliates[client.name_key] = (await this.cSvc.callAPI('/laro/marketplace/affiliates/list', 'get'))?.reduce((list,a) => {
                    a = new Affiliate(a);
                    if (a.approved && !a.declined) {
                        a.client_ids?.forEach(id => {
                            if (!list.includes(id)) {
                                list.push(id);
                            }
                        })
                    }
                    return list;
                }, []);
                !this.affiliates[client.name_key].push(client.id);
            }
            return this.affiliates[client.name_key];
        }
    }

}

