"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TRIP_STATUS_MAP = exports.ETripStatus = exports.ETripType = exports.TripRequest = exports.ERequestSource = exports.Trip = exports.EPaymentTypes_Map = exports.EPaymentTypes = exports.AVIANIS_STATUS = void 0;
const model_core_1 = require("@nxt/model-core");
const Flight_1 = require("./Flight");
const index_1 = require("../index");
var AVIANIS_STATUS;
(function (AVIANIS_STATUS) {
    AVIANIS_STATUS["RELEASE"] = "released";
    AVIANIS_STATUS["ACTIVE"] = "active";
    AVIANIS_STATUS["ARRIVED"] = "arrived";
    AVIANIS_STATUS["CANCELED"] = "cancelled";
    AVIANIS_STATUS["READY"] = "readyForRelease";
})(AVIANIS_STATUS || (exports.AVIANIS_STATUS = AVIANIS_STATUS = {}));
var EPaymentTypes;
(function (EPaymentTypes) {
    EPaymentTypes["CHECK"] = "check";
    EPaymentTypes["ACH"] = "ach";
    EPaymentTypes["CARD"] = "cc";
    EPaymentTypes["CASH"] = "cash";
    EPaymentTypes["TBD"] = "tbd";
    EPaymentTypes["NONE"] = "n/a";
})(EPaymentTypes || (exports.EPaymentTypes = EPaymentTypes = {}));
exports.EPaymentTypes_Map = {
    'check': 'Check',
    'ach': 'Bank Transfer/Wire/ACH',
    'cc': 'Credit Card',
    'cash': 'Cash',
    'tbd': 'TBD',
    'n/a': 'None Selected'
};
class Trip extends Flight_1.Flight {
    get crewSummary() {
        var _a, _b, _c;
        let result = [];
        let airports = [];
        let date = '';
        if ((_a = this.legs) === null || _a === void 0 ? void 0 : _a.length) {
            this.sortLegs();
            let second;
            for (let leg of this.legs) {
                if (!leg['hide']) {
                    if (!date) {
                        date = leg.times.takeoffDate;
                    }
                    if (((_b = leg.aircraft) === null || _b === void 0 ? void 0 : _b.tail_number) && !airports.find(a => { var _a; return a.match((_a = leg.aircraft) === null || _a === void 0 ? void 0 : _a.tail_number); })) {
                        airports.push(`${(_c = leg.aircraft) === null || _c === void 0 ? void 0 : _c.tail_number}:${leg.departureAirport.iata || leg.departureAirport.icao}`);
                    }
                    else {
                        airports.push(leg.departureAirport.iata || leg.departureAirport.icao);
                    }
                    second = leg.arrivalAirport.iata;
                }
            }
            if (airports.length === 1) {
                airports.push(second);
            }
        }
        if (date) {
            result.push(date);
        }
        return `${result.join(' | ')} | ${airports.join(' ')}`;
    }
    get subjectSummary() {
        var _a, _b;
        let result = [`Trip ${this.trip_number ? '#' + this.trip_number : ''}`];
        let legs = [];
        let date = '';
        if ((_a = this.legs) === null || _a === void 0 ? void 0 : _a.length) {
            this.sortLegs();
            let n = 0;
            for (let leg of this.legs) {
                if (!leg.isEmptyLeg && leg.active && !leg['hide']) {
                    if (!date) {
                        date = leg.times.takeoffDate;
                    }
                    switch (this.legs.length) {
                        case 2:
                            if (n === 0) {
                                legs.push([leg.departureAirport.iata || leg.departureAirport.icao, leg.arrivalAirport.iata || leg.arrivalAirport.icao]);
                            }
                            else {
                                legs.push([leg.arrivalAirport.iata || leg.arrivalAirport.icao]);
                            }
                            break;
                        default:
                            legs.push([leg.departureAirport.iata || leg.departureAirport.icao, leg.arrivalAirport.iata || leg.arrivalAirport.icao]);
                            break;
                    }
                }
                n++;
            }
        }
        if (date) {
            result.push(date);
        }
        if ((_b = this.contact) === null || _b === void 0 ? void 0 : _b.name) {
            result.push(this.contact.name);
        }
        if (this.accountNames) {
            result.push(this.accountNames);
        }
        return `${result.join(' • ')} • ${legs.map((leg, n) => {
            return leg.join('-');
        }).join('; ')}`;
    }
    get accountNames() {
        var _a, _b;
        return ((_b = (_a = this.accounts) === null || _a === void 0 ? void 0 : _a.map((a) => a.name)) === null || _b === void 0 ? void 0 : _b.join(' | ')) || '';
    }
    get balance() {
        return (0, model_core_1.round)((this.price.total || 0) - (this.totalPaid || 0), 2);
    }
    get tails() {
        var _a, _b, _c;
        let result = [];
        if ((_a = this.legs) === null || _a === void 0 ? void 0 : _a.length) {
            for (let leg of this.legs) {
                if (((_b = leg.aircraft) === null || _b === void 0 ? void 0 : _b.tail_number) && !result.find(t => { var _a; return t === ((_a = leg.aircraft) === null || _a === void 0 ? void 0 : _a.tail_number); })) {
                    result.push((_c = leg.aircraft) === null || _c === void 0 ? void 0 : _c.tail_number);
                }
            }
        }
        return result;
    }
    get _payment_balance() {
        return (0, model_core_1.round)((this.price.total || 0) - (this._payment_amounts || 0), 2);
    }
    constructor(data, olMap, type, idLen) {
        super(data, olMap || index_1.olm, type || 'trips', idLen || 19);
        this.third_party = null;
        this.quote_number = '';
        this.quote_id = '';
        this.trip_number = '';
        this.trip_id = '';
        this.paid = false;
        this.totalPaid = null;
        this.status = ETripStatus.NONE;
        this.payment_type = EPaymentTypes.NONE;
        this.contact = null;
        this.agents = [];
        this.roles = [];
        this.followers = [];
        this.accounts = [];
        this.url = '';
        this.date_linked = { quote: '', trip: '' };
        this.name = '';
        this.estimates = [];
        this.bestMargin = null;
        this.legs = new model_core_1.ChildArray();
        this.payments = new model_core_1.ChildArray();
        this._m = ['name', 'trip_number', 'quote_number', 'status', 'price', 'paid'];
        this._s = ['quote_number', 'paid', 'name', 'departure_date', 'return_date', 'tails', 'legs', 'price', 'trip_number', 'contact', 'agents', 'accounts', 'status', 'deals'];
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, Trip);
        ;
        this.sortLegs();
    }
    calc(prop, overrides) {
        super.calc(prop, overrides);
        this.calcPayments();
    }
    calcPayments() {
        var _a;
        if ((_a = this.payments) === null || _a === void 0 ? void 0 : _a.length) {
            this.totalPaid = (0, model_core_1.round)(this.payments.reduce((n, payment) => {
                if (Number(payment.amount) > 0 && payment.paid === true) {
                    n += Number(payment.amount);
                }
                return n;
            }, 0), 2);
            this._payment_amounts = (0, model_core_1.round)(this.payments.reduce((n, payment) => {
                n += Number(payment.amount);
                return n;
            }, 0), 2);
        }
        else {
            this._payment_amounts = 0;
        }
    }
    async save(parent) {
        await this.loadAll({ default_client_id: '' });
        this.name = this.subjectSummary;
        this.token = this.token || (0, model_core_1.getSID)(16);
        return super.save(parent);
    }
}
exports.Trip = Trip;
var ERequestSource;
(function (ERequestSource) {
    ERequestSource["DealsPage"] = "d";
    ERequestSource["QuotesPage"] = "q";
    ERequestSource["BrokerEmail"] = "b";
    ERequestSource["AccountPage"] = "a";
    ERequestSource["MarketplaceDeal"] = "md";
    ERequestSource["MarketplaceQuote"] = "mq";
})(ERequestSource || (exports.ERequestSource = ERequestSource = {}));
class TripRequest extends Trip {
    constructor(data, olMap) {
        super(data, olMap || index_1.olm, 'trips', 19);
        this.notes = '';
        this.source = null;
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, TripRequest);
    }
    toJSON() {
        var _a;
        let r = super.toJSON();
        r.legs = (_a = this.legs) === null || _a === void 0 ? void 0 : _a.map(l => (l === null || l === void 0 ? void 0 : l.toJSON) ? l.toJSON() : l);
        return r;
    }
}
exports.TripRequest = TripRequest;
var ETripType;
(function (ETripType) {
    ETripType["ONE_WAY"] = "one-way";
    ETripType["ROUND_TRIP"] = "round-trip";
    ETripType["MULTI_LEG"] = "multi-leg";
})(ETripType || (exports.ETripType = ETripType = {}));
var ETripStatus;
(function (ETripStatus) {
    ETripStatus["quoteImported"] = "qImported";
    ETripStatus["quoteSubmitted"] = "qSubmitted";
    ETripStatus["quoteOutstanding"] = "qOutstanding";
    ETripStatus["quoteConfirmed"] = "qConfirmed";
    ETripStatus["quoteBrokerRequest"] = "qBroker";
    ETripStatus["tripSubmitted"] = "tSubmitted";
    ETripStatus["tripImported"] = "tImported";
    ETripStatus["tripOutstanding"] = "tOutstanding";
    ETripStatus["tripConfirmed"] = "tConfirmed";
    ETripStatus["waitingForPayment"] = "pWaiting";
    ETripStatus["paymentCompleted"] = "pPaid";
    ETripStatus["paymentIssue"] = "pIssue";
    ETripStatus["inactive"] = "inactive";
    ETripStatus["cancelled"] = "cancelled";
    ETripStatus["NONE"] = "";
})(ETripStatus || (exports.ETripStatus = ETripStatus = {}));
exports.TRIP_STATUS_MAP = {};
exports.TRIP_STATUS_MAP[ETripStatus.quoteSubmitted] = {
    label: 'Requested',
    color: '#da1f52',
    contrast: '#ffffff'
};
exports.TRIP_STATUS_MAP[ETripStatus.quoteImported] = {
    label: 'Imported',
    color: '#91a2e6',
    contrast: '#ffffff'
};
exports.TRIP_STATUS_MAP[ETripStatus.quoteBrokerRequest] = {
    label: 'Broker Request',
    color: '#753a4a',
    contrast: '#ffffff'
};
exports.TRIP_STATUS_MAP[ETripStatus.tripSubmitted] = {
    label: 'Requested',
    color: '#da1f52',
    contrast: '#ffffff'
};
exports.TRIP_STATUS_MAP[ETripStatus.tripImported] = {
    label: 'Imported',
    color: '#91a2e6',
    contrast: '#ffffff'
};
exports.TRIP_STATUS_MAP[ETripStatus.quoteOutstanding] = {
    label: 'Outstanding',
    color: '#1637b9',
    contrast: '#ffffff'
};
exports.TRIP_STATUS_MAP[ETripStatus.quoteConfirmed] = {
    label: 'Confirmed',
    color: '#4ad05c',
    contrast: '#ffffff'
};
exports.TRIP_STATUS_MAP[ETripStatus.inactive] = {
    label: 'Inactive',
    color: '#474847',
    contrast: '#a2a2a2'
};
exports.TRIP_STATUS_MAP[ETripStatus.waitingForPayment] = {
    label: 'Awaiting Payment',
    color: '#f58738',
    contrast: '#ffffff'
};
exports.TRIP_STATUS_MAP[ETripStatus.paymentIssue] = {
    label: 'Payment Issue',
    color: '#f80a0a',
    contrast: '#ffffff'
};
exports.TRIP_STATUS_MAP[ETripStatus.paymentCompleted] = {
    label: 'Paid',
    color: '#1b6226',
    contrast: '#ffffff'
};
index_1.olm.trips = (ref, map) => {
    return new Trip(ref, map);
};
