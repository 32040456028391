import {Injectable} from '@angular/core';

import {EThreadContext, MessagingService} from '../../nxt/_services/messaging.service';
import {IMenuItem, Thread, ThreadMessage} from '@nxt/model-core';
import {ThreadOptionsService} from '../../nxt/_services/thread-options.service';
import {take} from 'rxjs/operators';
import {ImportTripDialog} from '../../../laro/src/operator/_components/trip/import-trip.dialog';
import {ImportQuoteDialog} from '../../../laro/src/operator/_components/quotes/import-quote.dialog';

@Injectable()
export class LaroThreadOptionsService extends ThreadOptionsService{

    async buildThreadOptions(
        mSvc: MessagingService,
        thread: Thread,
        context: EThreadContext,
        msg?: ThreadMessage
    ): Promise<IMenuItem[]> {

        let result = await super.buildThreadOptions(mSvc,thread,context,msg);

        result.push({
            label: 'Link Quote',
            click: () => {
                this.linkQuote(mSvc, thread);
            }
        });

        result.push({
            label: 'Link Trip',
            click: () => {
                this.linkTrip(mSvc, thread);
            }
        });

        return result;
    }


    async linkTrip(mSvc: MessagingService, thread: Thread) {

        this.pSvc.modal$.next({
            component: ImportTripDialog,
            onLoaded: async (comp: ImportTripDialog) => {
                comp.ngOnChanges();
                comp.onClose.pipe(take(1))
                    .subscribe(async result => {
                        if (result?.id) {
                            let trip = await this.fSvc.getObject(`clients/${this.cSvc.client_id}/trips/${result.id}`);
                            thread.ref = trip._docRef;
                            thread.object = trip;
                            await thread.save();
                        }
                    })
            }
        });

    }

    async linkQuote(mSvc: MessagingService, thread: Thread, confirm?: boolean) {

        if (thread.object?._type === 'trips' && !confirm) {
            this.pSvc.alert$.next({
                title: 'Already Linked to Trip!',
                message: `This thread is already linked to a trip. If you want to update the Trip with its related quote number, click View Trip below and update the trip with the quote number there. If you are sure you want to move this thread to a quote, click Confirm beow.`,
                buttons: [
                    {
                        label: 'Cancel',
                        closeOnClick: true,
                    },
                    {
                        label: 'View Trip',
                        class: 'btn-accent',
                        closeOnClick: true,
                        click: () => {
                            mSvc.nav(thread);
                        }
                    },
                    {
                        label: 'Confirm',
                        click: () => {
                            this.linkQuote(mSvc, thread, true);
                        }
                    }
                ]
            });
        } else {
            this.pSvc.modal$.next({
                component: ImportQuoteDialog,
                onLoaded: async (comp: ImportQuoteDialog) => {
                    comp.ngOnChanges();
                    comp.onClose.pipe(take(1))
                        .subscribe(async result => {
                            if (result?.id) {
                                let quote = await this.fSvc.getObject(`clients/${this.cSvc.client_id}/quotes/${result.id}`);
                                thread.ref = quote._docRef;
                                thread.object = quote;
                                await thread.save();
                            }
                        })
                }
            });
        }
    }

}
