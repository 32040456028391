import {
    Component,
    OnInit,
    OnDestroy,
    ElementRef,
    ViewChild,
    Input,
    Output,
    EventEmitter,
    SimpleChanges, OnChanges, HostListener
} from '@angular/core';
import {Editor} from '@tiptap/core';
import StarterKit from '@tiptap/starter-kit';
import Highlight from '@tiptap/extension-highlight';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import {Color} from '@tiptap/extension-color';
import {IconsComponent} from "@library/shared/icons/icons.component";
import {ReactiveFormsModule, UntypedFormGroup} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {PickerComponent} from "@ctrl/ngx-emoji-mart";
import {CloudFile, round} from "@nxt/model-core";
import {PageService} from "@library/shared/_services/page.service";
import {ClientService} from "@library/shared/_services/client.service";
import {FireService} from "@library/nxt/_services/fire.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {TextStyle} from "@tiptap/extension-text-style";

@Component({
    selector: 'rich-text-editor',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        IconsComponent,
        PickerComponent,
    ],
    standalone: true,
    template: `
        <div #editorContent class="tiptap overflow-y-auto text-base leading-6 mt-3 p-4 rounded-t-lg bg-white text-black border border-gray-300"></div>
        <div class="relative">
            <div class="w-full border border-gray-300 border-t-0 rounded-b-lg bg-gray-100">
                <div class="flex flex-wrap gap-2 origin-top">
                    <button (click)="executeCommand('bold')" class="toolbar-btn"
                            [class.active]="editor?.isActive('bold')">
                        <icon name="heroicon-solid-bold" class="h-5 w-5"></icon>
                    </button>
                    <button (click)="executeCommand('italic')" class="toolbar-btn"
                            [class.active]="editor?.isActive('italic')">
                        <icon name="heroicon-solid-italic" class="h-5 w-5"></icon>
                    </button>
                    <button (click)="executeCommand('underline')" class="toolbar-btn"
                            [class.active]="editor?.isActive('underline')">
                        <icon name="heroicon-outline-underline" class="h-5 w-5"></icon>
                    </button>
                    <div class="relative">
                        <button class="toolbar-btn inline-flex items-center justify-center space-x-1 p-1"
                                (click)="openColorPicker()">
                            <span class="mt-0.5 w-5 h-5 rounded border border-gray-400"
                                  [style.background]="selectedColor"></span>
                        </button>

                        <input
                            type="color"
                            #colorPicker
                            class="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                            (input)="changeFontColor($event)"
                        />
                    </div>
                    <button (click)="toggleEmojiPicker($event)" class="toolbar-btn relative">
                        😊
                    </button>
                    <button (click)="executeCommand('highlight')" class="toolbar-btn"
                            [class.active]="editor?.isActive('highlight')">
                        <icon name="custom-highlight" class="h-5 w-5"></icon>
                    </button>
                    <button (click)="executeCommand('link')" class="toolbar-btn"
                            [class.active]="editor?.isActive('link')">
                        <icon name="heroicon-outline-link" class="h-5 w-5"></icon>
                    </button>
                    <button (click)="executeCommand('bullet-list')" class="toolbar-btn"
                            [class.active]="editor?.isActive('bulletList')">
                        <icon name="heroicon-outline-list-bullet" class="h-5 w-5"></icon>
                    </button>
                    <button class="btn-clear" (click)="fileUpload.click()">
                        <icon name="heroicon-outline-paper-clip" class="h-5 w-5"></icon>
                    </button>
<!--                        <button (click)="executeCommand('strike')" class="toolbar-btn"-->
<!--                                [class.active]="editor?.isActive('strike')">-->
<!--                            <icon name="heroicon-solid-strikethrough" class="h-5 w-5"></icon>-->
<!--                        </button>-->
<!--                        <button (click)="executeCommand('unlink')" class="toolbar-btn"-->
<!--                                [class.active]="editor?.isActive('unlink')">-->
<!--                            <icon name="heroicon-outline-x" class="h-5 w-5"></icon>-->
<!--                        </button>-->
<!--                        <button (click)="executeCommand('align-left')" class="toolbar-btn"-->
<!--                                [class.active]="editor?.isActive({ textAlign: 'left' })">-->
<!--                            <icon name="heroicon-outline-align-left" class="h-5 w-5"></icon>-->
<!--                        </button>-->
<!--                        <button (click)="executeCommand('align-center')" class="toolbar-btn"-->
<!--                                [class.active]="editor?.isActive({ textAlign: 'center' })">-->
<!--                            <icon name="heroicon-outline-align-center" class="h-5 w-5"></icon>-->
<!--                        </button>-->
<!--                        <button (click)="executeCommand('align-right')" class="toolbar-btn"-->
<!--                                [class.active]="editor?.isActive({ textAlign: 'right' })">-->
<!--                            <icon name="heroicon-outline-align-right" class="h-5 w-5"></icon>-->
<!--                        </button>-->

<!--                        <button (click)="executeCommand('ordered-list')" class="toolbar-btn"-->
<!--                                [class.active]="editor?.isActive('orderedList')">-->
<!--                            <icon name="heroicon-outline-ordered-list" class="h-5 w-5"></icon>-->
<!--                        </button>-->
<!--                        <button (click)="executeCommand('image')" class="toolbar-btn">-->
<!--                            <icon name="heroicon-outline-photograph" class="h-5 w-5"></icon>-->
<!--                        </button>-->
                </div>
            </div>
            <div #emojiPickerContainer *ngIf="showEmojiPicker"
                 class="absolute top-12 left-0 z-50 bg-white shadow-lg border border-gray-300 rounded-lg">
                <emoji-mart
                    set="apple"
                    perLine="7"
                    [emojiSize]="24"
                    [showPreview]="false"
                    (emojiClick)="insertEmoji($event.emoji.native)">
                </emoji-mart>
            </div>
        </div>
        <input type="file" #fileInput (input)="handleFileInput($event)" [accept]="accept"  #fileUpload class="hidden">
    `,
    styles: [`
        .toolbar-btn {
            @apply p-2 text-gray-600 rounded hover:bg-gray-200 transition;
        }

        .toolbar-btn.active {
            @apply bg-gray-400 text-white;
        }
    `]
})
export class RichTextEditorComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('fileInput') fileInput: ElementRef;
    @Input() form: UntypedFormGroup;
    @Input() controlName: string;
    @Input() parent:any;
    @Input() label: string;
    @Input() path: string;
    @Input() height:string;
    @Input() disallowUpload: boolean;
    @Input() maxFileSize: number = 10240000;
    @Input() allowedFiles: string[]; // = ['jpg','jpeg','png','gif','pdf','doc','docx','xls','xlsx'];
    @Input() disallowedFiles: string[] = ['heic','exe','dll','bin','sh'];
    @Input() randomizeFilePath: boolean = false;
    @Output() onKeyup: EventEmitter<any> = new EventEmitter<any>();
    @Output() onFileUpload: EventEmitter<CloudFile> = new EventEmitter<CloudFile>();
    @ViewChild('editorContent', {static: true}) editorContent!: ElementRef;
    @ViewChild('colorPicker', {static: false}) colorPicker!: ElementRef;
    @ViewChild('emojiPickerContainer', {static: false}) emojiPickerContainer!: ElementRef;
    editor!: Editor;
    showEmojiPicker: boolean = false;
    selectedColor: string = "#000000";
    promptOpen:boolean=false;
    editorHeight: string = '200px';
    get sizeLimit(): string {
        return this.maxFileSize ? `${round(this.maxFileSize/1000,0)}kb/${round(this.maxFileSize/1000/1000,2)}mb` : 'any size';
    }
    get accept(): string {
        return this.allowedFiles?.join ? this.allowedFiles.join(',') : '';
    }

    constructor(
        private pSvc: PageService,
        private cSvc: ClientService,
        private fSvc: FireService,
    ) {
    }

    ngOnInit() {
        this.updateEditorHeight();
        this.editor = new Editor({
            element: this.editorContent?.nativeElement,
            extensions: [
                StarterKit,
                Highlight,
                Underline,
                Link.configure({
                    openOnClick: true,
                    linkOnPaste: true,
                    defaultProtocol: 'https',
                    HTMLAttributes: {
                        class: 'editor-link',
                    }
                }),
                TextStyle,
                Color.configure({types: ['textStyle']}),
                Placeholder.configure({
                    emptyEditorClass: 'is-empty',
                    placeholder: `${this.label}`,
                    showOnlyWhenEditable: true,
                    includeChildren: true,
                    emptyNodeClass: 'empty-node',
                })
            ],
            content: this.form.get(this.controlName)?.value,
            editorProps: {
                attributes: {
                    class: 'focus:outline-none w-full',
                    spellcheck: 'true',
                    style: `min-height: ${this.editorHeight};`,
                },
            },
            onUpdate: ({editor}) => {
                this.form.get(this.controlName)?.setValue(editor.getHTML());
                this.onKeyup.emit(editor.getHTML());
            }
        });

        this.editorContent.nativeElement.addEventListener('dragover', (event: DragEvent) => {
            event.preventDefault();
        });

        this.editorContent.nativeElement.addEventListener('drop', (event: DragEvent) => {
            event.preventDefault();
            event.stopPropagation();
            this.handleFileDrop(event);
        });

        this.editorContent.nativeElement.addEventListener('paste', async (event: ClipboardEvent) => {
            event.preventDefault();

            const items = event.clipboardData?.items;
            if (!items) return;

            const itemsArray = Array.from(items);

            for (let item of itemsArray) {
                const file = item.getAsFile();
                if (file) {
                    await this.handleFiles([file]);
                }
            }
        });

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.form && this.editor) {
            this.editor.commands.setContent(this.form.get(this.controlName)?.value || '');
        }
    }

    ngOnDestroy() {
        this.editor.destroy();
    }

    executeCommand(command: string) {
        switch (command) {
            case 'bold':
                this.editor.chain().focus().toggleBold().run();
                break;
            case 'italic':
                this.editor.chain().focus().toggleItalic().run();
                break;
            case 'underline':
                this.editor.chain().focus().toggleMark('underline').run();
                break;
            case 'highlight':
                this.editor.chain().focus().toggleMark('highlight').run();
                break;
            case 'link':
                this.promptOpen = true;
                let url = prompt('Enter URL:');
                this.promptOpen = false;
                if (url) {
                    url = url.trim();
                    if (!/^https?:\/\//i.test(url)) {
                        this.pSvc.notification$.next({
                            title: 'We Changed Your Link!',
                            message: `The link must start with http or https. We guessed, and added https. If this is incorrect, please edit the link manually.`
                        });
                        url = `https://${url}`;
                    }
                    this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();                }
                this.editor.commands.focus()
                break;
            case 'bullet-list':
                this.editor.chain().focus().toggleBulletList().run();
                break;
            // case 'strike':
            //     this.editor.chain().focus().toggleStrike().run();
            //     break;
            // case 'unlink':
            //     this.editor.chain().focus().unsetMark('link').run();
            //     break;
            // case 'align-left':
            //     this.editor.chain().focus().setTextAlign('left').run();
            //     break;
            // case 'align-center':
            //     this.editor.chain().focus().setTextAlign('center').run();
            //     break;
            // case 'align-right':
            //     this.editor.chain().focus().setTextAlign('right').run();
            //     break;

            // case 'ordered-list':
            //     this.editor.chain().focus().toggleOrderedList().run();
            //     break;
            // case 'blockquote':
            //     this.editor.chain().focus().toggleBlockquote().run();
            //     break;
            // case 'horizontal-rule':
            //     this.editor.chain().focus().setHorizontalRule().run();
            //     break;
            // case 'image':
            //     const imageUrl = prompt('Enter image URL:', 'https://placehold.co/600x400');
            //     if (imageUrl) this.editor.chain().focus().setImage({src: imageUrl}).run();
            //     break;
            // case 'video':
            //     const videoUrl = prompt('Enter YouTube URL:', 'https://www.youtube.com/watch?v=KaLxCiilHns');
            //     if (videoUrl) this.editor.chain().focus().setNode('youtube', {
            //         src: videoUrl,
            //         width: 640,
            //         height: 480
            //     }).run();
            //     break;
        }
    }
    updateEditorHeight() {
        if (this.height) {
            this.editorHeight = this.height;
        } else if (this.parent?.medium) {
            switch (this.parent.medium) {
                case 'email':
                    this.editorHeight = '250px';
                    break;
                case 'chat':
                    this.editorHeight = '100px';
                    break;
                case 'note':
                    this.editorHeight = '150px';
                    break;
                default:
                    this.editorHeight = '200px';
                    break;
            }
        }
    }
    openColorPicker() {
        this.colorPicker.nativeElement.click();
    }

    changeFontColor(event: Event) {
        const target = event.target as HTMLInputElement;
        if (target) {
            this.selectedColor = target.value;
            this.editor.chain().focus().setColor(this.selectedColor).run();
        }
    }

    toggleEmojiPicker(event: Event) {
        event.stopPropagation();
        this.showEmojiPicker = !this.showEmojiPicker;
    }

    insertEmoji(emoji: string) {
        // console.log('Inserting emoji:', emoji);
        this.editor.chain().focus().insertContent(emoji).run();
        // this.showEmojiPicker = false;
    }

    @HostListener('document:click', ['$event'])
    onClickOutside(event: Event) {
            if (
                this.showEmojiPicker &&
                this.emojiPickerContainer &&
                this.emojiPickerContainer.nativeElement &&
                !this.emojiPickerContainer.nativeElement.contains(event.target)
            ) {
                this.showEmojiPicker = false;
            }
    }

    async handleFileDrop(event: DragEvent) {
        if (this.parent?.id) {
            if (!event.dataTransfer || !event.dataTransfer.files.length) return;

            event.preventDefault();
            event.stopPropagation();

            const files = Array.from(event.dataTransfer.files);
            this.handleFiles(files);
        }
    }

    async handleFileInput(event: any) {
        const files: File[] = Array.from(event.target.files || []);
        this.handleFiles(files);
        this.fileInput.nativeElement.value = '';
    }

    async handleFiles(files: File[]) {
        if (this.disallowUpload) {
            this.pSvc.notification$.next({
                title: 'Not Allowed!',
                message: 'File uploading not enabled here.'
            });
        } else {
            for (const file of files) {
                if(this.path){
                    try {
                        let filePath = `${this.path}/${file.name}`;
                        if (this.randomizeFilePath) {
                            filePath = `${this.path}/${Date.now()}/${file.name}`;
                        }
                        let fileExtension = file.name.split('.').pop()?.toLowerCase() || '';

                        if (this.disallowedFiles?.length && this.disallowedFiles.includes(fileExtension)) {
                            if (fileExtension === 'heic') {
                                this.pSvc.notification$.next({
                                    title: 'Cannot Drag & Drop iOS Screenshots',
                                    message: `Instead, please open the screenshot and then copy-paste it into the text editor or save it to your device and then click the paper clip icon.`
                                });
                            } else {
                                this.pSvc.notification$.next({
                                    title: 'Disallowed File Type',
                                    message: `The file ${file.name} is not allowed to be uploaded.`
                                });
                            }
                            continue;
                        }

                        let re: RegExp = new RegExp(`\.(${this.allowedFiles?.join('|')||''})$`,'i');
                        if (this.allowedFiles?.length && !re.test(file.name)) {

                            this.pSvc.notification$.next({
                                title: 'Unsupported File Type',
                                message: `The file ${file.name} has an unsupported format. Allowed files include: ${this.allowedFiles.join(', ')}`,
                            });

                        } else if (this.maxFileSize && file.size > this.maxFileSize) {
                            this.pSvc.notification$.next({
                                title: 'File Size Alert',
                                message: `File is too large.  Max file size allowed is ${this.sizeLimit}`
                            });
                        } else {
                            this.pSvc.loading$.next(true);

                            const fileUrl = await this.fSvc.upload(filePath, file);

                            const cloudFile: CloudFile = new CloudFile({
                                name: file.name,
                                url: fileUrl,
                            });

                            if (!this.parent.files) {
                                this.parent.files = [];
                            }

                            cloudFile.setID();
                            this.parent.files.push(cloudFile);
                            if (!this.parent._docRef) {
                                this.parent._docRef = this.cSvc.client$.getValue()._docRef.collection(this.parent._type).doc(this.parent.id);
                            }
                            await cloudFile.save(this.parent);
                            this.onFileUpload.emit(cloudFile);
                            this.pSvc.loading$.next(false);

                        }

                    } catch (error) {
                        console.error('File upload error:', error);
                        this.pSvc.alert$.next({ title: 'Upload Error', message: error.message });
                    }
                }else{
                    console.error(`No File Path Assigned`)
                }
            }
        }
    }

}
