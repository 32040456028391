"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AMENITY_MAP = exports.EAmenityNames = void 0;
var EAmenityNames;
(function (EAmenityNames) {
    EAmenityNames["WIFI"] = "wifi";
    EAmenityNames["CARGO_CAPACITY"] = "cargo";
    EAmenityNames["ENCLOSED_LAV"] = "lav";
    EAmenityNames["DRINK_SERVICE"] = "drink";
    EAmenityNames["SATELLITE_PHONE"] = "phone";
    EAmenityNames["LIVE_MAP"] = "map";
    EAmenityNames["FLIGHT_ATTENDANT"] = "attendant";
    EAmenityNames["ENTERTAINMENT_SYSTEM"] = "esys";
    EAmenityNames["LOCAL_CAFE"] = "cafe";
    EAmenityNames["PETS"] = "pets";
    EAmenityNames["LEATHER_SETS"] = "leather";
    EAmenityNames["PERSONAL_MONITORS"] = "tv";
})(EAmenityNames || (exports.EAmenityNames = EAmenityNames = {}));
;
exports.AMENITY_MAP = {
    wifi: {
        id: EAmenityNames.WIFI,
        name: 'Wi-Fi',
        description: 'Wi-Fi Enabled',
        icon: 'wifi',
        isActive: true
    },
    lav: {
        id: EAmenityNames.ENCLOSED_LAV,
        name: 'Enclosed Lav',
        description: 'Enclosed Lavatory',
        icon: 'wc',
        isActive: true
    },
    drink: {
        id: EAmenityNames.DRINK_SERVICE,
        name: 'Drink Service',
        description: 'Complimentary Beverages',
        icon: 'local_drink',
        isActive: true
    },
    cargo: {
        id: EAmenityNames.CARGO_CAPACITY,
        name: 'Cargo Capacity',
        description: 'Cargo Capacity',
        icon: 'apps',
        index: 4,
        isActive: true
    },
    phone: {
        id: EAmenityNames.SATELLITE_PHONE,
        name: 'Satellite Phone',
        description: 'Satellite Phone',
        icon: 'phone',
        isActive: true
    },
    attendant: {
        id: EAmenityNames.FLIGHT_ATTENDANT,
        name: 'Cabin Attendant',
        description: 'Flight Attendant Available',
        icon: 'room_service',
        isActive: true
    },
    map: {
        id: EAmenityNames.LIVE_MAP,
        name: 'Live Map',
        description: 'Live Map',
        icon: 'explore',
        isActive: true
    },
    esys: {
        id: EAmenityNames.ENTERTAINMENT_SYSTEM,
        name: 'Entertainment System',
        description: 'Entertainment System',
        icon: 'play_circle_filled',
        isActive: true
    },
    // cafe: {
    //     id: EAmenityNames.LOCAL_CAFE,
    //     name: 'local_cafe',
    //     description: 'local_cafe',
    //     icon: 'local_cafe',
    //     isActive: false
    // },
    pets: {
        id: EAmenityNames.PETS,
        name: 'Pets',
        description: 'Pets Ok',
        icon: 'pets',
        isActive: true
    },
    leather: {
        id: EAmenityNames.LEATHER_SETS,
        name: 'Leather Seats',
        description: 'Leather Seats',
        icon: 'event_seat',
        isActive: true
    },
    tv: {
        id: '4R0mLeWu3JysgEmgqfzk',
        name: 'Personal Monitors',
        description: 'Personal Monitors',
        icon: 'tv',
        isActive: true
    }
};
