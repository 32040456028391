import {Component, Injectable} from '@angular/core';
import {combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';

import {environment} from '@env/environment';
import {Client, IClientError} from '@nxt/model-core';
import {ClientService} from '@library/shared/_services/client.service';
import {UserService} from '@library/nxt/_services/user.service';
import {PageService} from '@library/shared/_services/page.service';
import {Router, RouterLink} from '@angular/router';
import {NgIf} from '@angular/common';

/**
 * The job of this class is to monitor the client to see if it has all the basic
 * data required to be a fully functioning Levo client.
 */
@Component({
    selector: 'on-boarding',
    standalone: true,
    imports: [
        NgIf,
        RouterLink
    ],
    template: `
        <div class="w-full flex justify-between bg-gray-200 text-dark p-3 text-center" *ngIf="errorItem">
            <div class="font-semibold hidden md:inline"></div>
            <div class="text-xs md:text-sm">{{ errorItem.description }}</div>
            <a class="cursor-pointer text-xs md:text-sm font-bold whitespace-nowrap" routerLink="/{{cSvc.name_key}}{{errorItem.url}}">Go There ></a>
        </div>
    `
})
export class OnBoarding {
    client: Client;
    errorItem: any;

    constructor(
        public cSvc: ClientService,
        private uSvc: UserService,
        private pSvc: PageService
    ) {
        // Listen for the signed-in user. If the user is an admin of a non-default client
        // then this service needs to do its job.

        combineLatest([this.uSvc.user$, this.cSvc.client$, this.pSvc.state$])
            .pipe(map(res => {
                return {user: res[0], client: res[1], url: res[2]};
            }))
            .subscribe(
                async result => {

                    this.errorItem = null;
                    if (result?.user
                        && result?.client
                        && result?.client?.id !== environment.default_client.id
                        && this.uSvc.isRole(['admin'])
                        && !result?.client?.config?.onboarded) {

                        let c: Client =  await this.cSvc.callAPI(`/cms/client/${result.client.name_key}`, 'get');
                        if (c?.errors?.length) {
                            c?.errors?.sort((a,b) => {
                                return a.priority > b.priority ? 1 : -1;
                            });

                            let errorItem: IClientError = c?.errors?.find(e => !result.url.match(e.url) );
                            let activeItem: IClientError = c?.errors?.find(e => result.url.match(e.url) );
                            console.log('errorItem', errorItem?.description);
                            console.log('activeItem', activeItem?.description);
                            if (errorItem && !activeItem) {
                                this.errorItem = errorItem;
                            }
                        }

                    }

                });
    }


}
