"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Affiliate = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
class Affiliate extends model_core_1.Base {
    get status() {
        if (this.requested && this.declined) {
            return {
                color: 'darkred',
                contrast: 'white',
                label: 'Denied'
            };
        }
        else if (this.requested && !this.approved) {
            return {
                color: '#efefef',
                contrast: 'black',
                label: 'Pending'
            };
        }
        else if (this.requested && !this.approved) {
            return {
                color: 'darkgreen',
                contrast: 'white',
                label: 'Approved'
            };
        }
        else if (this.approved && !this.declined) {
            return {
                color: 'darkgreen',
                contrast: 'white',
                label: 'Active'
            };
        }
    }
    constructor(data, olMap) {
        super('affiliates', 21);
        this.creator = null;
        this.recipient = null;
        this.client_ids = [];
        this.requested = null;
        this.approved = null;
        this.declined = null;
        (0, model_core_1.setObjectProperties)(this, data, olMap, Affiliate);
    }
    toString() {
        return `Affiliate: ${this.creator.name_key}-${this.recipient.name_key}`;
    }
    buildClientIDs() {
        ['creator', 'recipient'].forEach(p => {
            if (this[p] && this[p].id && !this.client_ids.includes(this[p].id)) {
                this.client_ids.push(this[p].id);
            }
        });
    }
    toJSON() {
        this.buildClientIDs();
        return super.toJSON();
    }
    toMinJSON(ignoreDocRef) {
        let r = super.toMinJSON(ignoreDocRef);
        Object.assign(r, this.toJSON());
        return r;
    }
    async save(parent) {
        if (!this.id) {
            this.setID();
        }
        this.date = this.date || Date.now();
        if (parent === null || parent === void 0 ? void 0 : parent._docRef) {
            this._docRef = parent._docRef.firestore.collection(this._type).doc(this.id);
        }
        return super.save();
    }
}
exports.Affiliate = Affiliate;
index_1.olm.affiliates = (ref, map) => {
    return new Affiliate(ref, map);
};
