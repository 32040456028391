import {
    Component,
    EventEmitter,
    Output,
    OnInit,
    ViewChild,
    Input,
    WritableSignal,
    signal,
    AfterViewInit, OnChanges, SimpleChanges
} from '@angular/core';

import {AlgoliaQueryBuilder, AlgoliaQueryFacetFilterItem, IAlgoliaSearchResults, IMenuItem} from '@nxt/model-core';
import {CommonModule} from '@angular/common';
import {
    getUsersByRoleFilterSets,
    IAlgoliaFilterSet,
    AlgoliaSearchComponent
} from '@library/shared/search/algolia-search.component';
import {AccountService} from '@library/nxt/_services/account.service';
import {Aircraft, TRIP_STATUS_MAP} from '@nxt/model-laro';
import {PopButtonComponent} from '@library/shared/buttons/pop-button.component';
import {LocalStorageService} from '@library/shared/_services/local-storage.service';
import {addDays, endOfDay, format, startOfDay} from 'date-fns';
import {ClientService} from '@library/shared/_services/client.service';
import {DateRangeDialog} from '@library/shared/input/date-range.dialog';
import {take} from 'rxjs/operators';
import {PageService} from '@library/shared/_services/page.service';
import {FireService} from '@library/nxt/_services/fire.service';
import {firstValueFrom} from 'rxjs';
import {UserService} from '@library/nxt/_services/user.service';

@Component({
    standalone: true,
    imports: [CommonModule, AlgoliaSearchComponent, PopButtonComponent],
    selector: 'laro-trip-search',
    template: `
        <div class="flex w-full">
            <div class="grow">
                <algolia-search-component
                        #searchComponent
                        index="trips"
                        placeholder="Search Trips"
                        [saveSearch]="saveSearch"
                        [autoStart]="false"
                        [showChicklets]="showChicklets"
                        [savedSearchUser]="uSvc.user$|async"
                        [filterSet]="filterSets"
                        [preSearchLogic]="preSearchLogic()"
                        (onResults)="handleResults($event)"
                        (onClear)="clear($event)"
                ></algolia-search-component>
            </div>
            <div class="pt-2" *ngIf="!hideToggle && !hits">
                <pop-button iconName="heroicon-arrows-right-left"
                            iconClass="ml-2 h-4 w-4 text-gray-500"
                            [items]="sortingMenu"
                            btnClass="border-transparent inline-flex mr-1 text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm"
                            label="Sort"
                ></pop-button>
            </div>
        </div>

    `
})
export class LaroTripSearchComponent implements OnInit,AfterViewInit, OnChanges {
    @Output() onResults: EventEmitter<[IAlgoliaSearchResults, boolean,boolean]> = new EventEmitter<[IAlgoliaSearchResults, boolean,boolean]>();
    @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSortChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDateChange: EventEmitter<Date> = new EventEmitter();
    @ViewChild('searchComponent') searchComponent: AlgoliaSearchComponent;
    @Input() hideToggle: boolean = true;
    @Input() sortBy: string;
    @Input() saveSearch: boolean = true; // Set to false by all-search
    @Input() showChicklets: boolean = true;
    @Input() showFilters: boolean = true;
    @Input() backwardLooking: boolean = false;
    @Input() pageSize: number = 400;
    @Input() daysToShow: number;
    @Input() start: Date;
    lastItemId: string = '';
    type: string = 'trips';
    filterSets: IAlgoliaFilterSet[];
    numericSearch: boolean;
    matches: boolean;

    selectedDate: WritableSignal<Date> = signal(startOfDay(new Date()));
    sortingMenu: IMenuItem[] = [
        {
            label: 'Departure Date',
            click: async () => {
                await this.lSvc.saveState('tripSort','departure_date');
                this.onSortChange.emit('departure_date');
            }
        },
        {
            label: 'Last Updated',
            click: async () => {
                await this.lSvc.saveState('tripSort','last_date');
                this.onSortChange.emit('last_date');
            }
        }
    ];
    hits: number;

    constructor(
        public uSvc: UserService,
        private aSvc: AccountService,
        private lSvc: LocalStorageService,
        private cSvc: ClientService,
        private pSvc: PageService,
        private fSvc: FireService
    ) {
    }

    ngOnInit() {
        this.loadFilters();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.daysToShow && this.daysToShow && this.searchComponent?.search) {
            this.searchComponent.search();
        }
    }

    ngAfterViewInit() {
        this.loadFilters();
        if (this.daysToShow && this.searchComponent?.search) {
            this.searchComponent.search();
        }
    }
    clear(e) {
        this.numericSearch = false;
        this.onClear.emit(e);
        this.lastItemId = '';
    }

    async loadFilters() {
        if (this.showFilters) {
            let now: Date = new Date();
            let start: Date = new Date();
            let end: Date = new Date();

            let filterSets:IAlgoliaFilterSet[] = [
                {
                    name: 'Date Filters',
                    buttons: [
                        {
                            label: 'Custom Date Range',
                            class: 'btn-dark',
                            click: () => {
                                this.pSvc.modal$.next({
                                    label: 'Custom Date Range',
                                    component: DateRangeDialog,
                                    styles: {
                                        width: '600px'
                                    },
                                    onLoaded: (comp: DateRangeDialog) => {
                                        comp.start = start;
                                        comp.end = end;
                                        comp.onClose
                                            .pipe(take(1)).subscribe(
                                            (result: {start:Date, end:Date}) => {

                                                if (result) {
                                                    start = result.start;
                                                    end = result.end;
                                                    this.searchComponent.builder.remove('filterItems', {id:'CUSTOM'});
                                                    this.searchComponent.toggleFilter({
                                                        id: 'CUSTOM',
                                                        name: `${format(start,'MMM d, yyyy')} > ${format(end,'MMM d, yyyy')}`,
                                                        color: '#505cac',
                                                        contrast: '#ffffff',
                                                        key: 'Date',
                                                        type: 'numeric',
                                                        key_unique: true,
                                                        more: `departure_date>${start.valueOf()}`,
                                                        value: `departure_date<${end.valueOf()}`
                                                    });
                                                }
                                            }
                                        );
                                        comp.ngOnChanges();
                                    }
                                });
                            }
                        }
                    ],
                    items: [
                        {
                            id: '1Day1',
                            name: 'Next 1 Day',
                            color: '#505cac',
                            contrast: '#ffffff',
                            key: 'Date',
                            type: 'numeric',
                            key_unique: true,
                            more: `departure_date>${startOfDay(now).valueOf()}`,
                            value: `departure_date<${addDays(now,1).valueOf()}`
                        },
                        {
                            id: '3Days',
                            name: 'Next 3 Days',
                            color: '#505cac',
                            contrast: '#ffffff',
                            key: 'Date',
                            type: 'numeric',
                            key_unique: true,
                            more: `departure_date>${startOfDay(now).valueOf()}`,
                            value: `departure_date<${addDays(now,3).valueOf()}`
                        },
                        {
                            id: '7DAYS',
                            name: 'Next 7 Days',
                            color: '#505cac',
                            contrast: '#ffffff',
                            key: 'Date',
                            type: 'numeric',
                            key_unique: true,
                            more: `departure_date>${startOfDay(now).valueOf()}`,
                            value: `departure_date<${addDays(now,6).valueOf()}`
                        },
                        {
                            id: '14DAYS',
                            name: 'Next 14 Days',
                            color: '#505cac',
                            contrast: '#ffffff',
                            key: 'Date',
                            type: 'numeric',
                            key_unique: true,
                            more: `departure_date>${startOfDay(now).valueOf()}`,
                            value: `departure_date<${addDays(now,14).valueOf()}`
                        },
                        {
                            id: '30DAYS',
                            name: 'Next 30 Days',
                            color: '#505cac',
                            contrast: '#ffffff',
                            key: 'Date',
                            type: 'numeric',
                            key_unique: true,
                            more: `departure_date>${startOfDay(now).valueOf()}`,
                            value: `departure_date<${addDays(now,30).valueOf()}`
                        },
                        {
                            id: '60DAYS',
                            name: 'Next 60 Days',
                            color: '#505cac',
                            contrast: '#ffffff',
                            key: 'Date',
                            type: 'numeric',
                            key_unique: true,
                            more: `departure_date>${startOfDay(now).valueOf()}`,
                            value: `departure_date<${addDays(now,60).valueOf()}`
                        },
                        {
                            id: '90DAYS',
                            name: 'Next 90 Days',
                            color: '#505cac',
                            contrast: '#ffffff',
                            key: 'Date',
                            type: 'numeric',
                            key_unique: true,
                            more: `departure_date>${startOfDay(now).valueOf()}`,
                            value: `departure_date<${addDays(now,90).valueOf()}`
                        },
                        {
                            id: '3DaysAway',
                            name: 'Departing In 3 Days',
                            color: '#252f72',
                            contrast: '#ffffff',
                            key: 'Date',
                            type: 'numeric',
                            key_unique: true,
                            more: `departure_date>${startOfDay(addDays(now,3)).valueOf()}`,
                            value: `departure_date<${endOfDay(addDays(now,3)).valueOf()}`
                        },
                        {
                            id: '7DaysAway',
                            name: 'Departing In 7 Days',
                            color: '#252f72',
                            contrast: '#ffffff',
                            key: 'Date',
                            type: 'numeric',
                            key_unique: true,
                            more: `departure_date>${startOfDay(addDays(now,7)).valueOf()}`,
                            value: `departure_date<${endOfDay(addDays(now,7)).valueOf()}`
                        }
                    ]
                },
                {
                    name: 'Status Filters',
                    items: Object.keys(TRIP_STATUS_MAP).reduce((statuses,status) => {
                        if (!status.match(/^q/)) {
                            statuses.push({
                                id: status,
                                name: TRIP_STATUS_MAP[status].label,
                                color: TRIP_STATUS_MAP[status].color,
                                contrast: TRIP_STATUS_MAP[status].contrast,
                                key: 'status',
                                key_unique: true,
                                type: 'facet',
                                value: [`status:${status}`]
                            });
                        }
                        return statuses;
                    }, [])
                }];
            this.filterSets = filterSets
                .concat(await this.getActiveAircraftFilter())
                .concat(await this.getInactiveAircraftFilter())
                .concat(await getUsersByRoleFilterSets(this.aSvc, this.cSvc));
        }
    }

    async getActiveAircraftFilter() {
        let filter: IAlgoliaFilterSet = {
                name: 'Active Aircraft',
                items: []
            };

        let q = await firstValueFrom(this.fSvc.getColl(`clients/${this.cSvc.client_id}/aircraft`, [{ name: 'where', args: ['active','==',true] }]));
        filter.items = q.map(doc => {
            let a: Aircraft = new Aircraft(doc);
            return {
                id: a.id,
                name: `${a.name}${ a.tail_number ? ' ('+a.tail_number+')' : ''}`,
                color: '#6cbcbe',
                contrast: '#000000',
                key: 'AIRCRAFT',
                type: 'facet',
                key_unique: false,
                value: `tails:${a.tail_number}`
            }
        });

        return filter;
    }

    async getInactiveAircraftFilter() {
        let filter: IAlgoliaFilterSet = {
            name: 'Inactive Aircraft',
            items: []
        };

        let q = await firstValueFrom(this.fSvc.getColl(`clients/${this.cSvc.client_id}/aircraft`, [{ name: 'where', args: ['active','==',false] }]));
        filter.items = q.map(doc => {
            let a: Aircraft = new Aircraft(doc);
            return {
                id: a.id,
                name: `${a.name}${ a.tail_number ? ' ('+a.tail_number+')' : ''}`,
                color: '#a5b02c',
                contrast: '#000000',
                key: 'AIRCRAFT',
                type: 'facet',
                key_unique: false,
                value: `tails:${a.tail_number}`
            }
        });

        return filter;
    }

    async handleResults([results, append]) {
        if (!this.start) {
            this.start = this.selectedDate();
        }
        if (!append) {
            this.matches = false;
            if (this.numericSearch) {
                let term: string = this.searchComponent.builder.query;
                let parts: string[] = term.replace(/ /g,'').split(',');
                results.hits = results.hits.reduce((matches,item) => {
                    for (let part of parts) {
                        if (item.trip_number && (item.trip_number.toString().match(part) || item.trip_number.toString() === part)) {
                            this.matches = true;
                            matches.push(item);
                            break;
                        }
                    }
                    return matches;
                },[]);
            }
        }
        if (!results.hits?.length) {
            return this.onResults.emit([results, append, false]);
        } else {
            if (this.daysToShow) {
                const startTimestamp = this.start.valueOf();
                const endTimestamp = addDays(this.start, this.daysToShow).valueOf();

                const firstItemDate = new Date(results.hits[0].departure_date);
                const lastItem = results.hits[results.hits.length - 1];
                const lastDate = new Date(lastItem.departure_date);

                const hasCurrentResults = results.hits.find(({departure_date}) => {
                    const depTimestamp = new Date(departure_date).valueOf();
                    return depTimestamp >= startTimestamp && depTimestamp <= endTimestamp;
                });

                if (!hasCurrentResults) {
                    if (firstItemDate.valueOf() > endTimestamp) {
                        this.selectedDate.set(firstItemDate);
                        this.onDateChange.emit(firstItemDate);
                    } else {
                        this.selectedDate.set(lastDate);
                    }
                    if (this.lastItemId !== lastItem.id) {
                        this.lastItemId = lastItem.id;
                        this.searchComponent.search();
                        return;
                    }
                }

            }

            this.onResults.emit([results, append, false]);
            //This will turn the page if there are more results to get
            if (this.searchComponent.showMore$.value) {
                this.searchComponent.nextPage();
                return;
            }
        }

    }
    preSearchLogic() {

        return (builder: AlgoliaQueryBuilder) => {
            if (builder) {
                builder.typoTolerance = 'strict';

                let dateFilter:AlgoliaQueryFacetFilterItem = new AlgoliaQueryFacetFilterItem({
                    id: 'DATE',
                    type: 'numeric',
                    key: 'DATE',
                    key_unique: true,
                    value: `departure_date>=${startOfDay(new Date()).valueOf()}`
                });

                this.numericSearch = !!builder.query.trim().match(/\d+((,\d+)|)/);
                if (!builder.query) {
                    builder.addFilter(dateFilter);
                } else {
                    builder.removeFilter(dateFilter);
                }
            }
            return builder;
        };
    }
}
