"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EAvianisServiceRequestStatus = exports.EAvianisServiceTypes = void 0;
var EAvianisServiceTypes;
(function (EAvianisServiceTypes) {
    EAvianisServiceTypes["CATERING"] = "catering";
    EAvianisServiceTypes["FBO"] = "fbo";
    EAvianisServiceTypes["FUEL"] = "fuel";
    EAvianisServiceTypes["GROUND_TRANSPORT"] = "groundTransportation";
    EAvianisServiceTypes["LODGING"] = "lodging";
    EAvianisServiceTypes["HANDLING"] = "HANDLING";
    EAvianisServiceTypes["HANGER"] = "HANGER";
    EAvianisServiceTypes["AIRLINE"] = "AIRLINE";
    EAvianisServiceTypes["FLIGNT_PLANNING"] = "FLIGHT PLANNING";
    EAvianisServiceTypes["OTHER"] = "OTHER";
    EAvianisServiceTypes["SPECIAL"] = "SPECIAL";
})(EAvianisServiceTypes || (exports.EAvianisServiceTypes = EAvianisServiceTypes = {}));
var EAvianisServiceRequestStatus;
(function (EAvianisServiceRequestStatus) {
    EAvianisServiceRequestStatus["CANCELLED"] = "cancelled";
    EAvianisServiceRequestStatus["PENDING"] = "pending";
    EAvianisServiceRequestStatus["NONE"] = "";
})(EAvianisServiceRequestStatus || (exports.EAvianisServiceRequestStatus = EAvianisServiceRequestStatus = {}));
;
