"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCityName = exports.Airport = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
class Airport extends model_core_1.Base {
    constructor(data, olMap) {
        super('airports', 22);
        this.name = '';
        this.iata = '';
        this.icao = '';
        this.latitude = null;
        this.longitude = null;
        this.timezone = '';
        this.images = [];
        this.region = '';
        this.city = {};
        this.address = {};
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, Airport);
        ['latitude', 'longitude'].forEach(p => {
            if (!isNaN(Number(this[p]))) {
                this[p] = Number(this[p]);
            }
            else {
                this[p] = null;
            }
        });
    }
    toJSON() {
        let r = super.toJSON();
        ['latitude', 'longitude'].forEach(p => {
            if (!isNaN(Number(this[p]))) {
                r[p] = Number(this[p]);
            }
            else {
                r[p] = null;
            }
        });
        return r;
    }
    toMinJSON(ignoreDocRef) {
        let r = super.toMinJSON(ignoreDocRef);
        ['latitude', 'longitude'].forEach(p => {
            if (!isNaN(Number(this[p]))) {
                r[p] = Number(this[p]);
            }
            else {
                r[p] = null;
            }
        });
        ['city', 'iata', 'icao', 'name', 'timezone'].forEach(p => {
            r[p] = (0, model_core_1.toMinJSON)(this, p);
        });
        ['address', 'region', 'images', 'active', 'date', 'last_date'].forEach(p => delete r[p]);
        return r;
    }
    async toSearchJSON(opts) {
        let r = await super.toSearchJSON(opts);
        Object.assign(r, this.toJSON());
        if (this.latitude && this.longitude) {
            r._geoloc = {
                lat: Number(this.latitude),
                lng: Number(this.longitude)
            };
        }
        return r;
    }
}
exports.Airport = Airport;
function getCityName(airport, comma) {
    var _a, _b, _c, _d, _e;
    if (airport) {
        let city = ((((_a = airport.city) === null || _a === void 0 ? void 0 : _a.name) || ((_b = airport.address) === null || _b === void 0 ? void 0 : _b.city)) || '').replace(/ $/g, '');
        let state = ((((_c = airport.city) === null || _c === void 0 ? void 0 : _c.state) || ((_d = airport.address) === null || _d === void 0 ? void 0 : _d.state)) || '').replace(/ $/g, '');
        let ctry = (((_e = airport.address) === null || _e === void 0 ? void 0 : _e.country) || '').replace(/ $/g, '');
        if (city && state) {
            if (comma === false) {
                return `${city} ${state}`;
            }
            else {
                return `${city}, ${state}`;
            }
        }
        else if (city && ctry) {
            return `${city}, ${ctry}`;
        }
        else {
            return city;
        }
    }
    else {
        return '';
    }
}
exports.getCityName = getCityName;
index_1.olm.airports = (ref, map) => {
    return new Airport(ref, map);
};
