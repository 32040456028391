"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TRACKING_STATUS = exports.ShippingTypes = exports.Shipment = void 0;
const Common_1 = require("./Common");
const Address_1 = require("./Address");
const Base_1 = require("./Base");
const index_1 = require("../index");
class Shipment extends Base_1.Base {
    get from_address_id() {
        var _a;
        return ((_a = this.from_address) === null || _a === void 0 ? void 0 : _a.id) || '';
    }
    get to_address_id() {
        var _a;
        return ((_a = this.to_address) === null || _a === void 0 ? void 0 : _a.id) || '';
    }
    get errors() {
        return this.metadata.messages;
    }
    constructor(data, olMap) {
        super('shipments', 24);
        this.type = ShippingTypes.None;
        this.from_address = null;
        this.to_address = null;
        this.from_address_default = null;
        this.date = null;
        this.distance = null;
        this.requested = null;
        this.scheduled = null;
        this.canceled = null;
        this.sent = null;
        this.delivered = null;
        this.total_height = null;
        this.total_length = null;
        this.total_width = null;
        this.total_weight = null;
        this.total_weight_ounces = null;
        this.nmfc_class = null;
        this.amount = null;
        this.cost = null;
        this.notes = '';
        this.ltl = null;
        this.free = false;
        this.rates = [];
        this.rate = null;
        this.rate_status = '';
        this.postage_label = null;
        this.is_return = null;
        this.tracking_code = '';
        this.tracking_url = '';
        this.pickup_code = '';
        this.status = TRACKING_STATUS.NONE;
        this.tracker = null;
        this.packaging = null;
        this.packing_list = '';
        this.third_party = [];
        this.metadata = {};
        this.items = new index_1.ChildArray();
        this.events = new index_1.ChildArray();
        this.files = new index_1.ChildArray();
        this._warnings = [];
        (0, Common_1.setObjectProperties)(this, data, olMap, Shipment);
    }
    shouldBeLTL() {
        if (this.total_weight > 150) {
            return true;
        }
        else if (this.total_width > 100) {
            return true;
        }
        else if (this.total_length > 100) {
            return true;
        }
        return false;
    }
    toString() {
        var _a, _b;
        return `Shipment: ${(_a = this.rate) === null || _a === void 0 ? void 0 : _a.carrier} ${(_b = this.rate) === null || _b === void 0 ? void 0 : _b.service}`;
    }
    setDimensions(opts) {
        var _a, _b, _c;
        this.total_height = this.getLargestDimension('Height') ? this.getLargestDimension('Height') : this.total_height;
        this.total_length = this.getLargestDimension('Length') ? this.getLargestDimension('Length') : this.total_length;
        this.total_width = this.getLargestDimension('Width') ? this.getLargestDimension('Width') : this.total_width;
        if (((_a = this.packaging) === null || _a === void 0 ? void 0 : _a.manual) && ((_b = this.packaging) === null || _b === void 0 ? void 0 : _b.weight)) {
            this.total_weight = this.packaging.weight;
        }
        else if ((_c = this.items) === null || _c === void 0 ? void 0 : _c.length) {
            this.total_weight = (0, Common_1.round)(this.items.reduce((n, item, i) => {
                if (opts) {
                    this.items[i] = (0, index_1.loadObject)(this.items[i], opts);
                }
                if (this.items[i]._override_weight) {
                    n += (this.items[i]._override_weight || 0);
                }
                else {
                    if (this.items[i].calc) {
                        this.items[i].calc(this);
                    }
                    n += (this.items[i].total_weight || 0);
                }
                return n;
            }, 0), 2);
        }
        // Default small packages to 8oz?
        this.total_weight_ounces = this.total_weight ? (0, Common_1.round)((this.total_weight * 16), 0) : 8;
    }
    getLargestDimension(dim) {
        var _a, _b, _c, _d, _e;
        let result;
        if (((_a = this.packaging) === null || _a === void 0 ? void 0 : _a.manual) && ((_b = this.packaging.dimensions) === null || _b === void 0 ? void 0 : _b.get) && ((_d = (_c = this.packaging.dimensions) === null || _c === void 0 ? void 0 : _c.get(dim)) === null || _d === void 0 ? void 0 : _d.size)) {
            result = this.packaging.dimensions.get(dim).size;
        }
        else {
            (_e = this.items) === null || _e === void 0 ? void 0 : _e.forEach(i => {
                var _a, _b, _c, _d, _e;
                if (((_a = i.dimensions) === null || _a === void 0 ? void 0 : _a.get) && ((_c = (_b = i.dimensions) === null || _b === void 0 ? void 0 : _b.get(dim)) === null || _c === void 0 ? void 0 : _c.size) && (!result || ((_e = (_d = i.dimensions) === null || _d === void 0 ? void 0 : _d.get(dim)) === null || _e === void 0 ? void 0 : _e.size) > result)) {
                    result = i.dimensions.get(dim).size;
                }
            });
        }
        return result;
    }
    addMessage(msg) {
        this.metadata = this.metadata || {};
        this.metadata.messages = this.metadata.messages || [];
        if (!this.metadata.messages.find((i) => i.provider === msg.provider && i.message === msg.message)) {
            this.metadata.messages.push(msg);
        }
    }
    toJSON() {
        this.setDimensions();
        let r = super.toJSON();
        if (this.items) {
            delete r.items;
        }
        return r;
    }
    toMinJSON(ignoreDocRef) {
        let r = super.toMinJSON(ignoreDocRef);
        this.setDimensions();
        ['id', 'retail_rate', 'rate', 'total_height', 'total_length', 'total_width', 'total_weight', 'total_weight_ounces', 'status']
            .forEach(p => {
            if (this[p]) {
                r[p] = this[p];
            }
        });
        return r;
    }
    async save(parent) {
        var _a, _b;
        this.setDimensions();
        if (!this.id) {
            this.setID();
        }
        if (parent && parent._docRef) {
            this._docRef = parent._docRef.collection(this._type).doc(this.id);
            if (((_a = this.to_address) === null || _a === void 0 ? void 0 : _a.id) && !this.to_address._docRef && ((_b = parent.contact) === null || _b === void 0 ? void 0 : _b._docRef)) {
                let to = new Address_1.Address(await parent.contact._docRef.collection('addresses').doc(this.to_address.id).get());
                if (!to._exists) {
                    await to.save();
                }
                this.to_address = to;
            }
        }
        if (!this._docRef) {
            throw `Cannot save! No document reference.`;
        }
        let batches = [[]];
        let current = 0;
        batches[current].push([this._docRef, this.toJSON()]);
        if (this.items) {
            this.items.forEach((item) => {
                if (batches[current].length === 499) {
                    current++;
                    batches[current] = [];
                }
                batches[current].push([this._docRef.collection('items').doc(item.id), (item.toJSON) ? item.toJSON() : item]);
            });
        }
        await Promise.all(batches.map(async (batch) => {
            let dbBatch = this._docRef.firestore.batch();
            batch.forEach((item) => {
                dbBatch.set(item[0], item[1]);
            });
            await dbBatch.commit();
        }));
    }
    async checkValidity(order) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        this._warnings = [];
        // check items locations to make sure they are compatible
        let nmfc_classes = [];
        let locations = [];
        await Promise.all((_a = this.items) === null || _a === void 0 ? void 0 : _a.map(async (item) => {
            var _a;
            if (item.sku && this._docRef) {
                let q = await this._docRef.parent.parent.parent.parent.collection('products').where('sku', '==', item.sku).get();
                if (q.size) {
                    let p = new index_1.Product(q.docs[0]);
                    if (((_a = p.address) === null || _a === void 0 ? void 0 : _a.id) && !locations.find(a => a.id === p.address.id)) {
                        locations.push(p.address);
                    }
                    if (p.nmfc_class && !nmfc_classes.includes(p.nmfc_class)) {
                        nmfc_classes.push(p.nmfc_class);
                    }
                    if (p.weight !== item.unit_weight) {
                        item.unit_weight = p.weight;
                    }
                }
            }
        }));
        if (locations.length > 1) {
            this._warnings.push('Incompatible Items! Items sourced from different locations cannot be in the same order.');
        }
        else if (locations.length) {
            // If the default location changed, the user probably changed items in the shipment.
            // Force them to re-select the FROM just to make sure they get it right.
            if (((_b = this.from_address_default) === null || _b === void 0 ? void 0 : _b.id) && ((_c = this.from_address_default) === null || _c === void 0 ? void 0 : _c.id) !== locations[0].id) {
                delete this.from_address;
            }
            else if (!((_d = this.from_address) === null || _d === void 0 ? void 0 : _d.id)) {
                this.from_address = locations[0];
            }
            this.from_address_default = locations[0];
        }
        if (nmfc_classes.length === 1 && this.nmfc_class !== nmfc_classes[0]) {
            this.nmfc_class = nmfc_classes[0];
        }
        if (order) {
            if (!((_e = this.items) === null || _e === void 0 ? void 0 : _e.length)) {
                this._warnings.push('Shipment has no items. Check the Items tab.');
            }
            if (!((_f = this.to_address) === null || _f === void 0 ? void 0 : _f.id)) {
                this._warnings.push('TO address not selected. Check the To tab.');
            }
            if (!((_g = this.from_address) === null || _g === void 0 ? void 0 : _g.id)) {
                this._warnings.push('FROM address not selected. Check the From tab.');
            }
            if (order._type !== 'quotes') {
                if (!((_h = this.rate) === null || _h === void 0 ? void 0 : _h.carrier)) {
                    this._warnings.push('No shipper/carrier selected. Check the Rates tab, or click Get Rates.');
                }
            }
            if ((order === null || order === void 0 ? void 0 : order.invoice_id) && ((_j = order === null || order === void 0 ? void 0 : order.metadata) === null || _j === void 0 ? void 0 : _j.shipping_total) && ((_k = order === null || order === void 0 ? void 0 : order.metadata) === null || _k === void 0 ? void 0 : _k.shipping_total) !== this.amount && !this.free) {
                this._warnings.push('The shipping amount on the original order does not match the total on this shipment. Check the Rates tab on the shipment.');
            }
        }
    }
    pickRate(order) {
        var _a, _b, _c, _d, _e, _f;
        for (let rate of this.rates) {
            if ((order === null || order === void 0 ? void 0 : order.selected_rate)
                && ((_a = order === null || order === void 0 ? void 0 : order.selected_rate) === null || _a === void 0 ? void 0 : _a.carrier) === rate.carrier
                && ((_b = order === null || order === void 0 ? void 0 : order.selected_rate) === null || _b === void 0 ? void 0 : _b.service) === rate.service) {
                // console.log('selectRate 1', rate);
                this.rate = rate;
                break;
            }
            else if (rate.default) {
                // console.log('selectRate 2', rate);
                this.rate = rate;
                break;
            }
            else if (!((_c = this.rate) === null || _c === void 0 ? void 0 : _c.carrier) ||
                Number((rate === null || rate === void 0 ? void 0 : rate.retail_rate) || (rate === null || rate === void 0 ? void 0 : rate.list_rate)) < Number(((_d = this.rate) === null || _d === void 0 ? void 0 : _d.retail_rate) || ((_e = this.rate) === null || _e === void 0 ? void 0 : _e.list_rate))) {
                // console.log('selectRate 3', rate);
                this.rate = rate;
            }
        }
        // TODO Use get/set third party id for UPS account
        if (this.getThirdPartyId('ups')) {
            this.cost = 0;
            this.amount = 0;
        }
        else if ((_f = this.rate) === null || _f === void 0 ? void 0 : _f.carrier) {
            this.cost = Number(this.rate.list_rate);
            this.amount = this.free ? 0 : Number(this.rate.retail_rate || this.rate.list_rate);
            this.type = this.rate.type;
        }
        if (order) {
            order.shipping = (this.free) ? 0 : this.amount;
            order.selected_rate = this.rate;
            order.calc();
            // console.log('PICK RATE!', order.shipping);
        }
    }
}
exports.Shipment = Shipment;
var ShippingTypes;
(function (ShippingTypes) {
    ShippingTypes["UPS"] = "UPS";
    ShippingTypes["EasyPost"] = "EasyPost";
    ShippingTypes["EP"] = "EP";
    ShippingTypes["Averitt"] = "Averitt";
    ShippingTypes["Alside"] = "Alside";
    ShippingTypes["XPO"] = "XPO";
    ShippingTypes["RoadRunner"] = "RoadRunner";
    ShippingTypes["TMS"] = "TMS";
    ShippingTypes["R_AND_L"] = "R&L";
    ShippingTypes["EveryWindow"] = "EW";
    ShippingTypes["Custom"] = "Custom";
    ShippingTypes["Other"] = "O";
    ShippingTypes["None"] = "";
})(ShippingTypes || (exports.ShippingTypes = ShippingTypes = {}));
var TRACKING_STATUS;
(function (TRACKING_STATUS) {
    TRACKING_STATUS["NONE"] = "";
    TRACKING_STATUS["PRE_TRANSIT"] = "pre_transit";
    TRACKING_STATUS["OUT_FOR_DELIVERY"] = "out_for_delivery";
    TRACKING_STATUS["IN_TRANSIT"] = "in_transit";
    TRACKING_STATUS["DELIVERED"] = "delivered";
    TRACKING_STATUS["PROBLEM"] = "problem";
})(TRACKING_STATUS || (exports.TRACKING_STATUS = TRACKING_STATUS = {}));
index_1.olm.shipments = (ref, map) => {
    return new Shipment(ref, map);
};
