"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvianisInvoice = exports.Invoice = void 0;
const index_1 = require("../index");
const model_core_1 = require("@nxt/model-core");
class Invoice extends model_core_1.Base {
    constructor(data, olMap, type, idLen) {
        super(type || 'invoices', idLen);
        this.trip = null;
        this.account = null;
        this.paid = false;
        this.amount_paid = null;
        this.notes = '';
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, Invoice);
    }
}
exports.Invoice = Invoice;
class AvianisInvoice extends Invoice {
    constructor(data, olMap) {
        super(data, olMap || index_1.olm, 'invoices', null);
        this.balanceDue = null;
        this.billingContact = '';
        this.discount = null;
        this.dueDate = ''; // 2023-07-07T14:14:59.285Z,
        this.invoiceDate = ''; // 2023-07-07T14:14:59.285Z,
        this.jetCard = '';
        this.number = '';
        this.relatedRecords = '';
        this.taxes = null;
        this.total = null;
        this.lineItems = [];
        this.payments = [];
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, AvianisInvoice);
    }
    async save(parent) {
        await super.save(parent);
        if (this._docRef) {
            if (typeof this.account === 'string') {
                let q = await this._docRef.parent.parent.collection('invoices').where('name', '==', this.account).get();
                if (q.size) {
                    await this.update({ account: new index_1.Account(q.docs[0]).toMinJSON() });
                }
                else {
                    console.warn(`COULDN'T FIND ACCOUNT WITH NAME: ${this.account}`);
                }
            }
        }
    }
}
exports.AvianisInvoice = AvianisInvoice;
