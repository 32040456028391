"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DealLeg = exports.TripLeg = void 0;
const Flight_1 = require("./Flight");
const index_1 = require("../index");
const model_core_1 = require("@nxt/model-core");
class TripLeg extends Flight_1.FlightLeg {
    constructor(data, olMap, type, idLen) {
        super(data, olMap || index_1.olm, type, idLen);
        this.trip_number = '';
        this._m = ['departureAirport', 'arrivalAirport', 'isEmptyLeg', 'date', 'active', 'times', 'aircraft', 'passengers'];
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, TripLeg);
    }
}
exports.TripLeg = TripLeg;
class DealLeg extends TripLeg {
    constructor(data, olMap) {
        super(data, olMap || index_1.olm, 'legs', 8);
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, DealLeg);
    }
}
exports.DealLeg = DealLeg;
index_1.olm.legs = (ref, map) => {
    return new TripLeg(ref, map);
};
