"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Client = exports.checkLaroErrors = exports.LARO_CLIENT_ERRORS = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
exports.LARO_CLIENT_ERRORS = {
    aircraft: {
        id: 'aircraft',
        priority: 2,
        url: '/data/aircraft/fleet',
        urlLabel: 'Configure Aircraft',
        description: `Update your company's fleet. It's under Data -> Fleet`
    },
    software: {
        id: 'software',
        priority: 1,
        url: '/company/settings/charter-management-software',
        urlLabel: 'Flight Software Connection',
        description: `Add your flight software settings under Company -> Settings -> Charter Management Software`
    }
};
function checkLaroErrors(client) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    client.errors = [];
    if (((_b = (_a = client === null || client === void 0 ? void 0 : client.config) === null || _a === void 0 ? void 0 : _a.aircraft) === null || _b === void 0 ? void 0 : _b.active) === false) {
        let err = Object.assign({}, exports.LARO_CLIENT_ERRORS.aircraft);
        if ((_d = (_c = client.config) === null || _c === void 0 ? void 0 : _c.aircraft) === null || _d === void 0 ? void 0 : _d.error) {
            err.description = (_f = (_e = client.config) === null || _e === void 0 ? void 0 : _e.aircraft) === null || _f === void 0 ? void 0 : _f.error;
        }
        client.add('errors', err);
    }
    if (!((_h = (_g = client === null || client === void 0 ? void 0 : client.config) === null || _g === void 0 ? void 0 : _g.software) === null || _h === void 0 ? void 0 : _h.active)) {
        let err = Object.assign({}, exports.LARO_CLIENT_ERRORS.software);
        if ((_k = (_j = client.config) === null || _j === void 0 ? void 0 : _j.software) === null || _k === void 0 ? void 0 : _k.error) {
            err.description = (_m = (_l = client.config) === null || _l === void 0 ? void 0 : _l.software) === null || _m === void 0 ? void 0 : _m.error;
        }
        client.add('errors', err);
    }
}
exports.checkLaroErrors = checkLaroErrors;
class Client extends model_core_1.Client {
    toJSON() {
        checkLaroErrors(this);
        return super.toJSON();
    }
}
exports.Client = Client;
index_1.olm.clients = (ref, map) => {
    return new Client(ref, map);
};
