"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubscriptionScore = exports.getLegScore = exports.prepareSubscriptionSearch = exports.DEFAULT_SUBSCRIPTION_SEARCHES = exports.DEFAULT_QUOTE_SEARCHES = exports.MarketplaceSearch = exports.MARKETPLACE_SEARCH_TYPES = exports.MARKETPLACE_SEARCH_LEVELS = void 0;
const model_core_1 = require("@nxt/model-core");
const date_fns_1 = require("date-fns");
const index_1 = require("../index");
var MARKETPLACE_SEARCH_LEVELS;
(function (MARKETPLACE_SEARCH_LEVELS) {
    MARKETPLACE_SEARCH_LEVELS[MARKETPLACE_SEARCH_LEVELS["HIGH_ACCURACY"] = 1] = "HIGH_ACCURACY";
    MARKETPLACE_SEARCH_LEVELS[MARKETPLACE_SEARCH_LEVELS["MEDIUM_ACCURACY"] = 2] = "MEDIUM_ACCURACY";
    MARKETPLACE_SEARCH_LEVELS[MARKETPLACE_SEARCH_LEVELS["LOW_ACCURACY"] = 3] = "LOW_ACCURACY";
    MARKETPLACE_SEARCH_LEVELS[MARKETPLACE_SEARCH_LEVELS["NONE"] = null] = "NONE";
})(MARKETPLACE_SEARCH_LEVELS || (exports.MARKETPLACE_SEARCH_LEVELS = MARKETPLACE_SEARCH_LEVELS = {}));
var MARKETPLACE_SEARCH_TYPES;
(function (MARKETPLACE_SEARCH_TYPES) {
    MARKETPLACE_SEARCH_TYPES["QUOTE"] = "deals";
    MARKETPLACE_SEARCH_TYPES["SUBSCRIPTION"] = "subscriptions";
    MARKETPLACE_SEARCH_TYPES["NONE"] = "";
})(MARKETPLACE_SEARCH_TYPES || (exports.MARKETPLACE_SEARCH_TYPES = MARKETPLACE_SEARCH_TYPES = {}));
class MarketplaceSearch extends model_core_1.AlgoliaQueryBuilder {
    constructor(data, olMap) {
        super(data, 'marketplace_searches');
        this.type = MARKETPLACE_SEARCH_TYPES.NONE;
        this.name = '';
        this.description = '';
        /**
         * Lower number means more targeted/granular search. Therefore hits on lower numbers are higher value.
         */
        this.level = MARKETPLACE_SEARCH_LEVELS.NONE;
        /**
         * Number of hits found when running the search.
         */
        this.hits = null;
        this.radius = 0;
        this.daysBefore = null;
        this.daysAfter = null;
        this.date = null;
        this.icao = '';
        this.latitude = '';
        this.longitude = '';
        this.followers = [];
        this.roles = [];
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, MarketplaceSearch);
    }
    setStart(date) {
        let start = (0, date_fns_1.startOfDay)((0, date_fns_1.subDays)(date, this.daysBefore));
        let startFilter = new model_core_1.AlgoliaQueryFacetFilterItem({
            id: 'start',
            name: '',
            key: 'start',
            type: 'numeric',
            key_unique: true,
            value: `departure_date>${start.valueOf()}`
        });
        this.removeFilter(startFilter);
        this.addFilter(startFilter);
    }
    setEnd(date) {
        let end = this.daysAfter ? (0, date_fns_1.endOfDay)((0, date_fns_1.addDays)(date, this.daysAfter)) : date;
        let endFilter = new model_core_1.AlgoliaQueryFacetFilterItem({
            id: 'end',
            name: '',
            key: 'end',
            type: 'numeric',
            key_unique: true,
            value: `departure_date<${end.valueOf()}`
        });
        this.removeFilter(endFilter);
        this.addFilter(endFilter);
    }
    setAffiliates(client_ids) {
        client_ids.forEach(id => {
            if (!this.filterItems.find(i => i.id === id)) {
                this.addFilter(new model_core_1.AlgoliaQueryFacetFilterItem({
                    id: id,
                    name: '',
                    key: 'client',
                    type: 'facet',
                    key_unique: false,
                    value: `_client:${id}`
                }));
            }
        });
    }
    setAirport(p, a) {
        let airportFilter = new model_core_1.AlgoliaQueryFacetFilterItem({
            id: p,
            name: '',
            key: 'airport',
            type: 'facet',
            key_unique: true
        });
        this.removeFilter(airportFilter);
        if (a === null || a === void 0 ? void 0 : a.icao) {
            airportFilter.value = `${p}Airport.icao:${a.icao}`;
            this.addFilter(airportFilter);
        }
    }
    milesToMeters(miles) {
        return (0, model_core_1.round)(Math.ceil(Number(miles || 0) * 1609.34), 0);
    }
    setRadius(latitude, longitude) {
        if (this.radius) {
            this.aroundRadius = this.milesToMeters(this.radius);
            this.aroundLatLng = `${latitude}, ${longitude}`;
            this.removeFilter(new model_core_1.AlgoliaQueryFacetFilterItem({ id: 'departure' }));
            this.removeFilter(new model_core_1.AlgoliaQueryFacetFilterItem({ id: 'arrival' }));
        }
        else {
            this.aroundRadius = null;
        }
    }
    prepare(date, airport, p, client_ids, end) {
        this.setStart(date);
        this.setEnd(end || date);
        this.setAirport(p, airport);
        if ((airport === null || airport === void 0 ? void 0 : airport.latitude) && (airport === null || airport === void 0 ? void 0 : airport.longitude)) {
            this.setRadius(airport.latitude, airport.longitude);
        }
        this.setAffiliates(client_ids);
    }
    toJSON() {
        return {
            name: this.name,
            type: this.type,
            level: this.level,
            hits: this.hits,
            description: this.description,
            radius: this.radius,
            daysBefore: this.daysBefore,
            daysAfter: this.daysAfter,
            followers: (0, model_core_1.toJSON)(this, 'followers'),
            roles: (0, model_core_1.toJSON)(this, 'roles')
        };
    }
    toMinJSON() {
        let r = super.toMinJSON(false);
        Object.assign(r, this.toJSON());
        return r;
    }
}
exports.MarketplaceSearch = MarketplaceSearch;
exports.DEFAULT_QUOTE_SEARCHES = [
    {
        daysBefore: 0,
        daysAfter: 0,
        name: 'Exact',
        description: 'Highest accuracy search: match requested departure day with empty leg departure day, and match the airport exactly.',
        radius: 0,
        level: MARKETPLACE_SEARCH_LEVELS.HIGH_ACCURACY,
        type: MARKETPLACE_SEARCH_TYPES.QUOTE
    },
    {
        daysBefore: 1,
        daysAfter: 1,
        name: 'High',
        description: 'Match requested departure day within one day before/after empty leg scheduled departure, and show airports within 300 miles.',
        radius: 300,
        level: MARKETPLACE_SEARCH_LEVELS.MEDIUM_ACCURACY,
        type: MARKETPLACE_SEARCH_TYPES.QUOTE
    },
    {
        daysBefore: 2,
        daysAfter: 2,
        name: 'Medium',
        description: 'Match requested departure day within two days before/after empty leg scheduled departure, and show airports within 500 miles.',
        radius: 500,
        level: MARKETPLACE_SEARCH_LEVELS.LOW_ACCURACY,
        type: MARKETPLACE_SEARCH_TYPES.QUOTE
    }
];
exports.DEFAULT_SUBSCRIPTION_SEARCHES = [
    {
        name: 'Subscribers (Exact)',
        description: `Highest accuracy search: match email subscribers' home airports exactly with empty leg departure airports.`,
        radius: 0,
        level: MARKETPLACE_SEARCH_LEVELS.HIGH_ACCURACY,
        type: MARKETPLACE_SEARCH_TYPES.SUBSCRIPTION
    },
    {
        name: 'Subscribers (High)',
        description: `Find empty leg departure airports within 100 miles of email subscribers' home airports.`,
        radius: 100,
        level: MARKETPLACE_SEARCH_LEVELS.MEDIUM_ACCURACY,
        type: MARKETPLACE_SEARCH_TYPES.SUBSCRIPTION
    },
    {
        name: 'Subscribers (High)',
        description: `Find empty leg departure airports within 300 miles of email subscribers' home airports.`,
        radius: 300,
        level: MARKETPLACE_SEARCH_LEVELS.LOW_ACCURACY,
        type: MARKETPLACE_SEARCH_TYPES.SUBSCRIPTION
    }
];
function prepareSubscriptionSearch(search, deal, client_ids) {
    var _a, _b, _c, _d;
    search.setAffiliates(client_ids);
    if (!((_a = deal.departureAirport) === null || _a === void 0 ? void 0 : _a.icao) || !((_b = deal.arrivalAirport) === null || _b === void 0 ? void 0 : _b.icao)) {
        return;
    }
    search.addFilter(new model_core_1.AlgoliaQueryFacetFilterItem({
        id: 'home',
        name: 'home',
        key: 'home',
        type: 'facet',
        key_unique: true,
        value: [`home:${(_c = deal.departureAirport) === null || _c === void 0 ? void 0 : _c.icao}`]
    }));
    search.addFilter(new model_core_1.AlgoliaQueryFacetFilterItem({
        id: 'destinations',
        name: 'destinations',
        key: 'destinations',
        type: 'facet',
        key_unique: true,
        value: [`destinations:${(_d = deal.arrivalAirport) === null || _d === void 0 ? void 0 : _d.icao}`]
    }));
    search.addFilter(new model_core_1.AlgoliaQueryFacetFilterItem({
        id: 'active',
        name: 'active',
        key: 'active',
        type: 'facet',
        key_unique: true,
        value: [`active:true`]
    }));
    return search;
}
exports.prepareSubscriptionSearch = prepareSubscriptionSearch;
function getLegScore(d, leg) {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    // Start with a score of a 100 and detract from there.
    let score = {
        total: 100
    };
    // If the deal isn't validated, subtract 20.
    score.verified = d.verified || false;
    if (!d.verified) {
        score.total -= 10;
    }
    // If the departure date isn't on the same day, subtract 10 for each day's difference
    let actual = (0, date_fns_1.startOfDay)(new Date(d.departure_date));
    let desired = (0, date_fns_1.startOfDay)(new Date(leg.times.takeoffUTC));
    score.departureDateDiff = (desired.valueOf() > actual.valueOf()) ? (0, date_fns_1.differenceInDays)(desired.valueOf(), actual.valueOf()) : (0, date_fns_1.differenceInDays)(actual.valueOf(), desired.valueOf());
    if (score.departureDateDiff) {
        score.total -= (10 * score.departureDateDiff);
    }
    // Check the departure airport's distance from desired airport.
    let distance = (0, model_core_1.getDistanceBetweenAddresses)((_a = d.departureAirport) === null || _a === void 0 ? void 0 : _a.latitude, (_b = d.departureAirport) === null || _b === void 0 ? void 0 : _b.longitude, (_c = leg.departureAirport) === null || _c === void 0 ? void 0 : _c.latitude, (_d = leg.departureAirport) === null || _d === void 0 ? void 0 : _d.longitude);
    // Reduce by 5 for every 100 miles of distance.
    // console.log(`${d.departureAirport.icao}->${leg.departureAirport.icao}`, distance);
    score.departureDistance = distance;
    while (distance > 0) {
        score.total -= 5;
        distance -= 50;
        // console.log(`${d.arrivalAirport.icao}->${leg.arrivalAirport.icao}`, score);
    }
    // Check the destination airport's distance from desired airport.
    distance = (0, model_core_1.getDistanceBetweenAddresses)((_e = d.arrivalAirport) === null || _e === void 0 ? void 0 : _e.latitude, (_f = d.arrivalAirport) === null || _f === void 0 ? void 0 : _f.longitude, (_g = leg.arrivalAirport) === null || _g === void 0 ? void 0 : _g.latitude, (_h = leg.arrivalAirport) === null || _h === void 0 ? void 0 : _h.longitude);
    // Reduce by 5 for every 100 miles of distance.
    // console.log(`${d.arrivalAirport.icao}->${leg.arrivalAirport.icao}`, distance);
    score.arrivalDistance = distance;
    while (distance > 0) {
        score.total -= 5;
        distance -= 100;
        // console.log(`${d.arrivalAirport.icao}->${leg.arrivalAirport.icao}`, score);
    }
    if (score.total < 0) {
        score.total = 0;
    }
    return score;
}
exports.getLegScore = getLegScore;
function getSubscriptionScore(d, sub) {
    var _a, _b, _c, _d;
    // Start with a score of a 100 and detract from there.
    let score = {
        total: 100
    };
    // If the deal isn't validated, subtract 20.
    score.verified = d.verified || false;
    if (!d.verified) {
        score.total -= 20;
    }
    // Check the departure airport's distance from desired airport.
    let distance = (0, model_core_1.getDistanceBetweenAddresses)((_a = d.departureAirport) === null || _a === void 0 ? void 0 : _a.latitude, (_b = d.departureAirport) === null || _b === void 0 ? void 0 : _b.longitude, (_c = sub.preferences.home) === null || _c === void 0 ? void 0 : _c.latitude, (_d = sub.preferences.home) === null || _d === void 0 ? void 0 : _d.longitude);
    // Reduce by 5 for every 100 miles of distance.
    // console.log(`${d.departureAirport.icao}->${leg.departureAirport.icao}`, distance);
    score.departureDistance = distance;
    while (distance > 0) {
        score.total -= 5;
        distance -= 100;
        // console.log(`${d.arrivalAirport.icao}->${leg.arrivalAirport.icao}`, score);
    }
    if (score.total < 0) {
        score.total = 0;
    }
    return score;
}
exports.getSubscriptionScore = getSubscriptionScore;
index_1.olm.marketplace_searches = (ref, map) => {
    return new MarketplaceSearch(ref, map);
};
