import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {EMedium, Thread, ThreadMessage} from '@nxt/model-core';
import {NgIf} from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'message-body',
    standalone: true,
    imports: [
        NgIf
    ],
    template: `
<!--        <div class="w-full mt-1 text-gray-600 text-sm" *ngIf="parent.medium !== EMedium.EMAIL && parent.medium !== EMedium.WEB">-->
<!--            <span *ngIf="!parent._html" [innerHTML]="parent._summary"></span>-->
<!--            <span [innerHTML]="parent._html"></span>-->
<!--        </div>-->
        <div class="w-full mt-1 text-gray-600 text-sm">
            <span class="text-xs"  [innerHTML]="html"></span>
        </div>
    `
})
export class MessageBodyComponent implements OnChanges {
    @Input() parent: ThreadMessage|Thread;
    EMedium = EMedium;
    html: any;

    constructor(private sanitizer: DomSanitizer) {
        
    }
    
    ngOnChanges(changes: SimpleChanges) {
        if (this.parent) {
            (this.parent as ThreadMessage)?.message?.replace(/^undefined$/,'').replace(/\n/g, '<br/>');
            this.parent?.summary?.replace(/^undefined$/,'').replace(/\n/g, '<br/>');
            let msg: string = (this.parent as ThreadMessage).messageHtml;
            if (!msg && this.parent?.html && !this.parent.inbound && !this.parent.template_id) {
                msg = this.parent.html;
            }
            msg = msg || this.parent?.summary || (this.parent as ThreadMessage)?.message;
            this.html = this.sanitizer.bypassSecurityTrustHtml(msg);
        }
    }

}
