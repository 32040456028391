import {NgModule, Pipe, PipeTransform} from '@angular/core';
import {CommonModule} from '@angular/common';
import {prettifyPhone, round} from '@nxt/model-core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({name: 'filterBy'})
export class FilterByPipe implements PipeTransform {
    transform(records: any[], config?: any): any {
        let properties: string[] = config.properties;
        let term: string = config.term?.toLowerCase();
        if (records && term) {
            return records.filter((item, n) => {
                if (!term) {
                    return true;
                } else if (!properties?.length) {
                    if (typeof item === 'string') {
                        if (item.toLowerCase().match(term)) {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        return true;
                    }
                } else {
                    for (let p of properties) {
                        if (p.match(/\./)) {
                            let splits: string[] = p.split('.');
                            let parts: string[] = [];
                            let passed: boolean = true;
                            for (let part of splits) {
                                parts.push(part);
                                if (eval(`!item.${parts.join('.')}`)) {
                                    passed = false;
                                    break;
                                }
                            }
                            if (passed) {
                                if (eval(`item.${parts.join('.')}.toLowerCase().match('${term}')`)) {
                                    return true;
                                }
                            }

                        } else if (item[p]?.toLowerCase().match(term)) {
                            return true;
                        }
                    }
                    return false;
                }
            });
        } else {
            return records;
        }
    }
}

@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {
    transform(records: any[], args?: any): any {
        if (records && args?.property) {
            args.direction = args.direction === undefined ? 1 : args.direction;
            let numeric: boolean = args.numeric || false;

            return records.sort((a, b) => {
                if (a && b) {
                    a = (numeric || isNaN(eval(`a.${args.property}`)) === false) ? Number(eval(`a.${args.property}`)) : eval(`a.${args.property}`);
                    b = (numeric || isNaN(eval(`b.${args.property}`)) === false) ? Number(eval(`b.${args.property}`)) : eval(`b.${args.property}`);

                    if (a < b) {
                        return -1 * args.direction;
                    } else if (a > b) {
                        return 1 * args.direction;
                    } else {
                        return 0;
                    }
                } else {
                    return 0;
                }
            });
        }
    }
}

@Pipe({name: 'phone'})
export class PhoneFormatPipe implements PipeTransform {
    transform(number) {
        return prettifyPhone(number);
    }
}

@Pipe({name: 'short_number'})
export class ShortNumberPipe implements PipeTransform {
    transform(n: number | string): string | null {
        if (n && !isNaN(Number(n))) {
            if (typeof n === 'string') {
                n = Number(n);
            }
            if (n < 1000) {
                return n.toString();
            } else if (n < 1000000) {
                return `${round(n/1000, 1)}K`;
            } else {
                return `${round(n/1000000, 2)}M`;
            }
        } else {
            return '0';
        }
    }
}

@Pipe({
    name: 'dateAgo',
    pure: true
})
export class DateAgoPipe implements PipeTransform {

    transform(value: any, args?: any): any {
        if (value) {
            const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
            if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
                return 'Just now';
            const intervals: { [key: string]: number } = {
                'year': 31536000,
                'month': 2592000,
                'week': 604800,
                'day': 86400,
                'hour': 3600,
                'minute': 60,
                'second': 1
            };
            let counter;
            for (const i in intervals) {
                counter = Math.floor(seconds / intervals[i]);
                if (counter > 0)
                    if (counter === 1) {
                        return counter + ' ' + i + ' ago'; // singular (1 day ago)
                    } else {
                        return counter + ' ' + i + 's ago'; // plural (2 days ago)
                    }
            }
        }
        return value;
    }

}
@Pipe({
    standalone: true,
    name: 'abs'
})
export class AbsPipe implements PipeTransform {
    transform(value: number): number {
        return Math.abs(value);
    }
}

@NgModule({
    imports: [CommonModule],
    declarations: [FilterByPipe, OrderByPipe, PhoneFormatPipe, ShortNumberPipe, DateAgoPipe],
    exports: [FilterByPipe, OrderByPipe, PhoneFormatPipe, ShortNumberPipe, DateAgoPipe]
})
export class PipesModule {

}
