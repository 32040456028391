"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Share = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
class Share extends model_core_1.Base {
    constructor(data, olMap) {
        super('sharing', null);
        this.id = '';
        this.date = null;
        this.label = '';
        this.requestingOperator = {};
        this.receivingOperator = {};
        this.lastRequestPath = '';
        this.roles = {};
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, Share);
    }
    async save(parent) {
        var _a;
        if (!this.id) {
            this.id = (_a = this.receivingOperator) === null || _a === void 0 ? void 0 : _a.id;
        }
        if (!this.date) {
            this.date = Date.now();
        }
        if (parent && this.id) {
            this._docRef = (parent._docRef || parent).collection(this._type).doc(this.id);
        }
        if (!this._docRef) {
            throw `Cannot save! No document reference.`;
        }
        else {
            return this._docRef.set(this.toJSON());
        }
    }
}
exports.Share = Share;
index_1.olm.sharing = (ref, map) => {
    return new Share(ref, map);
};
