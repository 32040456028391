import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AbstractControl, UntypedFormGroup} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {BehaviorSubject, Subject} from 'rxjs';
import {debounceTime, filter, takeUntil} from 'rxjs/operators';

// @ts-ignore
import {environment} from '@env/environment';
import {Airport, getCityName} from '@nxt/model-laro';
import {AlgoliaQueryBuilder, AlgoliaQueryFacetFilterItem, IAlgoliaSearchRequest, IMenuItem} from '@nxt/model-core';
import {AutoCompleteComponent} from '@library/nxt/search/autocomplete.component';
import {PageService} from '@library/shared/_services/page.service';
import {OnDestroyPage} from '@library/shared/_inherited/ondestroy.page';
import algoliasearch from 'algoliasearch/lite';

@Component({
    standalone: true,
    imports: [CommonModule, AutoCompleteComponent],
    selector: 'airport-input-component',
    template: `
        <autocomplete [items]="items$|async"
                      [label]="label"
                      [form]="form" [controlName]="controlName"
                      (onKeyup)="airportInputChange($event)"
                      (onClear)="airportSelected.emit(null)"
                      (onSelect)="selectAirport($event)"
        ></autocomplete>
    `
})
export class AirportInputComponent extends OnDestroyPage implements OnInit {
    public results$: BehaviorSubject<Airport[]> = new BehaviorSubject<Airport[]>([]);
    @Output() airportSelected: EventEmitter<Airport> = new EventEmitter<Airport>();
    @Input() label: string;
    @Input() form: UntypedFormGroup;
    @Input() controlName: string;

    @Input() set airport(a: Airport) {
        this._airport = a;
        this.form?.get(this.controlName)?.setValue(this.airportDisplay(this.airport));
    }
    get airport(): Airport {
        return this._airport;
    }
    _airport: Airport;
    items$: BehaviorSubject<IMenuItem[]> = new BehaviorSubject<IMenuItem[]>([]);
    private airportChange$: Subject<string> = new Subject();
    builder: AlgoliaQueryBuilder = new AlgoliaQueryBuilder();

    constructor(
        private pSvc: PageService
    ) {
        super();
    }

    ngOnInit() {

        if (this.form && this.controlName && this.form.get(this.controlName)) {
            if (this.form.get(this.controlName).value instanceof Object) {
                this.airport = this.form.get(this.controlName).value;
            }
        }

        this.airportChange$
            .pipe(
                debounceTime(550),
                filter((val) => !!val && typeof val === 'string' && val.length >= 3)
            )
            .pipe(takeUntil(this.d$))
            .subscribe(async (value) => {
                if (value) {
                    try {

                        await this.searchAirports(value);
                        this.items$.next(this.results$.getValue().map((item: Airport) => {
                            return {value: item.id, label: this.airportDisplay(item), airport: item}
                        }));

                    } catch (e) {
                        this.pSvc.alert$.next(e);
                    }
                    this.pSvc.loading$.next(false);;

                }
            });

        if (this.airport && this.form) {
            this.form?.get(this.controlName)?.setValue(this.airportDisplay(this.airport));
        }

    }

    async searchAirports(value: string) {
        this.pSvc.loading$.next(true);
        this.builder.query = value;
        let iata: AlgoliaQueryFacetFilterItem = new AlgoliaQueryFacetFilterItem({
            id: 'iata',
            name: '',
            key: 'iata',
            type: 'facet',
            key_unique: true,
            value: `iata:${this.builder.query}`
        });
        let icao: AlgoliaQueryFacetFilterItem = new AlgoliaQueryFacetFilterItem({
            id: 'icao',
            name: '',
            type: 'facet',
            key: 'icao',
            key_unique: true,
            value: `icao:${this.builder.query}`
        });
        this.builder.removeFilter(icao);
        this.builder.removeFilter(iata);
        if (this.builder.query?.length === 3) {
            this.builder.addFilter(iata);
            this.builder.query = '';
        } else if (this.builder.query?.length === 4) {
            this.builder.addFilter(icao);
            this.builder.query = '';
        }

        if (environment.algolia) {
            const aClient = algoliasearch(environment.algolia.appId, environment.algolia.searchKey);
            const index = aClient.initIndex('airports');
            let req: IAlgoliaSearchRequest = Object.assign({}, this.builder.toSearchRequest());

            let results: any = await index.search(req.query, req.params);
            if (results?.hits) {
                if (!this.builder.query && results.hits.length === 1) {

                    this.selectAirport({
                        value: results.hits[0].id,
                        label: this.airportDisplay(results.hits[0]),
                        airport: new Airport(results.hits[0])
                    });
                    this.results$.next([]);

                } else if (this.airport && results.hits.length === 1 && results.hits[0].id === this.airport.id) {
                    // do nothing
                } else {
                    this.results$.next(results.hits
                        .sort((a, b) => {
                            let aC = a['_highlightResult']?.city?.name?.matchLevel === 'full';
                            let bC = b['_highlightResult']?.city?.name?.matchLevel === 'full';
                            let aA = a['_highlightResult']?.name?.matchLevel === 'full';
                            let bA = b['_highlightResult']?.name?.matchLevel === 'full';

                            let aI = a.icao?.match(/^K/);
                            let bI = b.icao?.match(/^K/);

                            return (aC && aA && aI && bC && bA && bI) ? 0 : (aC && aA && aI) ? -1 : (aC && aI) ? -1 : 1;

                        })
                        .map(item => {
                            item = new Airport(item);
                            return item;
                        })
                    );
                }

            } else {
                this.results$.next([]);
            }

        } else {
            console.warn('SEARCH SETTINGS NOT CONFIGURED');
        }

    }

    airportInputChange(e: any) {
        this.airportChange$.next(this.form.get(this.controlName).value);
    }

    airportDisplay(airport: Airport, retry?: boolean): string {
        if (airport?.icao) {
            if (airport?.name) {
                return `${airport.icao} : ${airport.name} (${getCityName(airport)})`;
            } else if (!retry) {
                this.searchAirports(airport.icao)
                    .then(
                        r => {
                            if (this.results$.getValue()?.length) {
                                return this.airportDisplay(this.results$.getValue()[0], true);
                            }
                        }
                    )

            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    selectAirport(item: any) {
        this.airport = item?.airport;
        this.airportSelected.emit(this.airport);
    }

}

export function airportValidator(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value !== null && !control.value?.iata && !control.value?.icao) {
        return {'airportValidator': true}
    }
    return null;
}
