import {Component, HostListener, ViewChild, ViewContainerRef} from '@angular/core';
import {Router} from '@angular/router';

import {IModalOptions, PageService} from '@library/shared/_services/page.service';
import {ThemeService} from '@library/shared/_services/theme.service';

// @ts-ignore
import {environment} from '@env/environment';
import {combineLatest} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {ClientService} from '../_services/client.service';
import {UserService} from '../../nxt/_services/user.service';
import {SwUpdate, VersionEvent} from '@angular/service-worker';
import {AlertDialog} from '../alert/alert.dialog';
import {ModalDialog} from '../modal/modal.dialog';
import {ThreadRightClickMenu} from '../../../nxto/src/app/threads/_components/thread-right-click.menu';
import {RightClickMenu} from '../right-click/right-click.menu';
import {MessagingService} from '../../nxt/_services/messaging.service';
import {DomSanitizer} from '@angular/platform-browser';
import {InputDateTimeCalendar} from '../input/input-date-time-calendar';
import {InputDateCalendar} from '../input/input-date-calendar';

@Component({
    selector: 'app-root',
    template: ``
})
export class RootNxtAppComponent {
    @ViewChild('modalContainer', {static: true, read: ViewContainerRef}) modalContainer: ViewContainerRef;
    favIcon: HTMLLinkElement = document.querySelector('#favicon');
    disallowed: boolean = false;
    client_id: string;
    contentClass: string = 'main-content';

    constructor(
        public cSvc: ClientService,
        public mSvc: MessagingService,
        public uSvc: UserService,
        public pSvc: PageService,
        private router: Router,
        tSvc: ThemeService,
        swUpdate: SwUpdate,
        sanitizer: DomSanitizer
    ) {

        tSvc?.setTheme(environment.default_client.theme, this.favIcon);

        cSvc.client$
            .subscribe(
                async (result: any) => {
                    let client_id = result?.client?.id || result?.c?.id;
                    if (client_id !== this.client_id) {
                        this.client_id = client_id;
                        this.pSvc.buildMenu(window.location.pathname);
                    }
                });

        this.pSvc.alert$
            .subscribe(
                (e: any) => {
                    if (e) {
                        let alert: any = {};
                        if (e.title || e.buttons || e.options || e.error?.title) {
                            alert = e.error?.title ? e.error : e;
                        } else if (e.error) {
                            let alert: any = {};
                            ['title', 'message', 'body', 'details'].forEach(p => {
                                alert[p] = (e.error[p] instanceof String && e.error[p].toString() !== 'undefined') ? e.error[p].toString() : '';
                            });
                            ['body', 'details'].forEach(prop => {
                                if (alert[prop]) {
                                    alert.message += ` ${alert[prop]}`;
                                }
                            });
                            ['name'].forEach(prop => {
                                if (alert[prop]) {
                                    alert.title += ` ${alert[prop]}`;
                                }
                            });
                            // console.warn('AFTER', alert);
                            alert = alert;
                            alert.title = alert.title || 'An Error Occurred';
                            alert.message = alert.message || 'Please try again. If the problem persists please contact support.';
                        } else if (e?.message) {
                            alert = {
                                title: e.name || 'An Error Occurred',
                                message: e.message?.toString()
                            }
                        } else {
                            alert = {
                                title: 'Oops!',
                                message: e?.toString()
                            }
                        }
                        if (!alert.title && e.statusText) {
                            alert.title = e.statusText;
                            alert.message = e.message || '';
                        }
                        if (e?.status === 500 && !this.uSvc.user$.getValue()) {
                            console.log('MAYBE NOT LOGGED IN?');
                        }

                        this.pSvc.modal$.next({
                            component: AlertDialog,
                            buttons: alert.buttons,
                            label: alert.title,
                            onLoaded: (comp: AlertDialog) => {
                                comp.alert = alert;
                                if (e.html) {
                                    comp.html = sanitizer.bypassSecurityTrustHtml(e.html);
                                }
                                console.warn('e', e);
                                console.warn('alert', alert);
                                console.warn('alert?.message', alert?.message);
                                this.pSvc.loading$.next(false);;
                                this.pSvc.blocking$.next(false);
                            },
                            styles: alert.styles
                        });
                    }
                }
            );

        this.pSvc.modal$.subscribe(
            (opts: IModalOptions) => {

                setTimeout(() => {
                    if (opts) {
                        let comp: any = ModalDialog;
                        switch (opts.component) {
                            case ThreadRightClickMenu:
                            case RightClickMenu:
                            case InputDateCalendar:
                            case InputDateTimeCalendar:
                                comp = opts.component;
                                break;
                        }
                        let ref: any = this.modalContainer.createComponent<any>(comp);
                        if (ref.instance?.init) {
                            ref.instance.init(opts);
                        }
                        ref.instance.onClose.pipe(take(1))
                            .subscribe(e => {
                                // Fake an esc click
                                switch (comp) {
                                    case ThreadRightClickMenu:
                                    case RightClickMenu:
                                    case InputDateCalendar:
                                    case InputDateTimeCalendar:
                                        if (this.modalContainer.length) {
                                            this.modalContainer.remove(this.modalContainer.length - 1);
                                        }
                                        if (!this.modalContainer.length) {
                                            this.pSvc.modal$.next(null);
                                        }
                                        break;
                                    default:
                                        this.pSvc.clickEsc$.next(true);
                                        break;
                                }
                            });

                        if (opts?.onLoaded) {
                            try {
                                switch (opts.component) {
                                    case ThreadRightClickMenu:
                                    case RightClickMenu:
                                    case InputDateCalendar:
                                    case InputDateTimeCalendar:
                                        opts.onLoaded(ref.instance);
                                        break;
                                }
                            } catch (e) {
                                console.warn(e);
                            }
                        }
                    }
                });
            }
        );

        this.pSvc.clickEsc$
            .subscribe(
                r => {
                    if (r) {
                        if (this.modalContainer.length) {
                            this.modalContainer.remove(this.modalContainer.length - 1);
                            setTimeout(() => {
                                if (!this.modalContainer.length) {
                                    this.pSvc.modal$.next(null);
                                }
                            }, 200);
                        }
                    }
                }
            );

        combineLatest([pSvc.dimensions$, mSvc.thread$])
            .pipe(map(res => {
                return {dims: res[0], thread: res[1]};
            }))
            .subscribe(
                result => {

                    // this.pSvc.thirdCol$.next(result.dims.small);
                    if (result?.dims?.desktop) {
                        this.contentClass = 'main-content-full';
                        this.pSvc.colClass = 'w-1/2';
                    } else if (result?.thread) {
                        this.contentClass = 'main-content-half';
                        this.pSvc.colClass = 'w-full';
                    } else {
                        this.contentClass = 'main-content';
                        this.pSvc.colClass = 'w-full';
                    }
                }
            );

        combineLatest([cSvc.c$, uSvc.roles$, pSvc.roles$])
            .pipe(map(res => {
                return {client: res[0], uRoles: res[1], rRoles: res[2]};
            }))
            .subscribe(
                result => {
                    if (result.uRoles !== undefined && result.rRoles !== undefined) {
                        //console.log('client', result.client);
                        // console.log('userRoles', result.uRoles);
                        // console.log('requiredRoles', result.rRoles);
                        if (result.rRoles) {
                            if (!result.uRoles) {
                                this.disallowed = false;
                                // console.log('NOT ALLOWED! NOT SIGED IN AT ALL');
                            } else if (!this.uSvc.isRole(result.rRoles)) {
                                this.disallowed = true;
                                // console.log('NOT ALLOWED! USER DOES NOT HAVE ROLE',result.rRoles);
                            } else {
                                this.disallowed = false;
                            }
                        } else {
                            this.disallowed = false;
                        }
                    }
                })

        if (swUpdate.isEnabled) {
            swUpdate.versionUpdates.subscribe((u:VersionEvent) => {
                if  (u?.type === 'VERSION_READY') {
                    // Only pop the alert for signed-in user.
                    this.pSvc.notification$.next({
                            timeout: 0,
                            title: 'A New Version Is Available!',
                            message: 'To get the new version, click Reload or refresh your browser.',
                            buttons: [
                                {
                                    label: 'Change Log',
                                    class: 'btn-dark',
                                    click: () => {
                                        window.open(`/change_log`);
                                    }
                                },
                                {
                                    label: 'Reload',
                                    class: 'btn-dark',
                                    click: () => {
                                        window.location.reload();
                                    }
                                }
                            ]
                        });
                }
            });
        }


    }

    @HostListener('document:click', ['$event']) handleClick(e) {
        this.pSvc.click$.next(e);
        // Detect platform
        if (e.target['getAttribute']('data-url')) {
            e.preventDefault();
            if (e.target['getAttribute']('target') === '_blank') {
                window.open(e.target['getAttribute']('data-url'), "_blank");
            } else {
                this.router.navigate([e.target['getAttribute']('data-url')]);
            }
        } else if (e.target['getAttribute']('href') && e.target['getAttribute']('href').match(/^tel/)) {
            // Click to call event here.
            // this.aSvc.track('mixpanel', 'Clicked to Call', {'Number': environment.prettyPhone });
            // this.aSvc.track('ga', 'config', {'phone_conversion_number': environment.prettyPhone, 'send_to': environment.awid+'/m2MpCP7Gw-oBEJmFlsAC'});

        } else if (e.target['getAttribute']('href') && e.target['getAttribute']('href').match(/^mailto/)) {
            // Click to email event here.
            // this.aSvc.track('mixpanel', 'Clicked to Email',
            //     {
            //         'Address': e.target['getAttribute']('href').split('?')[0].replace('mailto:', ''),
            //         'Subject': e.target['getAttribute']('href').split('?')[1].replace('subject=', '').replaceAll('%20', ' ')
            //     });
        }
    }

    @HostListener('window:keydown', ['$event']) handleKey(e) {
        if (e) {
            let charCode = String.fromCharCode(e.which).toLowerCase();
            if ((navigator.platform.match('Mac') && e.metaKey && charCode === 's') || (!navigator.platform.match('Mac') && e.ctrlKey && charCode === 's')) {
                e.preventDefault();
                // this.pSvc.clickSave$.next(true);
            } else if (e.key === 'Escape') {
                this.pSvc.clickEsc$.next(true);
            }
        }
    }
}
