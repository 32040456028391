import {Injectable} from '@angular/core';
import {ClientService} from '../../shared/_services/client.service';
import {IMenu, IMenuItem} from '@nxt/model-core';

import {NxtMenuService} from '../../nxt/_services/nxt.menu.service';
import {ESchedulingSystems} from '@nxt/model-laro';

@Injectable()
export class LaroOperatorMenuService extends NxtMenuService {
    get dashboard(): IMenuItem {
        return {
            label: 'Dashboard',
            routerLink: `/${this.cSvc.name_key}/dashboard`,
            icon: {name: 'heroicon-outline-presentation-chart-bar'},
            roles: ['isDefault','user']
        };
    }

    get trips(): IMenuItem {
        return {
            label: 'Trips',
            routerLink: `/${this.cSvc.name_key}/trips`,
            icon: {name: 'custom-flight-takeoff'},
            roles: ['!isDefault','trips-r']
        };
    }

    get data_accounts(): IMenuItem {
        return {
            label: 'Accounts',
            id: 'accounts',
            hide: true,
            routerLink: `/${this.cSvc.name_key}/data/accounts`,
            icon: {name: 'heroicon-outline-archive'},
            roles: ['!isDefault','accounts-r']
        };
    }
    get data_fleet(): IMenuItem {
        return {
            label: 'Fleet',
            routerLink: `/${this.cSvc.name_key}/data/aircraft/fleet`,
            icon: {name: 'heroicon-outline-paper-airplane'},
            roles: ['!isDefault', 'aircraft']
        };
    }
    get data_pricing(): IMenuItem {
        return {
            label: 'Pricing',
            routerLink: `/${this.cSvc.name_key}/data/pricing`,
            icon: {name: 'heroicon-outline-currency-dollar'},
            roles: ['!isDefault', 'pricing-r']
        }
    };
    get data_models(): IMenuItem {
        return {
            label: 'Models',
            routerLink: `/${this.cSvc.name_key}/data/aircraft/models`,
            icon: {name: 'heroicon-outline-paper-airplane'},
            roles: ['isDefault', 'aircraft']
        }
    };
    get data_airports(): IMenuItem {
        return {
            label: 'Airports',
            routerLink: `/${this.cSvc.name_key}/data/airports`,
            icon: {name: 'heroicon-outline-office-building'},
            roles: ['isDefault', 'airports-r']
        }
    };
    get data(): IMenuItem {
        return {
            label: 'Data',
            id: 'data',
            icon: {name: 'heroicon-outline-database'},
            pathMatch: `/${this.cSvc.name_key}/data`,
            roles: ['aircraft','accounts','invoices','affiliates','airports','pricing','payments'],
            items: [
            this.data_accounts,
            this.data_fleet,
            this.data_pricing,
            this.data_models,
            this.data_airports,
            this.data_products
        ]
        }
    };
    get marketplace_affiliates(): IMenuItem {
        return {
            label: 'Settings',
            routerLink: `/${this.cSvc.name_key}/marketplace/settings`,
            icon: {name: 'heroicon-outline-hand'},
            roles: ['!isDefault','affiliates-r','marketplace-r']
        }
    };
    get marketplace_dashboard(): IMenuItem {
        return {
            label: 'Dashboard',
            routerLink: `/${this.cSvc.name_key}/marketplace`,
            icon: {name: 'heroicon-outline-chart-square-bar'},
            roles: ['!isDefault','affiliates-r','marketplace-r']
        }
    };
    get marketplace_empties(): IMenuItem {
        return {
            label: 'Empties/Deals',
            routerLink: `/${this.cSvc.name_key}/marketplace/empties`,
            icon: {name: 'heroicon-outline-ticket'},
            roles: ['!isDefault', 'marketplace-r']
        }
    };
    get marketplace(): IMenuItem {
        return {
            label: 'Marketplace',
            icon: {name: 'heroicon-outline-lightning-bolt'},
            pathMatch: `/${this.cSvc.name_key}/marketplace`,
            roles: ['affiliates-r','marketplace-r','!isDefault'],
            items: [
                this.marketplace_dashboard,
                this.marketplace_affiliates,
                this.marketplace_empties,
            ]
        }
    }

    get sales(): IMenuItem{
        return {
            label: 'Flight Requests',
            routerLink: `/${this.cSvc.name_key}/levo-ai/requests`,
            icon: {name: 'heroicon-outline-filter'},
            roles: ['quotes']
        }
    }

    get availability(): IMenuItem{
        return {
            label: 'Flight Availability',
            routerLink: `/${this.cSvc.name_key}/levo-ai/availability`,
            icon: {name: 'custom-plane'},
            roles: ['quotes']
        }
    }

    get smartConnect(): IMenuItem {
        return {
            label: 'Levo AI™',
            id: 'sales',
            icon: {name: 'custom-ai'},
            pathMatch: `/${this.cSvc.name_key}/levo-ai`,
            roles: ['quotes','!isDefault'],
            items: [
                this.sales
                ,this.availability
            ]
        }
    }

    constructor(public cSvc: ClientService) {
        super(cSvc);
    }

    async getMenu(): Promise<IMenu> {
        let result: IMenu = {
            items:[
                this.dashboard,
                this.inbox,
                this.orders,
                this.smartConnect,
                this.quotes,
                this.trips,
                this.marketplace,
                this.marketing,
                this.data,
                this.content,
                this.company,
                this.tasks
            ]
        };

        let showHideItem = (first, second, hide) => {
            let item:any = result.items.find(i => i.id === first);
            if (item) {
                if (!second) {
                    item.hide = hide;
                } else {
                    item = item?.items?.find(i => i.id === second);
                    if (item) {
                        item.hide = hide;
                    } else {
                        console.warn(`Nothing found on second level at ${second}`);
                    }
                }
            } else {
                console.warn(`Nothing found on first level at ${first}`);
            }
        };

        try {
            switch (this.cSvc.name_key) {
                case 'LARO':
                    result.items.find(item => item.id === 'tasks').label = 'Tasks';
                    showHideItem('orders','',false);
                    showHideItem('quotes','',true);
                    showHideItem('sales','',true);
                    break;
                default:
                    result.items.find(item => item.id === 'tasks').label = 'Support';
                    showHideItem('cms','',true);
                    showHideItem('marketing','documents',true);
                    showHideItem('data','products',true);
                    showHideItem('quotes','',false);
                    let c = this.cSvc.client$.getValue();
                    if (c?.config?.software?.type === ESchedulingSystems.AVIANIS) {
                        showHideItem('data','accounts',false);
                    }
                    break;
            }
        } catch (e) {
            console.warn(e);
        }
        return result;
    }
}
