"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.parseBrokerRequest = exports.getScoreColor = exports.QuoteRequest = exports.Quote = void 0;
const model_core_1 = require("@nxt/model-core");
const Trip_1 = require("./Trip");
const index_1 = require("../index");
const date_fns_1 = require("date-fns");
class Quote extends Trip_1.Trip {
    get subjectSummary() {
        var _a, _b, _c, _d;
        let result = [`Quote ${this.quote_number ? '#' + this.quote_number : ''}`];
        let aircraft = [];
        let legs = [];
        let date = '';
        if ((_a = this.legs) === null || _a === void 0 ? void 0 : _a.length) {
            this.sortLegs();
            let n = 0;
            for (let leg of this.legs) {
                if (!leg.isEmptyLeg && leg.active) {
                    if (!date) {
                        date = leg.times.takeoffDate;
                    }
                    if (((_b = leg.aircraft) === null || _b === void 0 ? void 0 : _b.tail_number) && !aircraft.find(a => { var _a; return a.match((_a = leg.aircraft) === null || _a === void 0 ? void 0 : _a.tail_number); })) {
                        aircraft.push((_c = leg.aircraft) === null || _c === void 0 ? void 0 : _c.tail_number);
                    }
                    else {
                        aircraft.push('');
                    }
                    switch (this.legs.length) {
                        case 2:
                            if (n === 0) {
                                legs.push([leg.departureAirport.iata || leg.departureAirport.icao, leg.arrivalAirport.iata || leg.arrivalAirport.icao]);
                            }
                            else {
                                legs.push([leg.arrivalAirport.iata || leg.arrivalAirport.icao]);
                            }
                            break;
                        default:
                            legs.push([leg.departureAirport.iata || leg.departureAirport.icao, leg.arrivalAirport.iata || leg.arrivalAirport.icao]);
                            break;
                    }
                }
                n++;
            }
        }
        if (date) {
            result.push(date);
        }
        if ((_d = this.contact) === null || _d === void 0 ? void 0 : _d.name) {
            result.push(this.contact.name);
        }
        if (this.accountNames) {
            result.push(this.accountNames);
        }
        return `${result.join(' • ')} • ${legs.map((leg, n) => {
            if (aircraft[n]) {
                return `${aircraft[n]}: ${leg.join('-')}`;
            }
            else {
                return leg.join('-');
            }
        }).join('; ')}`;
    }
    constructor(data, olMap, type, idLen) {
        var _a;
        super(data, olMap || index_1.olm, type || 'quotes', idLen || 21);
        this.quoteType = '';
        this.terms = '';
        this.score = null;
        this.legs = [];
        this.services = [];
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, Quote);
        if ((_a = this.legs) === null || _a === void 0 ? void 0 : _a.length) {
            this.name = this.subjectSummary;
        }
    }
}
exports.Quote = Quote;
class QuoteRequest extends Quote {
    constructor(data, olMap) {
        super(data, olMap || index_1.olm, 'quotes', 21);
        this.notes = '';
        this.source = null;
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, QuoteRequest);
    }
}
exports.QuoteRequest = QuoteRequest;
function getScoreColor(score) {
    if ((score === null || score === void 0 ? void 0 : score.total) >= 80) {
        return '#07de2b';
    }
    else if ((score === null || score === void 0 ? void 0 : score.total) >= 65) {
        return '#d7ea28';
    }
    else if ((score === null || score === void 0 ? void 0 : score.total) >= 50) {
        return '#f58738';
    }
    else {
        return '#bd1c1c';
    }
}
exports.getScoreColor = getScoreColor;
index_1.olm.quotes = (ref, map) => {
    return new Quote(ref, map);
};
function parseBrokerRequest(subject, settings) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    let data = { skip: false };
    let copy = subject.toString();
    if (settings === null || settings === void 0 ? void 0 : settings.elimination) {
        for (let string_finder of settings.elimination) {
            let re = new RegExp(string_finder.regex);
            let matches = re.exec(copy);
            if (matches === null || matches === void 0 ? void 0 : matches.length) {
                data.skip = true;
                break;
            }
        }
    }
    if (!data.skip) {
        for (let id of ['string', 'trip', 'id', 'pax', 'date', 'airport']) {
            let pattern = (_a = settings === null || settings === void 0 ? void 0 : settings.patterns) === null || _a === void 0 ? void 0 : _a.find(p => p.type === id);
            if (pattern) {
                switch (pattern.type) {
                    case 'string':
                        for (let string_finder of pattern.string_regex) {
                            let re = new RegExp(string_finder.regex, 'gi');
                            let matches = re.exec(copy);
                            if (matches === null || matches === void 0 ? void 0 : matches.length) {
                                copy = copy.replace(re, '');
                            }
                        }
                        break;
                    case 'trip':
                        for (let type_finder of pattern.trip_type) {
                            let re = new RegExp(type_finder.regex);
                            if (re.exec(copy)) {
                                data.tripType = type_finder.trip_type_value;
                                copy = copy.replace(re, '');
                            }
                        }
                        break;
                    case 'id':
                        for (let id_finder of pattern.request_id) {
                            let re = new RegExp(id_finder.regex);
                            let matches = re.exec(copy);
                            if ((_b = matches === null || matches === void 0 ? void 0 : matches.groups) === null || _b === void 0 ? void 0 : _b.id) {
                                data.id = (_c = matches === null || matches === void 0 ? void 0 : matches.groups) === null || _c === void 0 ? void 0 : _c.id;
                                copy = copy.replace(re, '');
                            }
                        }
                        break;
                    case 'pax':
                        for (let pax_finder of pattern.pax) {
                            let re = new RegExp(pax_finder.regex);
                            let matches = re.exec(copy);
                            if ((_d = matches === null || matches === void 0 ? void 0 : matches.groups) === null || _d === void 0 ? void 0 : _d.paxNum) {
                                data.paxNum = Number((_e = matches === null || matches === void 0 ? void 0 : matches.groups) === null || _e === void 0 ? void 0 : _e.paxNum);
                                copy = copy.replace(re, '');
                            }
                        }
                        break;
                    case 'date':
                        for (let finder of pattern.date_finder) {
                            let re = new RegExp(finder.regex);
                            let matches = re.exec(copy);
                            let n = 0;
                            while (matches) {
                                if ((_f = matches === null || matches === void 0 ? void 0 : matches.groups) === null || _f === void 0 ? void 0 : _f.departureDate) {
                                    if (!data.legs) {
                                        data.legs = [];
                                    }
                                    data.legs[n] = data.legs[n] || {};
                                    data.legs[n].departureDate = (0, date_fns_1.parse)(`${(_g = matches === null || matches === void 0 ? void 0 : matches.groups) === null || _g === void 0 ? void 0 : _g.departureDate} 12:00`, `${finder.date_format} HH:mm`, new Date());
                                    if ((_h = matches === null || matches === void 0 ? void 0 : matches.groups) === null || _h === void 0 ? void 0 : _h.returnDate) {
                                        if (finder.date_format_two) {
                                            data.legs[n].returnDate = (0, date_fns_1.parse)(`${(_j = matches === null || matches === void 0 ? void 0 : matches.groups) === null || _j === void 0 ? void 0 : _j.returnDate} 12:00`, `${finder.date_format_two} HH:mm`, data.departureDate);
                                        }
                                        else {
                                            data.legs[n].returnDate = (0, date_fns_1.parse)(`${(_k = matches === null || matches === void 0 ? void 0 : matches.groups) === null || _k === void 0 ? void 0 : _k.returnDate} 12:00`, `${finder.date_format} HH:mm`, data.departureDate);
                                        }
                                    }
                                    copy = copy.replace(re, '');
                                }
                                n++;
                                matches = re.exec(copy);
                            }
                        }
                        break;
                    case 'airport':
                        let x = 0;
                        for (let airport_finder of pattern.airports) {
                            x++;
                            let re = new RegExp(airport_finder.regex);
                            let matches = re.exec(copy);
                            let n = 0;
                            while (matches) {
                                // console.log(x);
                                // console.log(airport_finder.regex, matches?.groups);
                                if ((_l = matches === null || matches === void 0 ? void 0 : matches.groups) === null || _l === void 0 ? void 0 : _l.departureAirport) {
                                    if (!data.legs) {
                                        data.legs = [];
                                    }
                                    data.legs[n] = data.legs[n] || {};
                                    data.legs[n].departureAirport = (_m = matches === null || matches === void 0 ? void 0 : matches.groups) === null || _m === void 0 ? void 0 : _m.departureAirport;
                                    data.legs[n].arrivalAirport = (_o = matches === null || matches === void 0 ? void 0 : matches.groups) === null || _o === void 0 ? void 0 : _o.arrivalAirport;
                                    copy = copy.replace(re, '');
                                }
                                n++;
                                matches = re.exec(copy);
                            }
                        }
                        break;
                }
            }
        }
    }
    let errors = [];
    if (data.skip) {
        errors.push('Elimination pattern found. Skip.');
    }
    else if (!((_p = data.legs) === null || _p === void 0 ? void 0 : _p.length)) {
        errors.push('No legs found.');
    }
    else {
        switch (data.legs.length) {
            case 1:
                if (data.tripType && data.tripType !== Trip_1.ETripType.ONE_WAY) {
                    if (data.tripType === Trip_1.ETripType.ROUND_TRIP && data.legs.length === 1) {
                        data.legs[1] = Object.assign({}, data.legs[0]);
                        data.legs[1].departureAirport = data.legs[0].arrivalAirport;
                        data.legs[1].arrivalAirport = data.legs[0].departureAirport;
                    }
                    else {
                        errors.push(`Trip type mismatch. ${data.legs.length} legs; trip type: ${data.tripType}`);
                    }
                }
                else {
                    data.tripType = Trip_1.ETripType.ONE_WAY;
                }
                break;
            case 2:
                if (data.tripType && data.tripType !== Trip_1.ETripType.ROUND_TRIP) {
                    if (data.tripType === Trip_1.ETripType.ONE_WAY) {
                        data.legs.splice(1);
                    }
                    else if (data.tripType === Trip_1.ETripType.MULTI_LEG) {
                        data.tripType = Trip_1.ETripType.ROUND_TRIP;
                    }
                    else {
                        errors.push(`Trip type mismatch. ${data.legs.length} legs; trip type: ${data.tripType}`);
                    }
                }
                else {
                    data.tripType = Trip_1.ETripType.ROUND_TRIP;
                }
                break;
            default:
                if (data.tripType && data.tripType !== Trip_1.ETripType.MULTI_LEG) {
                    if (data.legs.length === 2) {
                        data.tripType = Trip_1.ETripType.ROUND_TRIP;
                    }
                    else {
                        errors.push(`Trip type mismatch. ${data.legs.length} legs; trip type: ${data.tripType}`);
                    }
                }
                else {
                    data.tripType = Trip_1.ETripType.MULTI_LEG;
                }
                break;
        }
    }
    (_q = data.legs) === null || _q === void 0 ? void 0 : _q.forEach((leg, n) => {
        if (!leg.departureAirport) {
            if (n > 0) {
                data.legs[n].departureAirport = data.legs[n - 1].arrivalAirport;
            }
            else {
                errors.push(`Missing departure airport on leg ${n}`);
            }
        }
        if (!leg.arrivalAirport) {
            if (n > 0) {
                data.legs[n].arrivalAirport = data.legs[n - 1].departureAirport;
            }
            else {
                errors.push(`Missing arrival airport on leg ${n}`);
            }
        }
        if (!leg.departureDate) {
            if (n > 0) {
                data.legs[n].departureDate = data.legs[n - 1].departureDate;
            }
            else {
                data.legs[n].departureDate = new Date();
            }
        }
        if (leg.departureDate && !leg.returnDate) {
            data.legs[n].returnDate = leg.departureDate;
        }
    });
    return [data, errors, copy];
}
exports.parseBrokerRequest = parseBrokerRequest;
