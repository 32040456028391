import {Input, Component, EventEmitter, Output} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Router, RouterModule} from '@angular/router';
import {BehaviorSubject} from 'rxjs';

import {IMenu, IMenuItem} from '@nxt/model-core';

import {IconsComponent} from '../../shared/icons/icons.component';
import {PageService} from '../../shared/_services/page.service';
import {UserService} from '../_services/user.service';

@Component({
    standalone: true,
    imports: [CommonModule, IconsComponent, RouterModule],
    selector: 'sidebar-component',
    template: `

        <ng-template #menuTemplate>
            <ng-container *ngFor="let item of menu.items">
                <ng-container *ngIf="uSvc.isRole(item.roles) && !item.hide">
                    <a (click)="menuItemClick(item)" [title]="item.label"
                       [class]="(item.active) ? 'menuItemActive' : 'menuItemDefault'"
                    >
                        <div class="flex">
                            <icon [name]="item.icon?.name"
                                  [class]="'mr-3 flex-shrink-0 h-6 w-6 text-accent-300'"></icon>
                            <span *ngIf="!(pSvc.thirdCol$|async)">{{ item.label }}</span>
                        </div>
                        <icon name="heroicon-outline-chevron-down" *ngIf="!item.routerLink && !item.open"
                              class="h-4 w-4"></icon>
                        <icon name="heroicon-outline-chevron-up" *ngIf="!item.routerLink && item.open"
                              class="h-4 w-4"></icon>
                    </a>

                    <div class="space-y-1 pl-4 text-right" *ngIf="item.items && item.open">
                        <ng-container *ngFor="let subitem of item.items">
                            <ng-container *ngIf="uSvc.isRole(subitem.roles) && !subitem.hide">
                                <a (click)="menuItemClick(subitem)" [title]="subitem.label"
                                   [routerLink]="subitem.routerLink"
                                   [class]="(subitem.active) ? subitem.class?.active || 'subMenuItemActive' : subitem.class?.default || 'subMenuItemDefault'"
                                >
                                    <icon [name]="subitem.icon?.name"
                                          [class]="'mr-3 flex-shrink-0 h-4 w-4 text-accent-300'"></icon>
                                    <span class="text-sm" *ngIf="!(pSvc.thirdCol$|async)">{{ subitem.label }}</span>
                                </a>
                            </ng-container>
                        </ng-container>
                    </div>

                </ng-container>
            </ng-container>
        </ng-template>

        <div *ngIf="!(hide$ | async) && menu" class="h-full bg-accent-700 p-2 space-y-1"
             [style.width]="(pSvc.thirdCol$|async) ? '80px':'192px'">
            <div class="fixed max-h-full overflow-y-auto pb-32" [style.width]="(pSvc.thirdCol$|async) ? '64px':'176px'">
                <ng-container *ngTemplateOutlet="menuTemplate"></ng-container>
            </div>
        </div>

    `
})
export class SidebarComponent {
    @Input() menu: IMenu;
    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
    hide$: BehaviorSubject<boolean> = new BehaviorSubject(null);

    constructor(
        public pSvc: PageService,
        public uSvc: UserService,
        private router: Router
    ) {

        pSvc.menu$.subscribe(menu => this.menu);
        pSvc.dimensions$.subscribe(
            dims => {
                if (dims) {
                    this.hide$.next(dims.mobile||dims.small);
                }
            }
        );

    }

    toggle() {
        this.hide$.next(!this.hide$.getValue());
    }


    menuItemClick(item: IMenuItem) {
        if (item.roles) {
            this.pSvc.roles$.next(item.roles);
        }
        if (!item.routerLink) {
            item.open = !item.open;
        } else {
            item.active = true;
        }
        if (this.pSvc.dimensions$.getValue()?.mobile
            && this.hide$.getValue() === false
            && (item.routerLink || item.click)
        ) {
            this.hide$.next(true);
        }
        if (item.click instanceof Function) {
            item.click();
        } else if (item.routerLink) {
            this.router.navigate([item.routerLink], {});
        }
        this.onClick.emit();
        // if (thirdColBefore) {
        //     this.pSvc.thirdCol$.next(true);
        // }
    }
}
