import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
    UntypedFormControl
} from '@angular/forms';
import {CommonModule} from '@angular/common';

import {Airport, TripLeg} from '@nxt/model-laro';
import {AirportInputComponent} from './airport-input.component';

import {parse, format} from 'date-fns';
import {InputStackedTextComponent} from '@library/shared/input/input-stacked-text.component';
import {InputDateTimeComponent} from '@library/shared/input/input-date-time.component';
import {InputDateComponent} from '@library/shared/input/input-date.component';

@Component({
    standalone: true,
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule,
        AirportInputComponent, InputStackedTextComponent, InputDateTimeComponent, InputDateComponent
    ],
    selector: 'leg-form',
    template: `
        <form [formGroup]="form" *ngIf="form">
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3">

                <airport-input-component
                        #departureAirport
                        controlName="departureAirport"
                        [form]="form"
                        (airportSelected)="setAirport('departureAirport',$event)"
                        label="FROM"
                ></airport-input-component>

                <airport-input-component
                        #arrivalAirport
                        controlName="arrivalAirport"
                        [form]="form"
                        (airportSelected)="setAirport('arrivalAirport',$event)"
                        label="TO"
                ></airport-input-component>
                
                <input-date *ngIf="!includeTime" 
                            [form]="form"
                            controlName="takeoff"
                            [label]="requireDate ? 'Date' : 'Date (optional)'"
                            [minDate]="today"
                            (onChange)="setTakeoff($event, false)" 
                >
                </input-date>

                <input-date-time *ngIf="includeTime" 
                                 [form]="form" 
                                 controlName="takeoff"
                                 [label]="requireDate ? 'Date/Time' : 'Date/Time (optional)'"
                                 [minDate]="today" 
                                 [hours]="['5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','21','22','23']" 
                                 (onChange)="setTakeoff($event, true)"
                >
                </input-date-time>
            

            </div>

        </form>
    `
})
export class LegFormComponent implements OnChanges {
    @Output() onChange: EventEmitter<TripLeg> = new EventEmitter<TripLeg>();
    @Output() onLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() form: UntypedFormGroup;
    @Input() includeTime: boolean = false;
    @Input() requireTime: boolean = false;
    @Input() includeDate: boolean = false;
    @Input() requireDate: boolean = false;
    @Input() leg: TripLeg;
    @ViewChild('departureAirport') departureAirport: AirportInputComponent;
    @ViewChild('arrivalAirport') arrivalAirport: AirportInputComponent;
    today: Date = new Date();
    get takeoff(): string {
        if (this.includeTime && this.leg.times.takeoffDate && this.leg.times.takeoffTime) {
            return `${this.leg.times.takeoffDate} ${this.leg.times.takeoffTime}`
        } else if (!this.includeTime && this.leg.times.takeoffDate) {
            return this.leg.times.takeoffDate;
        } else {
            return '';
        }
    }

    constructor(private fb: UntypedFormBuilder) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.leg && !this.form) {
            this.form = this.fb.group({
                departureAirport: [this.leg.departureAirport || new Airport(), [Validators.required]],
                arrivalAirport: [this.leg.arrivalAirport || new Airport(), [Validators.required]],
                takeoff: [this.takeoff, this.requireDate ? [Validators.required,this.validDate] : null],
            });
        }
        this.onLoaded.emit(true);
    }

    public validDate(control: UntypedFormControl) {
        let valid: any = control.value ? true : null;
        let d: Date = parse(control.value,  'M/d/yyyy H:mm', new Date());
        if (isNaN(d.valueOf())) {
            valid = {'required': true};
        }
        return valid;
    }

    // Disallow same airport for takeoff and landing
    // ValidateAirports(control: AbstractControl) {
    //     if (!control.value) {
    //         return {required: true};
    //     }
    //     return null;
    // }


    setAirport(prop: string, airport: Airport) {
        this.leg[prop] = airport;
        this.onChange.emit(this.leg);
    }

    setTakeoff(takeoff: Date, includesTime: boolean) {
        console.log('takeoff', takeoff);
        this.leg.times.takeoffDate = format(takeoff, 'M/d/yyyy');
        if (includesTime) {
            this.leg.times.takeoffTime = format(takeoff, 'h:mm a');
        } else {
            this.leg.times.takeoffTime = '10:00 AM';
        }
        let d: Date = parse(`${this.leg.times.takeoffDate} ${this.leg.times.takeoffTime}`, `M/d/yyyy h:mm a`, new Date());
        this.leg.times.takeoffUTC = d.valueOf();
    }

    save() {
        this.onChange.emit(this.leg);
        return this.leg;
    }

}
