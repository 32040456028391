"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PriceItem = exports.EPriceLogicVerbs = exports.PriceLogicSubjectDisplay = exports.PriceLogicSubjectMap = exports.EPriceLogicSubjects = exports.EDenominations = exports.EPriceItemTypes = exports.PriceItemTypes = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
exports.PriceItemTypes = {
    qty: { label: 'Quantity', description: 'Pricing rate is based on an integer quantity (e.g. number of overnights)' },
    blocktime: {
        label: 'Block Time',
        description: 'Time-based rate using block time as multiplier.',
        discounting_description: 'Override regular block time based rate.'
    },
    flighttime: {
        label: 'Flight Time',
        description: 'Time-based rate using actual flight time as multiplier.',
        discounting_description: 'Override regular flight time based rate.'
    },
    waittime: {
        label: 'Wait Time',
        description: 'For same day flights, time between landing and departure.',
        discounting_description: 'Override regular with time based rate.'
    },
    repo: {
        label: 'Reposition',
        description: 'Fees charged for repositioning aircraft based on departure or arrival airport.'
    },
    percent: { label: 'Percent', description: 'Percent-based rate, applied to the base cost of the flight (e.g. FET)' },
    flat: { label: 'Flat', description: 'Flat rate fee, not based on any quantities or flight time values.' },
    decline: {
        label: 'Decline',
        description: 'Rule that, if met, will not quote the flight (e.g. certain international flights).',
        discounting_description: 'This type of rule will hide the flight from the public.'
    }
};
var EPriceItemTypes;
(function (EPriceItemTypes) {
    EPriceItemTypes["QUANTITY"] = "qty";
    EPriceItemTypes["BLOCK_TIME"] = "blocktime";
    EPriceItemTypes["FLIGHT_TIME"] = "flighttime";
    EPriceItemTypes["WAIT_TIME"] = "waittime";
    EPriceItemTypes["REPOSITION"] = "repo";
    EPriceItemTypes["PERCENT"] = "percent";
    EPriceItemTypes["FLAT"] = "flat";
    EPriceItemTypes["DECLINE"] = "decline";
    EPriceItemTypes["NONE"] = "";
})(EPriceItemTypes || (exports.EPriceItemTypes = EPriceItemTypes = {}));
var EDenominations;
(function (EDenominations) {
    EDenominations["HOUR"] = "hr";
    EDenominations["NIGHT"] = "night";
    EDenominations["PASSENGER"] = "pax";
    EDenominations["PASSENGER_LEG"] = "pax_leg";
    EDenominations["STOP"] = "stop";
    EDenominations["NOT_APPLICABLE"] = "";
})(EDenominations || (exports.EDenominations = EDenominations = {}));
var EPriceLogicSubjects;
(function (EPriceLogicSubjects) {
    EPriceLogicSubjects["DEPARTURE_AIRPORT"] = "dep";
    EPriceLogicSubjects["ARRIVAL_AIRPORT"] = "arr";
    EPriceLogicSubjects["REPOSITION_AIRPORT"] = "repo";
    EPriceLogicSubjects["ANY_COUNTRY"] = "c";
    EPriceLogicSubjects["STOPS"] = "stops";
    EPriceLogicSubjects["AIRCRAFT"] = "tail";
    EPriceLogicSubjects["AIRCRAFT_MODEL"] = "model";
    EPriceLogicSubjects["NIGHTS"] = "nights";
    EPriceLogicSubjects["TRIP_TYPE"] = "type";
    EPriceLogicSubjects["REPOSITION_TIME"] = "rtime";
    EPriceLogicSubjects["FLIGHT_TIME"] = "ftime";
    EPriceLogicSubjects["WAIT_TIME"] = "wtime";
    // EMPTY_LEG = 'Empty Leg'
})(EPriceLogicSubjects || (exports.EPriceLogicSubjects = EPriceLogicSubjects = {}));
exports.PriceLogicSubjectMap = {
    'Departure Airport': EPriceLogicSubjects.DEPARTURE_AIRPORT,
    'Arrival Airport': EPriceLogicSubjects.ARRIVAL_AIRPORT,
    'Reposition Airport': EPriceLogicSubjects.REPOSITION_AIRPORT,
    'Country': EPriceLogicSubjects.ANY_COUNTRY,
    'Stops': EPriceLogicSubjects.STOPS,
    'Aircraft': EPriceLogicSubjects.AIRCRAFT,
    'Number of Nights': EPriceLogicSubjects.NIGHTS,
    'Trip Type': EPriceLogicSubjects.TRIP_TYPE,
    'Reposition Block Time': EPriceLogicSubjects.REPOSITION_TIME,
    'Flight Time': EPriceLogicSubjects.FLIGHT_TIME,
    'Wait Time': EPriceLogicSubjects.WAIT_TIME
};
exports.PriceLogicSubjectDisplay = {};
exports.PriceLogicSubjectDisplay[EPriceLogicSubjects.DEPARTURE_AIRPORT] = 'Departure Airport';
exports.PriceLogicSubjectDisplay[EPriceLogicSubjects.ARRIVAL_AIRPORT] = 'Arrival Airport';
exports.PriceLogicSubjectDisplay[EPriceLogicSubjects.REPOSITION_AIRPORT] = 'Reposition Airport';
exports.PriceLogicSubjectDisplay[EPriceLogicSubjects.ANY_COUNTRY] = 'Country';
exports.PriceLogicSubjectDisplay[EPriceLogicSubjects.STOPS] = 'Stops';
exports.PriceLogicSubjectDisplay[EPriceLogicSubjects.AIRCRAFT] = 'Aircraft (Tail Number)';
exports.PriceLogicSubjectDisplay[EPriceLogicSubjects.AIRCRAFT_MODEL] = 'Aircraft (Related Model)';
exports.PriceLogicSubjectDisplay[EPriceLogicSubjects.NIGHTS] = 'Number of Nights';
exports.PriceLogicSubjectDisplay[EPriceLogicSubjects.TRIP_TYPE] = 'Trip Type';
exports.PriceLogicSubjectDisplay[EPriceLogicSubjects.REPOSITION_TIME] = 'Reposition Block Time';
exports.PriceLogicSubjectDisplay[EPriceLogicSubjects.FLIGHT_TIME] = 'Flight Time';
exports.PriceLogicSubjectDisplay[EPriceLogicSubjects.WAIT_TIME] = 'Wait Time';
var EPriceLogicVerbs;
(function (EPriceLogicVerbs) {
    EPriceLogicVerbs["IS"] = "is";
    EPriceLogicVerbs["IS_IN"] = "is in";
    EPriceLogicVerbs["IS_NOT"] = "is not";
    EPriceLogicVerbs["IS_LESS_THAN"] = "is less than";
    EPriceLogicVerbs["IS_GREATER_THAN"] = "is greater than";
})(EPriceLogicVerbs || (exports.EPriceLogicVerbs = EPriceLogicVerbs = {}));
class PriceItem extends model_core_1.Base {
    constructor(data, olMap, type, idLen) {
        var _a;
        super(type || 'pricing', idLen || 9);
        this.label = '';
        this.public_label = '';
        this.description = '';
        this.emptyOnly = false;
        this.taxable = null;
        this.tax = null;
        this.type = EPriceItemTypes.NONE;
        this.quantity = null;
        this.minQty = null;
        this.dailyMin = null;
        this.multiplier = null;
        this.amount = null;
        this.cost = null;
        this.editedBy = '';
        this.order = null;
        this.repositionAirport = null;
        this.repositionDays = null; // number of days between legs, beyond which treat each airport as repo-worthy.
        this.denomination = EDenominations.NOT_APPLICABLE;
        this.notes = '';
        this.logic = [];
        this.agents = [];
        this.followers = [];
        this.roles = [];
        this._m = ['label', 'description', 'taxable', 'tax', 'type', 'quantity', 'minQty', 'dailyMin', 'amount', 'order', 'repositionAirport', 'repositionDays', 'denomination', 'logic', 'multiplier'];
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, PriceItem);
        (_a = this === null || this === void 0 ? void 0 : this.logic) === null || _a === void 0 ? void 0 : _a.forEach((item, i) => {
            if (this.logic[i]['operator'] && !this.logic[i].verb) {
                this.logic[i].verb = this.logic[i]['operator'];
            }
        });
    }
    toJSON() {
        var _a;
        let r = super.toJSON();
        ['repositionAirport'].forEach(prop => { var _a; return r[prop] = ((_a = this[prop]) === null || _a === void 0 ? void 0 : _a.toJSON) ? this[prop].toJSON() : this[prop]; });
        (_a = r.logic) === null || _a === void 0 ? void 0 : _a.forEach((item, i) => {
            delete r.logic[i]['operator'];
        });
        if (r.minQty) {
            r.minQty = isNaN(Number(r.minQty)) ? null : Number(r.minQty);
        }
        return r;
    }
}
exports.PriceItem = PriceItem;
index_1.olm.pricing = (ref, map) => {
    return new PriceItem(ref, map);
};
