"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DELAY_DETAIL_CODES = exports.DELAY_STATUS_CODES = exports.FlightPlan = void 0;
const date_fns_1 = require("date-fns");
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
class FlightPlan extends model_core_1.Base {
    constructor(data, olMap) {
        super('flight_plans', 30);
        this.t = '';
        // Mode-S hex code “780EBA”
        this.ms = '';
        // Aircraft registration “B-8136”
        this.acr = '';
        // Aircraft type (ICAO code) “A321”
        this.act = '';
        // Aircraft type description “Airbus A321-231”
        this.acd = '';
        // Call sign “CES5314”
        this.cs = '';
        // Flight number (IATA/commercial format) “MU5314”
        this.fnia = '';
        // Airline ICAO code “CES”
        this.alic = '';
        // Airline IATA code “MU”
        this.alia = '';
        // Airline name “China Eastern Airlines”
        this.alna = '';
        // Origin airport latitude 23.3924
        this.aporgla = null;
        // Origin airport longitude 113.299
        this.aporglo = null;
        // Origin airport ICAO code “ZGGG”
        this.aporgic = '';
        // Origin airport IATA “CAN”
        this.aporgia = '';
        // Origin airport name “Guangzhou Baiyun International Airport”
        this.aporgna = '';
        // Origin airport city “Guangzhou”
        this.aporgci = '';
        // Origin airport state or province “Guangdong Province”
        this.aporgst = '';
        // Origin airport country “China”
        this.aporgco = '';
        // Origin airport timezone difference from UTC, in hours 8.0
        this.aporgtz = null;
        // Origin airport timezone abbreviation “CST”
        this.aporgtzns = '';
        // Origin airport timezone name “China Standard Time”
        this.aporgtznl = '';
        // Destination airport latitude 23.3924
        this.apdstla = null;
        // Destination airport longitude 113.299
        this.apdstlo = null;
        // Destination airport ICAO code “ZGGG”
        this.apdstic = '';
        // Destination airport IATA code "CAN"
        this.apdstia = '';
        // Destination airport name “Guangzhou Baiyun International Airport”
        this.apdstna = '';
        // Destination airport city “Guangzhou”
        this.apdstci = '';
        // Destination airport state or province “Guangdong Province”
        this.apdstst = '';
        // Destination airport country “China”
        this.apdstco = '';
        // Destination airport timezone difference from UTC, in hours 8.0
        this.apdsttz = null;
        // Destination airport timezone abbreviation "CST"
        this.apdsttzns = '';
        // Destination airport timezone name “China Standard Time”
        this.apdsttznl = '';
        // Scheduled departure time, local to the origin airport, in the format “yyyy-MM-dd HH:mm:ss”
        this.deps = '';
        // Estimated departure time, local to the orgin airport, in the format “yyyy-MM-dd HH:mm:ss”
        this.depe = '';
        // Actual departure time, local to the origin airport, in the format “yyyy-MM-dd HH:mm:ss”
        this.depa = '';
        // Scheduled departure time, in UTC, in the format “yyyy-MM-dd HH:mm:ss”
        this.depsu = '';
        // Estimated departure time, in UTC, in the format “yyyy-MM-dd HH:mm:ss”
        this.depeu = '';
        // Actual departure time, in UTC, in the format “yyyy-MM-dd HH:mm:ss”
        this.depau = '';
        // Departure time as filed by the pilot in the flight plan, in UTC, in the format “yyyy-MM-dd HH:mm:ss”
        this.deppu = '';
        // Scheduled arrival time, local to the destination airport, in the format “yyyy-MM-dd HH:mm:ss”
        this.arrs = '';
        // Estimated arrival time, local to the destination airport, in the format “yyyy-MM-dd HH:mm:ss”
        this.arre = '';
        // Actual arrival time, local to the destination airport, in the format “yyyy-MM-dd HH:mm:ss”
        this.arra = '';
        // Scheduled arrival time, in UTC, in the format “yyyy-MM-dd HH:mm:ss”
        this.arrsu = '';
        // Estimated arrival time, in UTC, in the format “yyyy-MM-dd HH:mm:ss”
        this.arreu = '';
        // Actual arrival time, in UTC, in the format “yyyy-MM-dd HH:mm:ss”
        this.arrau = '';
        // Indicates that this flight has been canceled
        this.cancel = '';
        // Planned flight waypoints
        this.wp = '';
        // Planned flight route
        this.route = '';
        // Source of the flight plan data (“ASDI” for FAA, “ECTL” for Eurocontrol, “AUST” for Airservices Australia)
        this.so = '';
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, FlightPlan);
    }
    async save(db) {
        // Creating a predictable id so we don't have multiple flight plans for the same plane on the same day,
        // assumes one plan per airport per day.  If a plane leaves the same airport twice in one day, we assume
        // it's the home-base which is not likely an empty leg anyway?
        if (this.depsu && this.acr && this.aporgic) {
            let d = (0, date_fns_1.parse)(this.depsu, 'yyyy-MM-dd HH:mm:ss', new Date());
            this.date = d.valueOf();
            this.id = `${(0, date_fns_1.format)(d, 'yyyy-MM-dd')}-${this.acr}-${this.aporgic}`; // Aircraft ID + Origin Airport
            this._docRef = db.doc(`flight_plans/${this.id}`);
            return super.save();
        }
        else {
            console.warn(`Cannot save flight plan. Missing data: (depsu: ${this.depsu}; acr: ${this.acr}; aporgic: ${this.aporgic})`);
        }
    }
}
exports.FlightPlan = FlightPlan;
index_1.olm.flight_plans = (ref, map) => {
    return new FlightPlan(ref, map);
};
var DELAY_STATUS_CODES;
(function (DELAY_STATUS_CODES) {
    DELAY_STATUS_CODES["CX"] = "cancelled";
    DELAY_STATUS_CODES["DY"] = "delayed";
    DELAY_STATUS_CODES["EY"] = "early";
    DELAY_STATUS_CODES["OT"] = "on time";
    DELAY_STATUS_CODES["NS"] = "no status";
})(DELAY_STATUS_CODES || (exports.DELAY_STATUS_CODES = DELAY_STATUS_CODES = {}));
var DELAY_DETAIL_CODES;
(function (DELAY_DETAIL_CODES) {
    DELAY_DETAIL_CODES["ARVS"] = "No delays posted";
    DELAY_DETAIL_CODES["CNCL"] = "Cancelled flight";
    DELAY_DETAIL_CODES["CXCL"] = "Cancelled";
    DELAY_DETAIL_CODES["DCSN"] = "Check with airline";
    DELAY_DETAIL_CODES["DPTS"] = "No delays posted";
    DELAY_DETAIL_CODES["EOFF"] = "Estimated Wheels Up/Airborne";
    DELAY_DETAIL_CODES["EON"] = "Estimated Wheels Down/Touchdown";
    DELAY_DETAIL_CODES["ETA"] = "Estimated time of arrival";
    DELAY_DETAIL_CODES["ETD"] = "Estimated time of departure";
    DELAY_DETAIL_CODES["IN"] = "Arrived in the gate";
    DELAY_DETAIL_CODES["LX"] = "Cancelled leg";
    DELAY_DETAIL_CODES["NA"] = "Not Available";
    DELAY_DETAIL_CODES["NO"] = "No-op";
    DELAY_DETAIL_CODES["OFF"] = "Departed off the ground";
    DELAY_DETAIL_CODES["ON"] = "Arrived on the ground";
    DELAY_DETAIL_CODES["OUT"] = "Departed out of the gate";
    DELAY_DETAIL_CODES["RFA"] = "Return from Airborne";
    DELAY_DETAIL_CODES["RTR"] = "Return to Ramp";
    DELAY_DETAIL_CODES["SCHD"] = "Schedule Change";
})(DELAY_DETAIL_CODES || (exports.DELAY_DETAIL_CODES = DELAY_DETAIL_CODES = {}));
