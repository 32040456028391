import {Component, EventEmitter, Output, OnInit, Input, ViewChild} from '@angular/core';

import {getUsersByRoleFilterSets, IAlgoliaFilterSet} from '../../shared/search/algolia-search.component';
import {AlgoliaQueryFacetFilterItem, IAlgoliaSearchResults} from '@nxt/model-core';

import {LocalStorageService} from '../../shared/_services/local-storage.service';
import {AccountService} from '../_services/account.service';
import {AlgoliaSearchComponent} from '../../shared/search/algolia-search.component';
import {CommonModule} from '@angular/common';
import {ClientService} from '../../shared/_services/client.service';

@Component({
    standalone: true,
    imports: [CommonModule, AlgoliaSearchComponent],
    selector: 'nxt-contact-search',
    template: `
        <algolia-search-component
                #searchComponent
                index="contacts"
                placeholder="Search Contacts (sorted by last activity date)"
                [saveSearch]="saveSearch"
                [autoStart]="autoStart"
                [showChicklets]="showChicklets"
                [filterSet]="filterSets"
                (onResults)="onResults.emit($event)"
                (onClear)="onClear.emit($event)"
        ></algolia-search-component>
    `
})
export class NewNxtContactSearchComponent implements OnInit {
    @Output() onResults: EventEmitter<[IAlgoliaSearchResults, boolean]> = new EventEmitter<[IAlgoliaSearchResults, boolean]>();
    @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('searchComponent', {static: true}) searchComponent: AlgoliaSearchComponent;
    @Input() saveSearch: boolean = true;
    @Input() autoStart: boolean = true;
    @Input() showChicklets: boolean = true;
    @Input() showFilters: boolean = true;
    type: string = 'contacts';
    filterSets: IAlgoliaFilterSet[];

    constructor(
        public lSvc: LocalStorageService,
        private aSvc: AccountService,
        private cSvc: ClientService
    ) {}

    ngOnInit() {
        this.loadFilters();
    }

    async loadFilters() {
        if (this.showFilters) {
            this.filterSets = [];
            let [a,b] = await Promise.all([this.loadSettings(),getUsersByRoleFilterSets(this.aSvc, this.cSvc)]);
            this.filterSets = this.filterSets.concat(a).concat(b);
        }
    }

    async loadSettings():Promise<IAlgoliaFilterSet[]> {
        let item: any = {
            name: 'Basic Filters',
            items: []
        }

        // Add filter to show hidden contacts.
        item.items.push(new AlgoliaQueryFacetFilterItem({
            id: 'active',
            name: 'Hidden Contacts',
            value: `active:false`,
            key: 'active',
            key_unique: true,
            type: 'facet',
            color: '#dfdfdf',
            contrast: '#000000'
        }));

        // Add contact Type
        let settings = await this.cSvc.callAPI(`/config/contact-types`, 'post');
        if (settings?.types?.length) {
            item.items = item.items.concat(settings?.types?.map(item => {
                return new AlgoliaQueryFacetFilterItem({
                    id: item.name,
                    name: item.name,
                    value: `type:"${item.name}"`,
                    color: '#dfdfdf',
                    contrast: '#000000',
                    type: 'or'
                })
            }));
        }

        return [item];
    }

}
