import {Injectable} from '@angular/core';
import {ClientService} from '../../shared/_services/client.service';
import {IMenu, IMenuItem} from '@nxt/model-core';
// @ts-ignore
import {environment} from '@env/environment';

@Injectable()
export class NxtMenuService {
    constructor(public cSvc: ClientService) {}
    get dashboard(): IMenuItem {
        return {
            label: 'Dashboard',
            routerLink: `/${this.cSvc.name_key}/dashboard`,
            icon: {name: 'heroicon-outline-presentation-chart-bar'},
            roles: ['admin']
        };
    }
    // Each Menu Item standsalone and is brought together by the
    // getMenu method. This allows other MenuService classes (e.g. LaroOperatorMenuService) to re-use easily.
    get inbox(): IMenuItem  {
        return {
            label: 'Smart Inbox™',
            routerLink: `/${this.cSvc.name_key}/threads`,
            icon: {name: 'heroicon-outline-mail'},
            roles: ['user']
        }
    }
    get workOrders(): IMenuItem {
        return {
            label: 'Work Orders',
                id: 'work',
            hide: true,
            routerLink: `/${this.cSvc.name_key}/work`,
            icon: {name: 'heroicon-wrench'},
            roles: ['work-r']
        }
    };
    get quotes(): IMenuItem{
        return {
            label: 'Quotes',
            id: 'quotes',
            hide: true,
            routerLink: `/${this.cSvc.name_key}/quotes`,
            icon: {name: 'heroicon-solid-fire'},
            roles: ['quotes']
        }
    }
    get orders(): IMenuItem {
        return {
            label: 'Orders',
            id: 'orders',
            hide: true,
            routerLink: `/${this.cSvc.name_key}/orders`,
            icon: {name: 'heroicon-outline-currency-dollar'},
            roles: ['orders','ads']
        }
    };
    get marketing_contacts(): IMenuItem {
        return {
            label: 'Contacts',
                id: 'contacts',
            routerLink: `/${this.cSvc.name_key}/marketing/contacts`,
            icon: {name: 'heroicon-outline-credit-card'},
            roles: ['contacts']
        }
    };
    get marketing_groups(): IMenuItem {
        return {
            label: 'Groups',
            id: 'groups',
            routerLink: `/${this.cSvc.name_key}/marketing/groups`,
            icon: {name: 'heroicon-outline-user-group'},
            roles: ['groups-r']
        }
    };
    get marketing_documents(): IMenuItem {
        return {
            label: 'Documents',
            id: 'documents',
            routerLink: `/${this.cSvc.name_key}/marketing/documents`,
            icon: {name: 'heroicon-outline-mail'},
            roles: ['cms']
        }
    };
    get marketing(): IMenuItem {
        return {
            label: 'Marketing',
            id: 'marketing',
            icon: {name: 'heroicon-outline-document-report'},
            pathMatch: `/${this.cSvc.name_key}/marketing`,
            roles: ['groups-r','contacts-r'],
            items: [
                this.marketing_contacts,
                this.marketing_groups,
                this.marketing_documents
            ]
        }
    }
    get data_products(): IMenuItem {
        return {
            label: 'Products',
            id: 'products',
            routerLink: `/${this.cSvc.name_key}/data/products`,
            icon: {name: 'heroicon-outline-view-list'},
            roles: ['products-r','pricing-r']
        }
    };
    get data_pricing(): IMenuItem {
        return {
            label: 'Pricing',
            id: 'pricing',
            hide: true,
            routerLink: `/${this.cSvc.name_key}/data/pricing`,
            icon: {name: 'heroicon-outline-ticket'},
            roles: ['pricing-r']
        }
    };
    get data_ads(): IMenuItem {
        return {
            label: 'Product Ads',
            id: 'ads',
            routerLink: `/${this.cSvc.name_key}/data/ads`,
            icon: {name: 'heroicon-outline-at-symbol'},
            roles: ['ads-r']
        }
    };
    get data_discounts(): IMenuItem {
        return {
            label: 'Discounts',
            id:'discounts',
            routerLink: `/${this.cSvc.name_key}/data/discounts`,
            icon: {name: 'heroicon-outline-ticket'},
            roles: ['!isDefault', 'pricing-r']
        }
    };
    get data_zips(): IMenuItem {
        return {
            label: 'Zips',
            id:'zips',
            hide: true,
            routerLink: `/${this.cSvc.name_key}/data/zips`,
            icon: {name: 'heroicon-outline-location-marker'},
            roles: ['admin']
        }
    };
    get data_colleges(): IMenuItem {
        return {
            label: 'Colleges',
            id: 'colleges',
            hide: true,
            routerLink: `/${this.cSvc.name_key}/data/colleges`,
            icon: {name: 'heroicon-outline-star'},
            roles: ['cms']
        }
    };
    get data_conferences(): IMenuItem {
        return {
            label: 'Conferences',
            id: 'conferences',
            hide: true,
            routerLink: `/${this.cSvc.name_key}/data/conferences`,
            icon: {name: 'heroicon-outline-user-group'},
            roles: ['cms']
        }
    };
    get data_events(): IMenuItem {
        return {
            label: 'Events',
            id: 'events',
            hide: true,
            routerLink: `/${this.cSvc.name_key}/data/events`,
            icon: {name: 'heroicon-outline-calendar'},
            roles: ['cms']
        }
    };



    get data(): IMenuItem {
        return {
            label: 'Data',
            id: 'data',
            hide: true,
            icon: {name: 'heroicon-outline-database'},
            pathMatch: `/${this.cSvc.name_key}/data`,
            roles: ['pricing-r','discounting-r', 'ads-r', 'products-r'],
            items: [
                this.data_products,
                this.data_pricing,
                this.data_ads,
                this.data_discounts,
                this.data_zips,
                this.data_colleges,
                this.data_conferences,
                this.data_events
            ]
        }
    }
    get content_pages(): IMenuItem {
        return {
            label: 'Pages',
            routerLink: `/${this.cSvc.name_key}/cms/pages`,
            icon: {name: 'heroicon-rectangle-group'},
            roles: ['cms']
        }
    };
    get content_headers(): IMenuItem {
        return {
            label: 'Headers',
            routerLink: `/${this.cSvc.name_key}/cms/headers`,
            icon: {name: 'heroicon-bars-arrow-up'},
            roles: ['cms']
        }
    };

    get content_files(): IMenuItem {
        return {
            label: 'Files',
            routerLink: `/${this.cSvc.name_key}/cms/files`,
            icon: {name: 'heroicon-outline-cloud-upload'},
            roles: ['cms']
        }
    };
    get conetnt_footers(): IMenuItem {
        return {
            label: 'Footers',
                routerLink: `/${this.cSvc.name_key}/cms/footers`,
            icon: {name: 'heroicon-bars-arrow-down'},
            roles: ['cms']
        }
    };
    get content_deployments(): IMenuItem {
        return {
            label: 'Deployments',
            routerLink: `/${this.cSvc.name_key}/cms/deployments`,
            icon: {name: 'heroicon-outline-logout'},
            roles: ['cms']
        }
    };
    get content(): IMenuItem {
        return {
            label: 'Content',
            id: 'cms',
            hide: environment.type==='prod' ,
            icon: {name: 'heroicon-outline-pencil'},
            pathMatch: `/${this.cSvc.name_key}/cms`,
            roles: ['cms'],
            items: [
                this.content_pages,
                this.content_headers,
                this.conetnt_footers,
                this.content_files,
                this.content_deployments
            ]
        }
    };
    get company_details(): IMenuItem {
        return {
            label: 'Details',
            routerLink: `/${this.cSvc.name_key}/company/details`,
            icon: {name: 'heroicon-outline-office-building'},
            roles: ['settings']
        }
    };
    get company_addresses(): IMenuItem {
        return {
            label: 'Addresses',
            routerLink: `/${this.cSvc.name_key}/company/addresses`,
            pathMatch: `/${this.cSvc.name_key}/company/addresses`,
            icon: {name: 'heroicon-outline-collection'},
            roles: ['settings']
        }
    };
    get company_settings(): IMenuItem {
        return {
            label: 'Settings',
            routerLink: `/${this.cSvc.name_key}/company/settings`,
            pathMatch: `/${this.cSvc.name_key}/company/settings`,
            icon: {name: 'heroicon-outline-adjustments'},
            roles: ['settings-r']
        }
    };
    get company_users(): IMenuItem {
        return {
            label: 'Users',
            routerLink: `/${this.cSvc.name_key}/company/users`,
            pathMatch: `/${this.cSvc.name_key}/company/users`,
            icon: {name: 'heroicon-outline-users'},
            roles: ['users']
        }
    };
    get company(): IMenuItem {
        return {
            label: 'Company',
            id: 'company',
            icon: {name: 'heroicon-outline-information-circle'},
            pathMatch: `/${this.cSvc.name_key}/company`,
            roles: ['users', 'settings-r'],
            items: [
            this.company_details,
            this.company_addresses,
            this.company_settings,
            this.company_users
        ]
        }
    };
    get tasks(): IMenuItem {
        return {
            label: 'Support Tickets',
            id: 'tasks',
            icon: {name: 'heroicon-outline-clipboard-check'},
            routerLink: `/${this.cSvc.name_key}/tasks`,
            roles: ['tasks-r']
        }
    };

    async getMenu(): Promise<IMenu> {
        let result: IMenu = {
            items:[
                this.dashboard,
                this.inbox,
                this.workOrders,
                this.quotes,
                this.orders,
                this.marketing,
                this.data,
                this.content,
                this.company,
                this.tasks
            ]
        };


        let showHideItem = (first, second, hide) => {
            let item:any = result.items.find(i => i.id === first);
            if (item) {
                if (!second) {
                    item.hide = hide;
                } else {
                    item = item?.items?.find(i => i.id === second);
                    if (item) {
                        item.hide = hide;
                    } else {
                        console.warn(`Nothing found on second level at ${second}`);
                    }
                }
            } else {
                console.warn(`Nothing found on first level at ${first}`);
            }
        };


        switch (this.cSvc.name_key) {
            case 'CBDB':
                showHideItem('data','',false);
                break;
            case 'AXEK':
                showHideItem('data','',false);
                showHideItem('orders','',false);
                showHideItem('quotes','',false);
                showHideItem('work','',false);
                break;
            case 'EWIN':
                showHideItem('data','',false);
                showHideItem('data','products',true);
                showHideItem('data','pricing',false);
                showHideItem('data','zips',false);
                showHideItem('orders','',false);
                showHideItem('quotes','',false);
                break;
            case 'GMTL':
                showHideItem('data','',false);
                showHideItem('orders','',false);
                showHideItem('quotes','',false);
                break;
            case 'RCRT':
                showHideItem('data','',false);
                showHideItem('data','colleges',false);
                showHideItem('data','conferences',false);
                showHideItem('data','events',false);
                showHideItem('quotes','',true);
                showHideItem('orders','',false);
                break;
            case 'EQIP':
                showHideItem('data','',false);
                showHideItem('data','products',false);
                showHideItem('data','ads',true);
                showHideItem('data','discounts',true);
                showHideItem('quotes','',false);
                showHideItem('orders','',false);
                showHideItem('marketing','contacts',false);
                break;
            case environment.default_client.name_key:
                showHideItem('quotes','',true);
                showHideItem('orders','',false);
                showHideItem('data','',false);
                showHideItem('data','products',false);
                showHideItem('data','ads',true);
                break
        }

        return result;
    }
}
