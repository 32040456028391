import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Router} from '@angular/router';
import {FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

// @ts-ignore
import {environment} from '@env/environment';
import {InputStackedTextComponent} from '@library/shared/input/input-stacked-text.component';
import {FooterButtonsComponent} from '@library/nxt/footer/footer-buttons.component';
import {QuoteTripFormComponent} from '../../../_components/input/quote-trip-form.component';
import {Contact as LaroContact, ESchedulingSystems, ETripStatus, Quote, QuoteRequest, Trip} from '@nxt/model-laro';
import {formatCurrency, IMenuItem, Thread} from '@nxt/model-core'
import {TabBarComponent} from '@library/nxt/tabs/tab-bar.component';
import {PriceDetailsComponent} from '../../../_components/trip/price-details.component';
import {ClientService} from '@library/shared/_services/client.service';
import {PageService} from '@library/shared/_services/page.service';
import {FireService} from '@library/nxt/_services/fire.service';
import {PriceBreakdownDialog} from '../../../_components/trip/price-breakdown.dialog';
import {LaroInternalQuoteResults} from '../quotes/laro-internal-quote-results';

@Component({
    standalone: true,
    imports: [
        CommonModule, FormsModule, ReactiveFormsModule,
        TabBarComponent, PriceDetailsComponent, PriceBreakdownDialog,
        InputStackedTextComponent, FooterButtonsComponent, QuoteTripFormComponent,
        LaroInternalQuoteResults
    ],
    template: `
        <tab-bar [tabs]="tabs"></tab-bar>
        <div class="p-3">
            <ng-container *ngIf="path==='import'">
                <div [formGroup]="form" *ngIf="form">
                    <p class="italic text-sm p-3">Enter quote number from Avianis and click Link.</p>
                    <input-stacked-text label="Quote Number"
                                        [form]="form"
                                        controlName="quote_number"
                                        (onEnter)="submit()"
                    ></input-stacked-text>
                </div>
            </ng-container>
        </div>
        <div class="h-1/2" *ngIf="path==='levo'">
            <div class="px-4 pb-20">
                <quote-trip-form
                        #quoteTripFormComponent
                        [includeDate]="true" 
                        [includeTime]="true"
                        (onResults)="handleResults($event)" 
                        (onClear)="clear()"
                ></quote-trip-form>
                
                <laro-internal-quote-results 
                        #internalQuoteResults
                        [results]="results"
                        (onChange)="buildButtons()"
                ></laro-internal-quote-results>
            </div>
        </div>
        <footer-buttons-component [buttons]="buttons"></footer-buttons-component>

    `
})
export class ImportQuoteDialog implements OnChanges {
    @ViewChild('quoteTripFormComponent', { static: false }) quoteTripFormComponent: QuoteTripFormComponent;
    @ViewChild('internalQuoteResults', { static: false }) internalQuoteResults: LaroInternalQuoteResults;
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    @Input() contact: LaroContact;
    @Input() thread: Thread;
    existing_quote: Quote;
    existing_trip: Trip;
    path: string = 'import';
    maxWidth: string = '400px';
    form: UntypedFormGroup;
    env = environment;

    results: QuoteRequest[];
    formatCurrency = formatCurrency;
    quote_number: string;
    buttons: IMenuItem[] = [];
    tabs: IMenuItem[];

    constructor(
        private cSvc: ClientService,
        private pSvc: PageService,
        private fb: UntypedFormBuilder,
        private fSvc: FireService,
        private router: Router
    ) {
    }

    ngOnChanges(changes?:SimpleChanges) {
        this.form = this.fb.group({
            quote_number: [this.existing_quote?.quote_number || this.existing_trip?.quote_number || this.quote_number || '']
        });
        this.buildTabs();
        this.buildButtons()
    }

    buildTabs() {
        this.tabs = [];
        this.tabs.push({
            label: 'Import/Link',
            active: (this.path === 'import'),
            click: () => {
                this.path = 'import';
                this.buildTabs();
                this.buildButtons();
            }
        });

        this.tabs.push({
            label: 'Levo Quote',
            active: (this.path === 'levo'),
            click: () => {
                this.path = 'levo';
                this.buildTabs();
                this.buildButtons();
            }
        });
    }

    buildButtons() {

        this.buttons = [
            {
                label: 'Close',
                
                click: () => {
                    this.onClose.emit();
                }
            }
        ];


        this.buttons.push({
                label: (this.path === 'levo') ? 'Get Quotes' : 'Link',
                class: 'btn-dark',
                show: () => {
                    return this.path === 'import';
                },
                click: () => {
                    this.submit();
                }
            });

        this.buttons.push({
                label: 'View Quote',
                class: 'btn-light',
                show: () => {
                    return !!this.existing_quote?.id && !!this.existing_trip?.id && !window.location.pathname.match(this.existing_quote?.id);
                },
                click: () => {
                    this.onClose.emit();
                    this.router.navigate([`/${this.cSvc.name_key}/quotes/${this.existing_quote.id}`]);
                }
            });

        if (this.path === 'levo' && this.internalQuoteResults?.activeQuote) {
            this.buttons.push({
                label: 'Save Selected Quote',
                class: 'btn-dark',
                click: async () => {
                    try {
                        this.internalQuoteResults.activeQuote.departure_date = this.internalQuoteResults.activeQuote.legs[0].times.takeoffUTC;
                    } catch (e) {}

                    if (!this.internalQuoteResults?.activeQuote?.departure_date) {
                        this.pSvc.notification$.next({
                            title: 'Please Add Date',
                            message: 'Date is required to save. Input date and regenerate quotes.'
                        });
                    } else {
                        this.internalQuoteResults.activeQuote.status = ETripStatus.quoteSubmitted;
                        await this.internalQuoteResults.activeQuote.save(this.cSvc.client$.getValue());
                        this.router.navigateByUrl(`/${this.cSvc.name_key}/quotes/${this.internalQuoteResults.activeQuote.id}`);
                        this.onClose.emit();
                    }

                }
            });
        }
    }

    clear() {
        this.results = null;
    }

    handleResults(results: [QuoteRequest[], boolean]) {
        this.pSvc.blocking$.next(false);
        this.results = results?.length ? results[0] : null;
    }

    async submit() {
        if (this.form.get('quote_number').value) {

            this.pSvc.blocking$.next(true);
            try {

                let result: any = await this.cSvc.callAPI('/laro/quote/import', 'post', {
                    parent: this.contact?.toMinJSON ? this.contact?.toMinJSON() : this.contact || null,
                    thread: this.thread?.toJSON() || null,
                    quote_number: this.form.get('quote_number').value,
                    trip_id: this.existing_quote?.trip_id || this.existing_trip?.id || '',
                    quote_id: this.existing_quote?.id || this.existing_trip?.quote_id || ''
                });

                this.onClose.emit(result);
                await this.router.navigate([`/${this.cSvc.name_key}/quotes/${result.id}`]);

            } catch (e) {
                if (e.error?.id) {
                    this.onClose.emit(( await this.fSvc.getObject(`clients/${this.cSvc.client_id}/quotes/${e.error.id}`)) as Quote);
                    await this.router.navigate([`/${this.cSvc.name_key}/quotes/${e.error.id}`]);
                } else {
                    this.pSvc.alert$.next(e);
                }
            }
            this.pSvc.blocking$.next(false);
        } else {
            this.form.markAllAsTouched();
            this.form.get('quote_number').setErrors({required: true});
        }
    }
}
