"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Deal = exports.EDealTypes = void 0;
const model_core_1 = require("@nxt/model-core");
const Trip_1 = require("./Trip");
const index_1 = require("../index");
var EDealTypes;
(function (EDealTypes) {
    EDealTypes["Manual"] = "m";
    EDealTypes["Imported"] = "i";
    EDealTypes["Scraped"] = "s";
    EDealTypes["Shuttle"] = "sh";
    EDealTypes["None"] = "";
})(EDealTypes || (exports.EDealTypes = EDealTypes = {}));
class Deal extends Trip_1.Trip {
    constructor(data, olMap) {
        super(data, olMap, 'deals', 12);
        this.client = null;
        this.imported = null;
        this.hidden = false;
        this.show_price = true;
        this.verified = false;
        this.departureAirport = null;
        this.arrivalAirport = null;
        this.times = { takeoffDate: '', takeoffTime: '', landingDate: '', landingTime: '', duration: {} };
        this.score = null;
        this.fp = null;
        this.type = EDealTypes.None;
        this.subscribers = null;
        this.booked = null;
        this._m = ['name', 'trip_number', 'hidden', 'departureAirport', 'arrivalAirport', 'times'];
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, Deal);
    }
    toString() {
        var _a, _b, _c;
        let parts = [];
        if (this.trip_number) {
            parts.push(`#${this.trip_number}`);
        }
        if ((_a = this.times) === null || _a === void 0 ? void 0 : _a.takeoffDate) {
            parts.push(this.times.takeoffDate);
        }
        if (((_b = this.departureAirport) === null || _b === void 0 ? void 0 : _b.icao) && ((_c = this.arrivalAirport) === null || _c === void 0 ? void 0 : _c.icao)) {
            parts.push(`${this.departureAirport.icao}-${this.arrivalAirport.icao}`);
        }
        return `Empty Leg: ${parts.join(' | ')} `;
    }
    toJSON() {
        var _a;
        let r = super.toJSON();
        r.legs = ((_a = this.legs) === null || _a === void 0 ? void 0 : _a.map(l => (l === null || l === void 0 ? void 0 : l.toJSON) ? l.toJSON() : l)) || [];
        return r;
    }
    async toSearchJSON(opts) {
        var _a, _b, _c;
        await this.loadAll(opts);
        // Customize here to avoid the way Trip builds search json.
        // store the geo-location of the departure airport for
        // easy radius searches for empty leg departure locations.
        let r = {
            id: this.id,
            _type: this._type,
            objectID: (_a = this._docRef) === null || _a === void 0 ? void 0 : _a.path
        };
        if (this._docRef) {
            let clientRef = this._docRef;
            r._client = this.id;
            while (((_b = clientRef.parent) === null || _b === void 0 ? void 0 : _b.id) && clientRef.parent.id !== 'clients') {
                clientRef = clientRef.parent;
                r._client = clientRef.id;
            }
            this.client = new model_core_1.Client(await clientRef.get());
        }
        [
            'departureAirport', 'aircraft', 'arrivalAirport',
            'departure_date', 'times', 'score', 'price', 'client',
            'estimate', 'verified', 'hidden', 'imported'
        ].forEach(p => r[p] = (0, model_core_1.toMinJSON)(this, p, true));
        if ((_c = this.fp) === null || _c === void 0 ? void 0 : _c.id) {
            r.verified = true;
        }
        if (this.departureAirport.latitude && this.departureAirport.longitude) {
            r._geoloc = {
                lat: Number(this.departureAirport.latitude),
                lng: Number(this.departureAirport.longitude)
            };
        }
        else {
            console.warn(`Departure airport (${this.departureAirport.icao}) missing lat/lon`);
        }
        return r;
    }
}
exports.Deal = Deal;
index_1.olm.deals = (ref, map) => {
    return new Deal(ref, map);
};
