import {CommonModule} from '@angular/common';
import {Component, EventEmitter, OnChanges, Output, SimpleChanges} from '@angular/core';

import {environment} from '@env/environment';
import {FooterButtonsComponent} from '@library/nxt/footer/footer-buttons.component';
import {Trip, Contact as LaroContact} from '@nxt/model-laro';
import {EMedium, IMenuItem, ITemplateData, User} from '@nxt/model-core';

import {FireService} from '@library/nxt/_services/fire.service';
import {ClientService} from '@library/shared/_services/client.service';
import {PageService} from '@library/shared/_services/page.service';
import {IconsComponent} from '@library/shared/icons/icons.component';


@Component({
    standalone: true,
    imports: [CommonModule, FooterButtonsComponent, IconsComponent],
    template: `

        <div class="h-screen-header-tab-search pb-20 overflow-y-auto p-6">

            <div class="flex">
                <div>
                    <div class="font-2xl font-bold mb-4">
                        Send To:
                    </div>

                    <span class="isolate inline-flex rounded-md shadow-sm">
                      <button type="button"
                              (click)="setPath('pax')"
                              [class]="path==='pax' ? 'relative inline-flex items-center rounded-l-md border border-gray-300 bg-dark px-4 py-2 text-sm font-medium text-light hover:bg-dark-50' : 'relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50'"
                      >
                          Passengers
                      </button>
                      <button type="button"
                              (click)="setPath('crew')"
                              [class]="path==='crew' ? 'relative inline-flex items-center rounded-r-md border border-gray-300 bg-dark px-4 py-2 text-sm font-medium text-light hover:bg-dark-50 -ml-1' : 'relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 -ml-1'"
                      >
                          Crew
                      </button>
                    </span>

                </div>

                <div class="ml-4" *ngIf="path==='crew'">
                    <div class="font-2xl font-bold mb-4">
                        Send Crew:
                    </div>

                    <span class="isolate inline-flex rounded-md shadow-sm">
                      <button type="button"
                              (click)="medium=EMedium.EMAIL"
                              [class]="medium===EMedium.EMAIL ? 'relative inline-flex items-center rounded-l-md border border-gray-300 bg-dark px-4 py-2 text-sm font-medium text-light hover:bg-dark-50' : 'relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50'"
                      >
                          E-Mail
                      </button>
                      <button type="button"
                              (click)="medium=EMedium.TEXT"
                              [class]="medium===EMedium.TEXT ? 'relative inline-flex items-center rounded-r-md border border-gray-300 bg-dark px-4 py-2 text-sm font-medium text-light hover:bg-dark-50 -ml-1' : 'relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 -ml-1'"
                      >
                          Text
                      </button>
                    </span>
                </div>

            </div>

            <div class="item-list mt-4" *ngIf="path==='crew' && medium===EMedium.TEXT">
                <p class="m-1">Legs cannot be specified in text. The text is just a link to the full trip details
                    page.</p>
            </div>
            <div class="item-list mt-4" *ngIf="path && medium===EMedium.EMAIL">
                <p class="m-1">Select legs to show, and recipients if desired:</p>
                <ul role="list">
                    <ng-container *ngFor="let leg of legs; let i = index">
                        <li>
                            <a (click)="toggleLeg(i)" class="hover:bg-gray-50 flex flex-col">
                                <div class="flex">
                                    <icon name="heroicon-outline-badge-check"
                                            [class]="leg.hide ?  'h-6 w-6 text-gray-200 mr-4' : 'h-6 w-6 text-green-500 mr-4'">
                                    </icon>
                                    <div class="text-dark">{{ leg.label }}</div>
                                </div>
                            </a>
                        </li>

                        <li *ngIf="!leg.recipients?.length">
                            <a class="ml-4">
                                <div class="text-gray-500 text-xs">
                                    No {{path}} found
                                </div>
                            </a>
                        </li>

                        <ng-container *ngFor="let user of leg.recipients">
                            <div *ngIf="(medium===EMedium.EMAIL && user?.email) || (medium===EMedium.TEXT && user?.phone)">
                                <div class="ml-10">
                                    <div class="flex">
                                        <div class="text-gray-500 text-sm">{{ user.name }} ({{ (medium===EMedium.EMAIL) ? user.email : user.phone }})</div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        
                        
                    </ng-container>
                    
                </ul>
                
                <p class="mt-6">Select recipient(s):</p>
                <ul role="list">
                    
                    <li *ngIf="!recipients?.length">
                        <a class="ml-4 hover:bg-gray-50">
                            <div class="flex">
                                <div class="text-gray-500 text-sm">
                                    No recipients found
                                </div>
                            </div>
                        </a>
                    </li>
                    
                    <ng-container *ngFor="let user of recipients">
                        <li *ngIf="medium===EMedium.EMAIL && user.email || medium===EMedium.TEXT && user.phone">
                            <a (click)="toggleRecipient(user)" class="ml-4 hover:bg-gray-50">
                                <div class="flex">
                                    <icon name="heroicon-outline-badge-check" *ngIf="recipientsMap[user.id]"
                                            class="h-6 w-6 text-green-500 mr-4">
                                    </icon>
                                    <icon name="heroicon-outline-minus-circle" *ngIf="!recipientsMap[user.id]"
                                           class="h-6 w-6 text-gray-200 mr-4">
                                    </icon>
                                    <div class="text-gray-500 text-sm">{{ user.name }} ({{ (medium===EMedium.EMAIL) ? user.email : user.phone }})</div>
                                </div>
                            </a>
                        </li>
                    </ng-container>
                </ul>
                
            </div>

            <div class="m-10 rounded-md bg-red-50 p-4" *ngIf="warn">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <icon name="heroicon-outline-x-circle" class="h-5 w-5 text-red-400"></icon>
                    </div>
                    <div class="ml-3">
                        <h3 class="text-sm font-medium text-red-800">
                            No Legs Selected!
                        </h3>
                        <div class="mt-2 text-sm text-red-700">
                            <ul role="list" class="list-disc space-y-1 pl-5">
                                <li>Are you sure you want to proceed? No legs are selected for display.</li>
                                <li>Click Next below to proceed.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        
        <footer-buttons-component [buttons]="buttons"></footer-buttons-component>

    `
})
export class ItineraryEmailDialog implements OnChanges {
    @Output() onClose: EventEmitter<[string, string, ITemplateData[], LaroContact[]]> = new EventEmitter<[string, string, ITemplateData[], LaroContact[]]>();
    trip: Trip;
    template_id: string;
    path: 'pax' | 'crew' | '' = 'pax';
    legs: ITemplateData[];
    medium: EMedium.EMAIL | EMedium.TEXT = EMedium.EMAIL;
    EMedium = EMedium;
    warn: boolean;
    options: any = {
        crew: [], pax: []
    }
    recipientsMap: any = {};
    recipients: LaroContact[] = [];
    buttons: IMenuItem[] = [
        {
            label: 'Next',
            class: 'btn-dark',
            click: async () => {
                this.send();
            }
        }
    ];

    constructor(
        private fSvc: FireService,
        private cSvc: ClientService,
        private pSvc: PageService
    ) {}

    async ngOnChanges(changes?:SimpleChanges) {

        if (this.template_id) {
            switch (this.template_id) {
                case 'itinerary_crew.html.js':
                    this.setPath('crew');
                    break;
                case 'itinerary_pax.html.js':
                case 'trip_confirmation.html.js':
                    this.setPath('pax');
                    break;
                default:
                    console.warn(`Unknown template_id: ${this.template_id}`);
                    break;
            }
        }
    }

    async loadData() {
        if (this.trip) {
            this.legs = [];
            this.recipientsMap = {};
            this.recipients = [];

            if (this.trip.contact && this.path === 'pax') {
                this.toggleRecipient(this.trip.contact, true);
            }

            if (this.trip?.legs?.length && this.path) {
                for (let leg of this.trip.legs) {
                    let item: any = {value: leg.id};
                    let tail: string = leg.aircraft?.tail_number ? `${leg.aircraft?.tail_number} - ` : '';
                    let time: string = '';
                    if (leg.times?.takeoffDate) {
                        time += ' '+leg.times.takeoffDate;
                    }
                    if (leg.times?.takeoffTime) {
                        time += ' '+leg.times.takeoffTime;
                    }
                    if (leg.times?.takeoffTZ) {
                        time += ' '+leg.times.takeoffTZ;
                    }
                    item.label = `${leg.trip_number ? 'Trip #'+leg.trip_number+': ' : ''}${time}: ${tail}${leg.departureAirport.name} (${leg.departureAirport.iata}) > ${leg.arrivalAirport.name} (${leg.arrivalAirport.iata})`;
                    if (leg.isEmptyLeg) {
                        item.label += ' (empty)';
                    }
                    if (!leg.active) {
                        item.label += ' (hidden)';
                    }

                    if (this.path === 'crew' || (!leg.isEmptyLeg && leg.active)) {
                        item.hide = false;
                        if (this.path === 'crew') {
                            item.recipients = leg.crew;
                            leg.crew?.forEach((u:User) => {
                                this.toggleRecipient(u, true);
                            });
                        } else {
                            item.recipients = leg.passengers;
                            leg.passengers?.forEach(c => {
                                this.toggleRecipient(c);
                                this.toggleRecipient(c);
                            })
                        }
                    } else {
                        item.hide = true;
                    }

                    this.legs.push(item);
                }
                this.warn = !this.legs?.find(i => i.hide === false);
            }

        }
    }

    setPath(path: 'pax' | 'crew') {
        this.path = path;
        this.loadData();
    }

    toggleLeg(i: number) {
        this.legs[i].hide = !this.legs[i].hide;
        this.warn = !this.legs?.find(i => i.hide === false);
    }

    async toggleRecipient(c: User|LaroContact, addOnly?: boolean) {
        if (c instanceof User) {
            if (!c.contact_id) {
                c = new User(await this.cSvc.callAPI('/cms/add/contact', 'post', c.toJSON()));
            }
            if (c.contact_id) {
                let contact = ( await this.fSvc.getObject(`clients/${this.cSvc.client_id}/contacts/${c.contact_id}`)) as LaroContact;
                if (!contact._exists) {
                    console.log(` ${c.name} - HAS NO CONTACT RECORD`);
                    c = null;
                } else {
                    c = contact;
                }
            } else {
                console.log(` ${c.name} - HAS NO CONTACT RECORD`);
            }
        }

        if ((this.medium===EMedium.EMAIL && c?.email) || (this.medium===EMedium.TEXT && c?.phone)) {
            let i: number = this.recipients.findIndex(i => i.id === c.id);
            if (this.recipientsMap[c.id] && !addOnly) {
                this.recipientsMap[c.id] = false
            } else {
                this.recipientsMap[c.id] = c;
                if (i===-1) {
                    this.recipients.push(c as LaroContact);
                }
            }
        }
        // console.log(this.recipientsMap);
    }

    async send() {
        this.pSvc.blocking$.next(true);
        this.recipients = [];
        this.recipients = Object.keys(this.recipientsMap).map(id => this.recipientsMap[id]);
        this.pSvc.blocking$.next(false);
        return this.onClose.emit([`itinerary_${this.path}.html.js`, this.medium, this.medium === EMedium.EMAIL ? this.legs : null, this.recipients]);
    }
}
