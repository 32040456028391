import {
    Component,
    EventEmitter,
    Output,
    OnInit,
    ViewChild,
    Input,
    WritableSignal,
    signal,
    AfterViewInit, OnChanges, SimpleChanges
} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AlgoliaQueryBuilder, AlgoliaQueryFacetFilterItem, IAlgoliaSearchResults} from '@nxt/model-core';
import {ETripStatus, TRIP_STATUS_MAP} from '@nxt/model-laro';

import {
    getUsersByRoleFilterSets,
    IAlgoliaFilterSet,
    AlgoliaSearchComponent
} from '@library/shared/search/algolia-search.component';
import {LocalStorageService} from '@library/shared/_services/local-storage.service';
import {AccountService} from '@library/nxt/_services/account.service';
import {addDays, format, startOfDay} from 'date-fns';
import {ClientService} from '@library/shared/_services/client.service';
import {take} from 'rxjs/operators';
import {PageService} from '@library/shared/_services/page.service';
import {DateRangeDialog} from '@library/shared/input/date-range.dialog';
import {UserService} from '@library/nxt/_services/user.service';

@Component({
    standalone: true,
    imports: [CommonModule, AlgoliaSearchComponent],
    selector: 'laro-quote-search',
    template: `
        <algolia-search-component
                #searchComponent
                index="quotes"
                subtype="subtype"
                placeholder="Search Quotes"
                [saveSearch]="saveSearch"
                [showChicklets]="showChicklets"
                [savedSearchUser]="uSvc.user$|async"
                [autoStart]="false"
                [preSearchLogic]="preSearchLogic()"
                [filterSet]="filterSets"
                (onResults)="handleResults($event)"
                (onClear)="clear($event)"
        ></algolia-search-component>
    `
})
export class LaroQuoteSearchComponent implements AfterViewInit, OnChanges  {
    @Output() onResults: EventEmitter<[IAlgoliaSearchResults, boolean, boolean]> = new EventEmitter<[IAlgoliaSearchResults, boolean, boolean]>();
    @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDateChange: EventEmitter<Date> = new EventEmitter();
    @ViewChild('searchComponent', {read: AlgoliaSearchComponent}) searchComponent: AlgoliaSearchComponent;
    @Input() saveSearch: boolean = true;
    @Input() showChicklets: boolean = true;
    @Input() showFilters: boolean = true;
    @Input() backwardLooking: boolean = false;
    @Input() pageSize: number = 400;
    @Input() daysToShow: number;
    @Input() start: Date;
    @Input() subtype: string;
    lastItemId: string = '';
    type: string = 'quotes';
    filterSets: IAlgoliaFilterSet[];
    numericSearch: boolean;
    matches: boolean;

    selectedDate: WritableSignal<Date> = signal(startOfDay(new Date()));

    constructor(
        public uSvc: UserService,
        public lSvc: LocalStorageService,
        private aSvc: AccountService,
        private cSvc: ClientService,
        private pSvc: PageService
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.daysToShow && this.daysToShow && this.searchComponent?.search) {
            this.searchComponent.search();
        }
    }

    ngAfterViewInit() {
        this.loadFilters();
        if (this.daysToShow && this.searchComponent?.search) {
            this.searchComponent.search();
        }
    }

    clear(e) {
        this.numericSearch = false;
        this.onClear.emit(e);
        this.lastItemId = '';
    }

    async loadFilters() {
        if (this.showFilters) {
            let now: Date = new Date();
            let start: Date = new Date();
            let end: Date = new Date();

            let filterSets:IAlgoliaFilterSet[] = [
                {
                    name: 'Date Filters',
                    buttons: [
                        {
                            label: 'Custom Date Range',
                            class: 'btn-dark',
                            click: () => {
                                this.pSvc.modal$.next({
                                    label: 'Custom Date Range',
                                    component: DateRangeDialog,
                                    styles: {
                                        width: '600px'
                                    },
                                    onLoaded: (comp: DateRangeDialog) => {
                                        comp.start = start;
                                        comp.end = end;
                                        comp.onClose
                                            .pipe(take(1)).subscribe(
                                            (result: {start:Date, end:Date}) => {

                                                if (result) {
                                                    start = result.start;
                                                    end = result.end;
                                                    this.searchComponent.builder.remove('filterItems', {id:'CUSTOM'});
                                                    this.searchComponent.toggleFilter({
                                                        id: 'CUSTOM',
                                                        name: `${format(start,'MMM d, yyyy')} > ${format(end,'MMM d, yyyy')}`,
                                                        color: '#505cac',
                                                        contrast: '#ffffff',
                                                        key: 'Date',
                                                        type: 'numeric',
                                                        key_unique: true,
                                                        more: `departure_date>${start.valueOf()}`,
                                                        value: `departure_date<${end.valueOf()}`
                                                    });
                                                }
                                            }
                                        );
                                        comp.ngOnChanges();
                                    }
                                });
                            }
                        }
                    ],
                    items: [
                        {
                            id: '3Days',
                            name: 'Next 3 Days',
                            color: '#505cac',
                            contrast: '#ffffff',
                            key: 'Date',
                            type: 'numeric',
                            key_unique: true,
                            more: `departure_date>${startOfDay(now).valueOf()}`,
                            value: `departure_date<${addDays(now,3).valueOf()}`
                        },
                        {
                            id: '7DAYS',
                            name: 'Next 7 Days',
                            color: '#505cac',
                            contrast: '#ffffff',
                            key: 'Date',
                            type: 'numeric',
                            key_unique: true,
                            more: `departure_date>${startOfDay(now).valueOf()}`,
                            value: `departure_date<${addDays(now,6).valueOf()}`
                        },
                        {
                            id: '14DAYS',
                            name: 'Next 14 Days',
                            color: '#505cac',
                            contrast: '#ffffff',
                            key: 'Date',
                            type: 'numeric',
                            key_unique: true,
                            more: `departure_date>${startOfDay(now).valueOf()}`,
                            value: `departure_date<${addDays(now,14).valueOf()}`
                        },
                        {
                            id: '30DAYS',
                            name: 'Next 30 Days',
                            color: '#505cac',
                            contrast: '#ffffff',
                            key: 'Date',
                            type: 'numeric',
                            key_unique: true,
                            more: `departure_date>${startOfDay(now).valueOf()}`,
                            value: `departure_date<${addDays(now,30).valueOf()}`
                        },
                        {
                            id: '60DAYS',
                            name: 'Next 60 Days',
                            color: '#505cac',
                            contrast: '#ffffff',
                            key: 'Date',
                            type: 'numeric',
                            key_unique: true,
                            more: `departure_date>${startOfDay(now).valueOf()}`,
                            value: `departure_date<${addDays(now,60).valueOf()}`
                        },
                        {
                            id: '90DAYS',
                            name: 'Next 90 Days',
                            color: '#505cac',
                            contrast: '#ffffff',
                            key: 'Date',
                            type: 'numeric',
                            key_unique: true,
                            more: `departure_date>${startOfDay(now).valueOf()}`,
                            value: `departure_date<${addDays(now,90).valueOf()}`
                        }
                    ]
                },
                {
                    name: 'Score Filters',
                    items: [20,40,60,80].map(score => {
                        return {
                            id: `SCORE-${score}`,
                            name: `Score >= ${score}`,
                            color: '#cc9721',
                            contrast: '#ffffff',
                            key: `SCORE`,
                            key_unique: true,
                            type: 'numeric',
                            value: `score.total>=${score}`
                        };
                    }, [])
                },
                {
                    name: 'Status Filters',
                    items: Object.keys(TRIP_STATUS_MAP).reduce((statuses,status) => {
                        if (!status.match(/^t/)) {
                            statuses.push({
                                id: status,
                                name: TRIP_STATUS_MAP[status].label,
                                color: TRIP_STATUS_MAP[status].color,
                                contrast: TRIP_STATUS_MAP[status].contrast,
                                key: 'status',
                                key_unique: true,
                                type: 'facet',
                                value: [`status:${status}`]
                            });
                        }
                        return statuses;
                    }, [])
                }
            ];
            // filterSets = filterSets.concat( await getManualFilterSet(this.cSvc, this.fSvc, this.pSvc, this.router, 'quotes') );
            this.filterSets = filterSets.concat(await getUsersByRoleFilterSets(this.aSvc, this.cSvc));
        }
    }

    async handleResults([results, append]) {
        if (!this.start) {
            this.start = this.selectedDate();
        }
        if (!append) {
            this.matches = false;
            this.numericSearch = !!this.searchComponent.builder.query.trim().match(/\d+((,\d+)|)/);
            if (this.numericSearch) {
                let term: string = this.searchComponent.builder.query;
                for (let i = (results.hits.length-1); i >=0; i--) {
                    let item = results.hits[i];
                    let parts: string[] = term.split(',');
                    let match: boolean = false;
                    for (let part of parts) {
                        part = part.trim();
                        if (item.quote_number.match(part) || item.quote_number === part) {
                            match = true;
                            this.matches = true;
                        }
                    }
                    if (!match) {
                        results.hits.splice(i,1);
                    }
                }
            }
        }

        if (!results.hits?.length) {
            return this.onResults.emit([results, append, false]);
        } else {
            if (this.daysToShow) {
                const startTimestamp = this.start.valueOf();
                const endTimestamp = addDays(this.start, this.daysToShow).valueOf();

                const firstItemDate = new Date(results.hits[0].departure_date);
                const lastItem = results.hits[results.hits.length - 1];
                const lastDate = new Date(lastItem.departure_date);

                const hasCurrentResults = results.hits.find(({departure_date}) => {
                    const depTimestamp = new Date(departure_date).valueOf();
                    return depTimestamp >= startTimestamp && depTimestamp <= endTimestamp;
                });

                if (!hasCurrentResults) {
                    if (firstItemDate.valueOf() > endTimestamp) {
                        this.selectedDate.set(firstItemDate);
                        this.onDateChange.emit(firstItemDate);
                    } else {
                        this.selectedDate.set(lastDate);
                    }
                    if (this.lastItemId !== lastItem.id) {
                        this.lastItemId = lastItem.id;
                        this.searchComponent.search();
                        return;
                    }
                }

            }

            this.onResults.emit([results, append, false]);
            //This will turn the page if there are more results to get
            if (this.searchComponent.showMore$.value) {
                this.searchComponent.nextPage();
                return;
            }
        }

    }

    preSearchLogic() {

        return (builder: AlgoliaQueryBuilder) => {
            if (builder) {
                builder.typoTolerance = 'strict';

                const startOfVisibleWeek = this.selectedDate().valueOf();
                this.numericSearch = !!builder.query.trim().match(/\d+((,\d+)|)/);
                let dateFilter:AlgoliaQueryFacetFilterItem = new AlgoliaQueryFacetFilterItem({
                    id: 'DATE',
                    type: 'numeric',
                    key: 'DATE',
                    key_unique: true,
                    value: `departure_date>=${startOfVisibleWeek}`
                });
                if (!builder.query) {
                    builder.addFilter(dateFilter);
                } else {
                    builder.removeFilter(dateFilter);
                }
                builder.addFilter(new AlgoliaQueryFacetFilterItem({
                    id: 'TYPE',
                    type: 'facet',
                    key: 'TYPE',
                    key_unique: true,
                    value: `status:-${ETripStatus.quoteBrokerRequest}`
                }));

            }
            return builder;
        };
    }
}
