import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {OperatorAppModule} from './operator/operator.app.module';
// @ts-ignore
import {environment} from '@env/environment';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(OperatorAppModule)
    .catch(err => console.error(err));
