import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Thread, ThreadMessage} from '@nxt/model-core';
import {CommonModule, NgIf} from '@angular/common';

@Component({
    selector: 'from',
    standalone: true,
    imports: [
        CommonModule
    ],
    template: `
        <div class="flex w-full text-sm font-bold text-dark-600 whitespace-nowrap overflow-hidden place-items-baseline">
            <span>{{ name }}</span>
            <span class="text-xs italic text-red-500 ml-2" *ngIf="parent['draft']">[ draft ]</span>
            <span class="ml-2 text-xs italic text-gray-600 font-light">{{ parent.date|date:'EEE M/d h:mm a' }}</span>
        </div>
    `
})
export class FromComponent implements OnChanges {
    @Input() parent: ThreadMessage|Thread;
    name: string;

    ngOnChanges(changes: SimpleChanges) {
        if (this.parent) {
            if (this.parent instanceof ThreadMessage) {
                if (this.parent.inbound) {
                    this.name = this.parent.creator?.name || this.parent.creator?.email;
                } else {
                    this.name = this.parent.creator?.nick_name || this.parent.creator?.name || this.parent.creator?.email;
                }
            } else {
                this.name = this.parent.creator?.nick_name || this.parent.creator?.name || this.parent.creator?.email;
            }
        }
    }
}
