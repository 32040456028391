"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadAll = exports.schedulingSystems = exports.ESchedulingSystems = exports.olm = void 0;
const model_core_1 = require("@nxt/model-core");
exports.olm = Object.assign({}, model_core_1.olm);
__exportStar(require("./src/Aircraft"), exports);
__exportStar(require("./src/Amenity"), exports);
__exportStar(require("./src/Affiliate"), exports);
__exportStar(require("./src/Client"), exports);
__exportStar(require("./src/Document"), exports);
__exportStar(require("./src/Airport"), exports);
__exportStar(require("./src/Trip"), exports);
__exportStar(require("./src/TripLeg"), exports);
__exportStar(require("./src/Quote"), exports);
__exportStar(require("./src/Account"), exports);
__exportStar(require("./src/Flight"), exports);
__exportStar(require("./src/FlightPlan"), exports);
__exportStar(require("./src/PriceItem"), exports);
__exportStar(require("./src/Deal"), exports);
__exportStar(require("./src/Share"), exports);
__exportStar(require("./src/Contact"), exports);
__exportStar(require("./src/Service"), exports);
__exportStar(require("./src/Schedule"), exports);
__exportStar(require("./src/Group"), exports);
__exportStar(require("./src/Marketplace"), exports);
__exportStar(require("./src/Order"), exports);
__exportStar(require("./src/Invoice"), exports);
__exportStar(require("./src/third-party/Avianis"), exports);
var ESchedulingSystems;
(function (ESchedulingSystems) {
    ESchedulingSystems["AirplaneManager"] = "airplane-manager";
    ESchedulingSystems["FOS"] = "fos";
    ESchedulingSystems["AVIANIS"] = "avianis";
    ESchedulingSystems["JET_INSIGHT"] = "jet_insight";
    ESchedulingSystems["NONE"] = "";
})(ESchedulingSystems || (exports.ESchedulingSystems = ESchedulingSystems = {}));
;
exports.schedulingSystems = [
    {
        label: 'Airplane Manager',
        value: ESchedulingSystems.AirplaneManager
    },
    {
        label: 'Avianis',
        value: ESchedulingSystems.AVIANIS
    },
    {
        label: 'FOS',
        value: ESchedulingSystems.FOS
    },
    {
        label: 'Jet Insight',
        value: ESchedulingSystems.JET_INSIGHT
    }
];
async function loadAll(parent, opts, stack, p) {
    opts = opts || { default_client_id: '' };
    opts.olm = opts.olm || exports.olm;
    await (0, model_core_1.loadAll)(parent, opts, stack, p);
}
exports.loadAll = loadAll;
