"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvianisService = exports.Service = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
class Service extends model_core_1.Base {
    constructor(data, olMap) {
        super('services', 6);
        this.type = '';
        Object.assign(this, (data === null || data === void 0 ? void 0 : data.data) ? data.data() : data);
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, Service);
    }
    toMinJSON(ignoreDocRef) {
        let r = super.toMinJSON(ignoreDocRef);
        Object.assign(r, this);
        return r;
    }
    toJSON() {
        let r = Object.assign({}, this);
        // Remove all underscored properties (things like _docRef, _exists, _new, etc.
        Object.keys(r).forEach(p => {
            if (p.match(/^_/)) {
                delete r[p];
            }
        });
        return r;
    }
}
exports.Service = Service;
class AvianisService extends Service {
}
exports.AvianisService = AvianisService;
index_1.olm.services = (ref, map) => {
    return new Service(ref, map);
};
