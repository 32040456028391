"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EPricingTypes = exports.Account = void 0;
const model_core_1 = require("@nxt/model-core");
const Trip_1 = require("./Trip");
const index_1 = require("../index");
class Account extends model_core_1.Base {
    constructor(data, olMap, type, idLen) {
        super(type || 'accounts', idLen || 21);
        this.name = '';
        this.email = '';
        this.phone = '';
        this.third_party = null;
        this.address = {};
        this.type = {};
        this.pricing = { type: EPricingTypes.RETAIL, discount: 0, payment: Trip_1.EPaymentTypes.CASH };
        this.aircraft = [];
        this._m = ['name', 'email', 'phone'];
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, Account);
        this.active = (this.active === null) ? true : this.active;
    }
    async loadAll(opts, stack, p) {
        opts = opts || { default_client_id: '' };
        opts.loadAllFn = opts.loadAllFn || index_1.loadAll;
        opts.olm = opts.olm || index_1.olm;
        return super.loadAll(opts, stack, p);
    }
}
exports.Account = Account;
// export class AccountContact extends Account {
//     avianis?: IAvianisAccountSettings = {}
//
//     _m = ['name','email','phone','avianis'];
//
//     constructor(data?: any, olMap?: any) {
//         super( data, olMap, 'accounts', 21);
//         setObjectProperties(this, data, olMap, AccountContact);
//         this.active = (this.active === null) ? true : this.active;
//     }
//
//
// }
// export enum EAccountTypes {
//     BROKER = 'Charter Broke
//     r',
//     CUSTOMER = 'Charter Customer',
//     OPERATOR = 'Charter Operator',
//     FLIGHT_DEPT = 'Flight Department',
//     OWNER = 'Managed Owner'
// }
var EPricingTypes;
(function (EPricingTypes) {
    EPricingTypes["RETAIL"] = "retail";
    EPricingTypes["WHOLESALE"] = "wholesale";
})(EPricingTypes || (exports.EPricingTypes = EPricingTypes = {}));
index_1.olm.accounts = (ref, map) => {
    return new Account(ref, map);
};
