import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';

import {Base, Thread} from '@nxt/model-core';

import {ThreadDetailComponent} from './thread-detail.component';
import {EThreadContext, MessagingService} from '@library/nxt/_services/messaging.service';
import {IconsComponent} from '@library/shared/icons/icons.component';
import {MessageMediumIcon} from '@library/nxt/list/message-medium-icon';
import {MessageRefIcon} from '@library/nxt/list/message-ref-icon';
import {FireService} from '@library/nxt/_services/fire.service';

@Component({
    standalone: true,
    imports: [CommonModule, ThreadDetailComponent, IconsComponent, MessageMediumIcon, MessageRefIcon],
    selector: 'thread-detail-wrapper',
    template: `
        <div class="h-full w-full overflow-y-auto">
            <div class="sticky top-0 z-10 w-full p-2 border-b bg-gray-200 flex justify-between place-items-center">
                <div>
                    <button class="btn-clear btn-sm" (click)="mSvc.thread$.next(null);thread=null;">
                        <icon name="heroicon-outline-x" class="h-4 w-4"></icon>
                    </button>
                </div>
                <button *ngIf="parent?._type!=='clients'" class="btn-dark btn-sm truncate max-w-2/3" (click)="mSvc.nav(thread)">
                    <message-ref-icon [item]="thread" class="w-5 h-5 text-light mr-1"></message-ref-icon>
                    <span class="max-w-[40vw]">{{ parent?.toString() }}</span>
                </button>

                <div class="font-bold" *ngIf="parent?._type==='clients'">
                    Company Chat
                </div>
            </div>
            <div class="p-4 pb-0 italic text-sm w-full text-right" *ngIf="parent?._type==='clients'">
                Must @mention users/roles for notifications to be sent.
            </div>
            <thread-detail [thread]="thread" [show]="show" [parent]="parent" [context]="context"></thread-detail>
        </div>
        
    `
})
export class ThreadDetailWrapperComponent implements OnChanges {
    @Input() thread: Thread;
    @Input() parent: Base;
    @Input() context: EThreadContext;
    @Input() compact: boolean = false;
    show: {move:boolean, print:boolean, followers:boolean} = { move: true, print: true, followers: true };

    constructor(
        public mSvc: MessagingService,
        private fSvc: FireService
    ) {
    }

    async ngOnChanges(changes: SimpleChanges) {
        if (this.thread?.id) {
            if (this.thread?.object?._docRef?.path) {
                this.parent = await this.fSvc.getObject(this.thread.object._docRef.path);
            }
            if (this.parent?._type === 'clients') {
                this.show =  { move: false, print: false, followers: false };
            }
        }
    }
}
