import {
    ViewChild, EventEmitter, Component, ElementRef, Input,
    AfterViewInit, Output, OnChanges, SimpleChanges
} from '@angular/core';
import {CommonModule} from '@angular/common';

import {Router, RouterModule} from '@angular/router';

import {IMenuItem} from '@nxt/model-core';
import {takeUntil} from 'rxjs/operators';

import {createPopper} from "@popperjs/core";

import {
    UntypedFormBuilder,
    UntypedFormGroup,
    FormsModule,
    ReactiveFormsModule,
    UntypedFormControl
} from '@angular/forms';
import {PageService} from '../../shared/_services/page.service';
import {OnDestroyPage} from '../../shared/_inherited/ondestroy.page';
import {InputStackedTextComponent} from '../../shared/input/input-stacked-text.component';

@Component({
    standalone: true,
    imports: [
        CommonModule, RouterModule, FormsModule, ReactiveFormsModule, InputStackedTextComponent
    ],
    selector: 'autocomplete',
    template: `
        <form [formGroup]="form" *ngIf="form" class="relative w-full">
            <input-stacked-text
                    #inputRef
                    [form]="form"
                    [label]="label"
                    [inputType]="'search'"
                    [controlName]="controlName"
                    [hideErrors]="dropdownPopoverShow"
                    (click)="toggleDropdown()"
                    (onEscape)="handleKeyup($event)"
                    (onEnter)="onEnter.emit()"
                    (onKeyup)="onKeyup.emit($event)"
                    (onTab)="onTab.emit();clear()"
                    (onClear)="onClear.emit()"
            ></input-stacked-text>
            <div class="z-40" style="position: absolute;" #popoverDropdownRef>
                <div
                        class="bg-white text-base z-30 py-2 list-none text-left rounded shadow-lg mb-1"
                        style="min-width: 12rem"
                        [class]="dropdownPopoverShow ? 'block' : 'hidden'"
                >
                    <a *ngFor="let item of items"
                       (click)="onSelect.emit(item);clear();"
                       class="cursor-pointer text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                    >
                        {{ displayFn ? displayFn(item) : item.label ? item.label : item.name || item }}
                    </a>
                    <a *ngIf="manualCloseLabel"
                       (click)="clear();"
                       class="cursor-pointer text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-gray-700 hover:bg-gray-100"
                    >
                        {{ manualCloseLabel }}
                    </a>
                </div>
            </div>
        </form>
    `,
})
export class AutoCompleteComponent extends OnDestroyPage implements AfterViewInit, OnChanges {
    @ViewChild("inputRef", {static: false}) inputRef: ElementRef;
    @ViewChild("popoverDropdownRef", {static: false}) popoverDropdownRef: ElementRef;
    @Output() onSelect: EventEmitter<any> = new EventEmitter<any>();
    @Output() onKeyup: EventEmitter<any> = new EventEmitter<any>();
    @Output() onEnter: EventEmitter<any> = new EventEmitter<any>();
    @Output() onTab: EventEmitter<any> = new EventEmitter<any>();
    @Output() onBlur: EventEmitter<any> = new EventEmitter<any>();
    @Output() onEscape: EventEmitter<any> = new EventEmitter<any>();
    @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
    @Input() displayFn: Function;
    @Input() label: string;
    @Input() menuPlacement: any;
    @Input() manualCloseLabel: string;
    @Input() form: UntypedFormGroup;
    @Input() controlName: string = 'searchTerm';

    @Input() set items(i: IMenuItem[]) {
        this._items = i;
        if (i.length) {
            this.dropdownPopoverShow = true;
        } else {
            this.dropdownPopoverShow = false;
        }
    }

    get items(): IMenuItem[] {
        return this._items;
    }
    _items: IMenuItem[];
    show: boolean;
    dropdownPopoverShow = false;

    constructor(
        private router: Router,
        private pSvc: PageService,
        private fb: UntypedFormBuilder,
        private eRef: ElementRef) {

        super();
        this.pSvc.click$
            .pipe(takeUntil(this.d$))
            .subscribe(
                e => {
                    if (e
                        && e.target
                        && this.eRef?.nativeElement
                        && !this.eRef.nativeElement.contains(e.target)
                        && !this.manualCloseLabel
                    ) {
                        this.clear();
                    }
                }
            );
    }

    clear() {
        this.show = false;
        this.dropdownPopoverShow = false;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.items) {
            if (this.items?.length <= 1) {
                this.clear();
            }
        }
    }

    ngAfterViewInit() {

        if (!this.form) {
            this.form = this.fb.group({});
            this.form.addControl(this.controlName, new UntypedFormControl());
        }

        if (this.inputRef?.nativeElement && this.popoverDropdownRef?.nativeElement) {
            createPopper(
                this.inputRef.nativeElement,
                this.popoverDropdownRef.nativeElement,
                {
                    placement: this.menuPlacement || 'auto'
                }
            );
        }

    }

    toggleDropdown() {
        if (this.dropdownPopoverShow) {
            if (!this.manualCloseLabel && !this.items.length) {
                this.dropdownPopoverShow = false;
            }
        } else if (this.items.length) {
            this.dropdownPopoverShow = true;
        }
    }

    handleKeyup(e?: KeyboardEvent) {
        if (e === undefined) {
            this.form.get(this.controlName).setValue('');
        } else if (e?.key === "Escape") {
            this.form.get(this.controlName).setValue('');
        }
        this.onKeyup.emit(this.form.get(this.controlName).value);
    }
}
