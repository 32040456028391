import {Component} from '@angular/core';
import {Router} from '@angular/router';

import {ConsumerAccountService} from '@library/consumer/_services/consumer.account.service';
import {ClientService} from '@library/shared/_services/client.service';

@Component({
    standalone: true,
    imports: [],
    template: `

    `
})
export class LogoutPage {
    constructor(
        public aSvc: ConsumerAccountService,
        private cSvc: ClientService,
        private router: Router
    ) {
        this.aSvc.signOut()
            .then(e => {
                this.router.navigate([`/${this.cSvc.name_key}/book`])
            })
    }

}
