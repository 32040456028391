import {Injectable} from '@angular/core';
import {take} from 'rxjs/operators';
// @ts-ignore
import {environment} from '@env/environment';
import {MessagingService} from '../../nxt/_services/messaging.service';
import {
    Base,
    Document,
    EMedium,
    ITemplateData,
    loadObject,
    User
} from '@nxt/model-core';
import {Contact, Contact as LaroContact, Quote, Trip} from '@nxt/model-laro';
import {ClientService} from '../../shared/_services/client.service';
import {PageService} from '../../shared/_services/page.service';
import {ItineraryEmailDialog} from '../../../laro/src/operator/_components/trip/itinerary-email.dialog';
import {Subscription} from 'rxjs';

@Injectable()
export class LaroTemplateLoaderService {
    type: string;

    constructor(
        private mSvc: MessagingService,
        private cSvc: ClientService,
        private pSvc: PageService
    ) {

        let sub: Subscription;
        this.cSvc.client$
            .subscribe(c => {
                if (c?.id) {
                    this.mSvc.templates$.next([]);
                    sub?.unsubscribe();
                    sub = this.mSvc.context$
                        .subscribe(async p => {
                            await this.loadTemplates(p?._type);
                        });
                }
            });
    }

    async loadTemplates(type: string) {
        if (type) {
            type = type?.replace(/s$/,'');
            if (!this.mSvc.templates$.getValue()?.length || !this.mSvc.templates$.getValue()?.find(t => t.value?.data_argument===type)) {
                let templateQuery = [
                    {name: 'where', args: ['data_argument', '==', type]}
                    , {name: 'where', args: ['type', '==', 'manual']}
                    , {name: 'where', args: ['active', '==', true]}
                ];
                let result: any[] = await this.cSvc.callAPI('/cms/documents', 'post', {templateQuery: templateQuery});
                result = (result?.map(item => {
                    let d: Document = new Document(item);
                    return {
                        label: d.name,
                        value: d,
                        click: async () => {

                            try {

                                this.pSvc.blocking$.next(true);
                                this.mSvc.templateDocument$.next(d);
                                let parent: Base = this.mSvc.context$.getValue();
                                let thread = this.mSvc.thread$.getValue();
                                if (thread?.ref) {
                                    let doc = await thread.ref.get();
                                    parent = loadObject( doc, { olm: this.mSvc.olm, loadAllFn: this.mSvc.loadAll, default_client_id: environment.default_client.id });
                                }
                                if (type === 'trip' && d?.template_id !== 'payment_request.html.js') {
                                    await this.sendItinerary();
                                } else if (type === 'trip' && d?.template_id === 'payment_request.html.js') {
                                    await this.sendPaymentRequest();
                                } else if (type === 'quote') {
                                    await this.sendQuote();
                                } else {
                                    let recip: any;
                                    if (parent?._type?.match(/contacts|users/)) {
                                        recip = parent;
                                    } else if (parent && parent['contact']) {
                                        recip = parent['contact'];
                                    }
                                    return this.mSvc.startTemplateMessage(d.template_id, d.template_id,  EMedium.EMAIL, recip ? [recip] : [], false);
                                }
                            } catch (e) {
                                console.warn(e);
                            }

                            this.pSvc.blocking$.next(false);
                        }
                    };
                }) || []);

                if (result?.length) {
                    result.push({
                        label: 'No Template',
                        click: () => {
                            this.mSvc.templateDocument$.next(null);
                        }
                    });
                } else {
                    // Flush any previous templates if the current context has no templates.
                    this.mSvc.templateDocument$.next(null);
                }
                this.mSvc.templates$.next(result);
            }
        }
        return this.mSvc.templates$.getValue();
    }

    async sendItinerary(recipients?: any[], template?: string, medium?: EMedium, data?: ITemplateData[]) {

        let thread = this.mSvc.thread$.getValue();
        let context = this.mSvc.context$.getValue();
        if (thread?.ref) {
            context = loadObject( (await thread.ref.get()), { olm: this.mSvc.olm, loadAllFn: this.mSvc.loadAll, default_client_id: environment.default_client.id });
        }

        if (!recipients && template !== 'payment_request.html.js') {

            this.pSvc.modal$.next({
                label: 'Send Itinerary',
                component: ItineraryEmailDialog,
                onLoaded: async (comp: ItineraryEmailDialog) => {
                    let trip: Trip = context as Trip;
                    await trip.loadAll({
                        default_client_id: environment.default_client.id,
                        loadAllFn: this.mSvc.loadAll,
                        olm: this.mSvc.olm
                    });
                    comp.trip = trip;
                    comp.template_id = this.mSvc.templateDocument$.getValue()?.template_id || '';
                    comp.onClose.pipe(take(1))
                        .subscribe(
                            (result: [string, EMedium, ITemplateData[], LaroContact[]]) => {
                                if (result) {
                                    this.sendItinerary(result[3], result[0], result[1], result[2]);
                                } else {
                                    this.mSvc.templateDocument$.next(null);
                                }
                            }
                        );
                    comp.ngOnChanges();
                }
            })

        } else {

            await this.mSvc.startTemplateMessage(
                (template==='itinerary_pax.html.js') ? 'quote_submission.html.js' : template,
                template,
                medium || EMedium.EMAIL,
                recipients,
                false,
                data,
                this.mSvc.templateDocument$.getValue()
            );

        }
    }

    async sendQuote() {
        let quote: Quote = this.mSvc.context$.getValue() as Quote;
        let thread = this.mSvc.thread$.getValue();
        if (thread?.ref) {
            quote = loadObject( (await thread.ref.get()), { olm: this.mSvc.olm, loadAllFn: this.mSvc.loadAll, default_client_id: environment.default_client.id });
        }

        if (!quote?._exists) {
            this.pSvc.alert$.next({
                title: 'Cannot Send',
                message: 'Quote is not loaded properly. Load the quote details page and try again. If this problem persists, please contact Support.'
            });
        } else {

            await quote.loadAll({
                default_client_id: environment.default_client.id,
                loadAllFn: this.mSvc.loadAll,
                olm: this.mSvc.olm
            });

            let recipients: any[] = [];
            if (quote.contact?.email) {
                recipients.push(quote.contact);
            }
            quote.accounts?.forEach(a => {
                if (a.email && !recipients.find(i => i.email?.toLowerCase() === a.email.toLowerCase())) {
                    recipients.push(a);
                }
            });

            await this.mSvc.startTemplateMessage(
                'quote_submission.html.js',
                'quote.html.js',
                EMedium.EMAIL,
                recipients,
                false,
                null,
                this.mSvc.templateDocument$.getValue()
            );

        }

    }

    async sendPaymentRequest() {

        let trip: Trip = this.mSvc.context$.getValue() as Trip;
        let thread = this.mSvc.thread$.getValue();
        if (thread?.ref) {
            trip = loadObject( (await thread.ref.get()), { olm: this.mSvc.olm, loadAllFn: this.mSvc.loadAll, default_client_id: environment.default_client.id });
        }

        if (!trip?.price?.locked) {
            this.pSvc.alert$.next({
                title: 'Cannot Send Payment Request',
                message: 'The price on this trip has not been locked.  Lock the price and then send the request.'
            });
        } else if (trip?.paid) {
            this.pSvc.alert$.next({
                title: 'Cannot Send Payment Request',
                message: 'The trip is already marked paid.'
            });
        } else {
            await trip.loadAll({
                default_client_id: environment.default_client.id,
                loadAllFn: this.mSvc.loadAll,
                olm: this.mSvc.olm
            });
            let recipients: any[] = [];
            if (trip?.contact?.email) {
                recipients.push(trip.contact);
            }

            await this.mSvc.startTemplateMessage(
                'payment_request.html.js',
                'payment_request.html.js',
                EMedium.EMAIL,
                recipients,
                false,
                null,
                this.mSvc.templateDocument$.getValue()
            );
        }

    }


}


