import {Injectable} from '@angular/core';

// @ts-ignore
import {environment} from '@env/environment';

import {UserService} from './user.service';
import {PageService} from '../../shared/_services/page.service';

import {LocalStorageService} from '../../shared/_services/local-storage.service';
import {getMessaging, getToken, Messaging} from '@angular/fire/messaging';
import {getApp} from '@angular/fire/app';
import {firstValueFrom} from 'rxjs';
import {ClientService} from '../../shared/_services/client.service';

@Injectable()
export class PushService {
    messaging: Messaging;

    get token() {
        return this.lSvc.globalState['pushToken'];
    }
    set token(t) {
        this.lSvc.saveGState('pushToken', t);
    }
    get permission() {
        return this.lSvc.globalState['pushTokenPermission'];
    }
    set permission(t) {
        this.lSvc.saveGState('pushTokenPermission', t);
    }
    get showInApp(): boolean {
        return this.lSvc.globalState['showInApp'];
    }
    set showInApp(b: boolean) {
        this.lSvc.saveGState('showInApp', b);
    }

    constructor(
        private uSvc: UserService,
        private pSvc: PageService,
        private cSvc: ClientService,
        private lSvc: LocalStorageService
    ) {

        this.messaging = getMessaging(getApp(environment.appName));
        firstValueFrom(this.uSvc.user$)
            .then(user => {
                if (user?._exists) {
                    if (user?.metadata?.tokens?.length) {
                        if (this.permission==='denied' || !this.permission || (this.token && !user.metadata.tokens.includes(this.token))) {
                            this.disable();
                        } else if (this.permission) {
                            this.loadToken();
                        }
                    } else if (this.permission) {
                        this.loadToken();
                    }

                }
            });
    }

    async loadToken() {
        getToken(this.messaging, {vapidKey: environment.push_public_key})
            .then(async (currentToken) => {
                if (currentToken) {
                    if (currentToken !== this.token) {
                        this.token = currentToken;
                    }
                } else {
                    this.disable();
                }
            })
            .catch((err) => {
                console.warn(err);
                this.disable();
            });
    }

    async requestPermission() {
        if (!Notification?.requestPermission) {
            this.pSvc.notification$.next({
                title: 'iOS User?',
                message: `To turn on notifications, add this site to your home screen and then try again. Adding Recruit Messenger to your home screen makes it easier to get to your account, and also allows notifications to work.`
            })
        } else {
            Notification.requestPermission()
                .then(async (permission) => {
                    this.permission = permission;
                    if (permission === 'granted') {
                        this.loadToken();
                    } else if (this.token) {
                        this.disable();
                    } else if (permission === 'denied') {
                        this.pSvc.notification$.next({
                            title: 'Cannot Activate Notifications!',
                            message: `You have previously denied notifications. Please enable notifications on your browser for this page and try again.`
                        });
                    }
                })
        }
    }

    async disable() {
        this.permission = '';
        if (this.token) {
            this.pSvc.loading$.next(true);
            try {
                await this.cSvc.callAPI('/messaging/push/notifications', 'post', {
                    token: this.token,
                    disable: true
                });
                this.token = '';
            } catch (e) {
                console.warn(e);
            }
            this.pSvc.loading$.next(false);
        }
    }

    async sendTestPush() {
        this.pSvc.loading$.next(true);
        try {
            await this.cSvc.callAPI('/messaging/push/notifications', 'post', {
                token: this.token,
                url: window.location.href.replace('/notifications', '')
            });
        } catch (e) {
            console.warn(e);
            this.pSvc.alert$.next(e);
        }
        this.pSvc.loading$.next(false);
    }


}
