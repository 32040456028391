import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {IPrice, Quote, QuoteRequest, Trip} from '@nxt/model-laro';
import {IMenuItem} from '@nxt/model-core';
import {NgIf} from '@angular/common';
import {TabBarComponent} from '@library/nxt/tabs/tab-bar.component';
import {LaroPriceBreakdown} from '../price/laro-price-breakdown';

@Component({
    selector: 'laro-internal-quote-results',
    standalone: true,
    imports: [
        NgIf,
        TabBarComponent,
        LaroPriceBreakdown
    ],
    template: `
        <tab-bar [tabs]="resultsTabs" *ngIf="resultsTabs?.length"></tab-bar>
        <laro-price-breakdown *ngIf="activeQuote" 
                              [price]="activeQuote?.estimate" 
                              (onChange)="onChange.emit($event)"
        ></laro-price-breakdown>
    `
})
export class LaroInternalQuoteResults implements OnChanges {
    @Output() onChange: EventEmitter<IPrice> = new EventEmitter<IPrice>();
    @Input() results: Quote[];
    @Input() activeQuote: Trip|Quote;
    resultsTabs: IMenuItem[];

    ngOnChanges(changes: SimpleChanges) {
        if (this.results?.length) {
            this.buildResultsTabs();
            if (!this.activeQuote) {
                this.activeQuote = this.results?.length ? this.results[0] : null;
            }
        } else {
            this.resultsTabs = null;
            this.activeQuote = null;
        }
    }


    buildResultsTabs() {
        this.resultsTabs = this.results?.map((q: QuoteRequest) => {
            if (!this.activeQuote) {
                this.activeQuote = q;
                this.onChange.emit(q.estimate);
            }
            return {
                label: `${q.aircraft?.name}`,
                active: (this.activeQuote?.aircraft?.id === q.aircraft?.id),
                click: () => {
                    this.activeQuote = q;
                    this.onChange.emit(q.estimate);
                    this.buildResultsTabs();
                }
            }
        });
    }

}
