import {Component, EventEmitter, Output, OnInit, ViewChild, Input} from '@angular/core';
import {CommonModule} from '@angular/common';

import {IAlgoliaSearchResults} from '@nxt/model-core';
import {TRIP_STATUS_MAP} from '@nxt/model-laro';

import {
    getUsersByRoleFilterSets,
    IAlgoliaFilterSet,
    AlgoliaSearchComponent, getManualFilterSet
} from '@library/shared/search/algolia-search.component';
import {LocalStorageService} from '@library/shared/_services/local-storage.service';
import {AccountService} from '@library/nxt/_services/account.service';
import {addDays, format, startOfDay} from 'date-fns';
import {ClientService} from '@library/shared/_services/client.service';
import {take} from 'rxjs/operators';
import {PageService} from '@library/shared/_services/page.service';
import {DateRangeDialog} from '@library/shared/input/date-range.dialog';
import {FireService} from '@library/nxt/_services/fire.service';
import {Router} from '@angular/router';

@Component({
    standalone: true,
    imports: [CommonModule, AlgoliaSearchComponent],
    selector: 'laro-quote-search',
    template: `
        <algolia-search-component
                #searchComponent
                index="quotes"
                placeholder="Search Quotes"
                [saveSearch]="saveSearch"
                [autoStart]="false"
                [filterSet]="filterSets"
                (onResults)="handleResults($event)"
                (onClear)="onClear.emit($event)"
        ></algolia-search-component>
    `
})
export class NewLaroQuoteSearchComponent implements OnInit {
    @Output() onResults: EventEmitter<[IAlgoliaSearchResults, boolean]> = new EventEmitter<[IAlgoliaSearchResults, boolean]>();
    @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('searchComponent') searchComponent: AlgoliaSearchComponent;
    @Input() saveSearch: boolean = true;
    type: string = 'quotes';
    filterSets: IAlgoliaFilterSet[];
    numericSearch: boolean;
    matches: boolean;
    
    constructor(
        public lSvc: LocalStorageService,
        private aSvc: AccountService,
        private cSvc: ClientService,
        private pSvc: PageService,
        private fSvc: FireService,
        private router: Router
    ) {
    }

    ngOnInit() {
        this.loadFilters();
    }

    async loadFilters() {
        let now: Date = new Date();
        let start: Date = new Date();
        let end: Date = new Date();

        let filterSets:IAlgoliaFilterSet[] = [
            {
                name: 'Date Filters',
                buttons: [
                    {
                        label: 'Custom Date Range',
                        class: 'btn-dark',
                        click: () => {
                            this.pSvc.modal$.next({
                                label: 'Custom Date Range',
                                component: DateRangeDialog,
                                styles: {
                                    width: '600px'
                                },
                                onLoaded: (comp: DateRangeDialog) => {
                                    comp.start = start;
                                    comp.end = end;
                                    comp.onClose
                                        .pipe(take(1)).subscribe(
                                        (result: {start:Date, end:Date}) => {
                                            
                                            if (result) {
                                                start = result.start;
                                                end = result.end;
                                                this.searchComponent.builder.remove('filterItems', {id:'CUSTOM'});
                                                this.searchComponent.toggleFilter({
                                                    id: 'CUSTOM',
                                                    name: `${format(start,'MMM d, yyyy')} > ${format(end,'MMM d, yyyy')}`,
                                                    color: '#505cac',
                                                    contrast: '#ffffff',
                                                    key: 'Date',
                                                    type: 'numeric',
                                                    key_unique: true,
                                                    more: `departure_date>${start.valueOf()}`,
                                                    value: `departure_date<${end.valueOf()}`
                                                });
                                            }
                                        }
                                    );
                                    comp.ngOnChanges();
                                }
                            });
                        }
                    }
                ],
                items: [
                    {
                        id: '3Days',
                        name: 'Next 3 Days',
                        color: '#505cac',
                        contrast: '#ffffff',
                        key: 'Date',
                        type: 'numeric',
                        key_unique: true,
                        more: `departure_date>${startOfDay(now).valueOf()}`,
                        value: `departure_date<${addDays(now,3).valueOf()}`
                    },
                    {
                        id: '7DAYS',
                        name: 'Next 7 Days',
                        color: '#505cac',
                        contrast: '#ffffff',
                        key: 'Date',
                        type: 'numeric',
                        key_unique: true,
                        more: `departure_date>${startOfDay(now).valueOf()}`,
                        value: `departure_date<${addDays(now,6).valueOf()}`
                    },
                    {
                        id: '14DAYS',
                        name: 'Next 14 Days',
                        color: '#505cac',
                        contrast: '#ffffff',
                        key: 'Date',
                        type: 'numeric',
                        key_unique: true,
                        more: `departure_date>${startOfDay(now).valueOf()}`,
                        value: `departure_date<${addDays(now,14).valueOf()}`
                    },
                    {
                        id: '30DAYS',
                        name: 'Next 30 Days',
                        color: '#505cac',
                        contrast: '#ffffff',
                        key: 'Date',
                        type: 'numeric',
                        key_unique: true,
                        more: `departure_date>${startOfDay(now).valueOf()}`,
                        value: `departure_date<${addDays(now,30).valueOf()}`
                    },
                    {
                        id: '60DAYS',
                        name: 'Next 60 Days',
                        color: '#505cac',
                        contrast: '#ffffff',
                        key: 'Date',
                        type: 'numeric',
                        key_unique: true,
                        more: `departure_date>${startOfDay(now).valueOf()}`,
                        value: `departure_date<${addDays(now,60).valueOf()}`
                    },
                    {
                        id: '90DAYS',
                        name: 'Next 90 Days',
                        color: '#505cac',
                        contrast: '#ffffff',
                        key: 'Date',
                        type: 'numeric',
                        key_unique: true,
                        more: `departure_date>${startOfDay(now).valueOf()}`,
                        value: `departure_date<${addDays(now,90).valueOf()}`
                    }
                ]
            },
            {
                name: 'Status Filters',
                items: Object.keys(TRIP_STATUS_MAP).reduce((statuses,status) => {
                    if (!status.match(/^t/)) {
                        statuses.push({
                            id: status,
                            name: TRIP_STATUS_MAP[status].label,
                            color: TRIP_STATUS_MAP[status].color,
                            contrast: TRIP_STATUS_MAP[status].contrast,
                            key: 'status',
                            key_unique: true,
                            type: 'facet',
                            value: [`status:${status}`]
                        });
                    }
                    return statuses;
                }, [])
            }
        ];
        // filterSets = filterSets.concat( await getManualFilterSet(this.cSvc, this.fSvc, this.pSvc, this.router, 'quotes') );
        this.filterSets = filterSets.concat(await getUsersByRoleFilterSets(this.aSvc, this.cSvc));
    }
    
    async handleResults([results, append]) {
        if (!append) {
            this.matches = false;
            this.numericSearch = !!this.searchComponent.builder.query.trim().match(/\d+((,\d+)|)/);
            if (this.numericSearch) {
                let term: string = this.searchComponent.builder.query;
                for (let i = (results.hits.length-1); i >=0; i--) {
                    let item = results.hits[i];
                    let parts: string[] = term.split(',');
                    let match: boolean = false;
                    for (let part of parts) {
                        part = part.trim();
                        if (item.quote_number.match(part) || item.quote_number === part) {
                            match = true;
                            this.matches = true;
                        }
                    }
                    if (!match) {
                        results.hits.splice(i,1);
                    }
                }
            }
        }
        return this.onResults.emit([results,append]);
    }

}
