import {Injectable} from '@angular/core';
import {olm, loadAll} from '@nxt/model-laro';
// @ts-ignore
import {environment} from '@env/environment';

@Injectable()
export class LaroObjectLoaderService {

    getOLM(name_key: string) {
        return olm;
    }

    getLoadAll(name_key: string) {
        return (parent,opts) => {
            opts = opts||{};
            opts.default_client_id = environment.default_client.id;
            opts.olm = olm;
            opts.loadAllFn = loadAll;
            return loadAll(parent,opts)
        }
    }
}


