import {Component, EventEmitter, Output, ViewChild} from '@angular/core';

import {CommonModule} from '@angular/common';

// @ts-ignore
import {environment} from '@env/environment';
import {ImportQuoteDialog} from '../import/import-quote.dialog';
import {take} from 'rxjs/operators';
import {ImportTripDialog} from '../import/import-trip.dialog';
import {Base, IMenuItem, Thread} from '@nxt/model-core';
import {ETripStatus, Quote, Trip} from '@nxt/model-laro';

import {TabBarComponent} from '@library/nxt/tabs/tab-bar.component';
import {LaroItemListComponent} from '../list/laro-item-list.component';
import {PipesModule} from '@library/shared/_pipes/pipes';

import {EThreadContext} from '@library/nxt/_services/messaging.service';
import {PageService} from '@library/shared/_services/page.service';

import {TripItemComponent} from '../list/trip.item';
import {
    MessageItemSummary
} from '../../../../../nxto/src/app/threads/_components/message-item-summary';
import {ContactItem} from '@library/nxt/list/contact.item';
import {ScrollableGenericList} from '@library/nxt/list/scrollable-generic-list';
import {NewLaroQuoteSearchComponent} from './laro-quote-search';
import {MessageSearchComponent} from '@library/nxt/search/message-search';
import {NewLaroTripSearchComponent} from './laro-trip-search';
import {NewNxtContactSearchComponent} from '@library/nxt/search/nxt-contact-search';
import {MessageItemHomepage} from '../../../../../nxto/src/app/threads/_components/message-item-homepage';


@Component({
    standalone: true,
    imports: [
        CommonModule, TabBarComponent, LaroItemListComponent,
        PipesModule, TripItemComponent,
        MessageItemSummary, ContactItem, ScrollableGenericList,
        NewLaroQuoteSearchComponent, MessageSearchComponent,
        NewLaroTripSearchComponent, NewNxtContactSearchComponent, MessageItemHomepage
    ],
    template: `
        <tab-bar *ngIf="path" [tabs]="tabs"></tab-bar>
        <div *ngIf="message" class="p-4">{{ message }}</div>
        <ng-container [ngSwitch]="path">

            <nxt-contact-search *ngSwitchCase="'contacts'"
                                    #contactSearch
                                    (onResults)="itemList.handleAlgoliaResults($event)"
                                    (onClear)="itemList.items=null"
                                    [saveSearch]="false"
            ></nxt-contact-search>

            <laro-quote-search *ngSwitchCase="'quotes'"
                                  #quoteSearch
                                  (onResults)="itemList.handleAlgoliaResults($event)"
                                  (onClear)="itemList.items=null"
                                  [saveSearch]="false"
            ></laro-quote-search>

            <laro-trip-search *ngSwitchCase="'trips'"
                                  #tripSearch
                                  (onResults)="itemList.handleAlgoliaResults($event)"
                                  (onClear)="itemList.items=null"
                                  [saveSearch]="false"
            ></laro-trip-search>

            <message-search *ngSwitchCase="'threadsmsgs'"
                                #msgSearch
                                (onResults)="itemList.handleAlgoliaResults($event)"
                                (onClear)="itemList.items=null"
                                [hitsPerPage]="pagingSize"
                                [saveSearch]="false"
                                [hideToggle]="true"
            ></message-search>
        </ng-container>

        <div class="w-full text-center mb-3">
            <div *ngIf="path==='trips' && tripSearch?.numericSearch">
                <button class="btn-dark btn-xs" (click)="importTrip(tripSearch?.searchComponent?.builder?.query)">
                    Import Trip {{tripSearch?.searchComponent?.builder?.query}}
                </button>
            </div>

            <div class="m-auto max-w-400" *ngIf="path==='quotes' && quoteSearch?.numericSearch">
                <button class="btn-dark btn-xs" (click)="importQuote(quoteSearch?.searchComponent?.builder?.query)">
                    Import Quote {{quoteSearch?.searchComponent?.builder?.query}}
                </button>
            </div>
        </div>

        <scrollable-generic-list
                #itemList
                class="h-screen-header-search pb-20 overflow-y-auto"
                label="Matches"
                (onClear)="clearSearch()"
                [exclude]="exclude"
                [pageSize]="pagingSize"
                [itemTemplate]="itemTemplate"
        ></scrollable-generic-list>
        
        <ng-template let-item="item" let-items="items" let-i="i" #itemTemplate>
            <ng-container [ngSwitch]="item._type">
                
                <contact-item *ngSwitchCase="'contacts'"
                              [contact]="item"
                              (onClick)="onClick.emit($event)"
                ></contact-item>
                
                <message-item-homepage
                        *ngSwitchCase="'threadsmsgs'"
                        (onSelect)="onClick.emit($event)"
                        [message]="item"
                        [context]="EThreadContext.INBOX"
                ></message-item-homepage>
                
                <trip-item *ngSwitchDefault [item]="item" (onClick)="onClick.emit($event)"></trip-item>

            </ng-container>
        </ng-template>
        
    `
})
export class LaroAllSearchDialog {
    @Output() onClose: EventEmitter<Thread> = new EventEmitter<Thread>();
    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('tripSearch') tripSearch: NewLaroTripSearchComponent;
    @ViewChild('quoteSearch') quoteSearch: NewLaroQuoteSearchComponent;
    @ViewChild('contactSearch') contactSearch: NewNxtContactSearchComponent;
    @ViewChild('msgSearch') msgSearch: MessageSearchComponent;

    message: string;
    env = environment;
    path: string = 'trips';
    action: string;
    exclusivePath: string[];
    pagingSize: number = 20;
    EThreadContext = EThreadContext;
    quote: Quote;
    trip: Trip;
    thread: Thread;
    exclude: Base;

    get tabs(): IMenuItem[] {
        return [
            {
                label: `Trips`,
                active: this.path === 'trips',
                hide: (this.exclusivePath && !this.exclusivePath.includes('trips')),
                click: () => {
                    this.path = 'trips'
                }
            },
            {
                label: `Quotes`,
                active: this.path === 'quotes',
                hide: (this.exclusivePath && !this.exclusivePath.includes('quotes')),
                click: () => {
                    this.path = 'quotes'
                }
            },
            {
                label: `Contacts`,
                active: this.path === 'contacts',
                hide: (this.exclusivePath && !this.exclusivePath.includes('contacts')),
                click: () => {
                    this.path = 'contacts';
                }
            },
            {
                label: `Messages`,
                active: this.path === 'threadsmsgs',
                hide: (this.exclusivePath && !this.exclusivePath.includes('threadsmsgs')),
                click: () => {
                    this.path = 'threadsmsgs';
                }
            }
        ];
    }

    constructor(public pSvc: PageService) {}

    importQuote(quote_number?: string) {
        this.pSvc.modal$.next({
            component: ImportQuoteDialog,
            styles: {width: '600px'},
            onLoaded: (comp: ImportQuoteDialog) => {
                comp.quote_number = quote_number;
                comp.thread = this.thread;
                if (this.exclude instanceof Quote && this.exclude.status === ETripStatus.quoteSubmitted) {
                    comp.existing_quote = this.exclude;
                }
                comp.ngOnChanges();
                comp.submit();
                comp.onClose.pipe(take(1))
                    .subscribe(async result => {
                        if (result?.id) {
                            this.onClick.emit(new Quote(result));
                            this.onClose.emit();
                        }
                    })
            }
        })
    }

    importTrip(trip_number: string) {
        this.pSvc.modal$.next({
            component: ImportTripDialog,
            styles: {width: '600px'},
            onLoaded: async (comp: ImportTripDialog) => {
                comp.trip_number = trip_number;
                if (this.exclude instanceof Trip && this.exclude.status === ETripStatus.tripSubmitted) {
                    comp.existing_trip = this.exclude;
                }
                comp.ngOnChanges();
                comp.submit();
                comp.onClose.pipe(take(1))
                    .subscribe(result => {
                        if (result?.id) {
                            this.onClick.emit(new Trip(result));
                            this.onClose.emit();
                        }
                    })
            }
        });
    }


    clearSearch() {
        ['contactSearch','quoteSearch','tripSearch','msgSearch'].forEach(p => {
            try {
                this[p]['searchComponent']?.clearTerm(true);
            } catch (e) {
                // console.warn(e);
            }
        });
    }

}

