import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Router} from '@angular/router';
import {FormsModule, ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

import {InputStackedTextComponent} from '@library/shared/input/input-stacked-text.component';
import {FooterButtonsComponent} from '@library/nxt/footer/footer-buttons.component';
import {Contact as LaroContact, ETripStatus, Quote, QuoteRequest, Trip} from '@nxt/model-laro';
import {formatCurrency, IMenuItem, Thread} from '@nxt/model-core';
import {ClientService} from '@library/shared/_services/client.service';
import {PageService} from '@library/shared/_services/page.service';
import {FireService} from '@library/nxt/_services/fire.service';

@Component({
    standalone: true,
    imports: [
        CommonModule,
        InputStackedTextComponent,
        ReactiveFormsModule,
        FooterButtonsComponent,
    ],
    template: `
        <div class="p-3">
            <div [formGroup]="form" *ngIf="form">
                <p class="italic text-sm p-3">Enter quote number from Avianis and click Link.</p>
                <input-stacked-text label="Quote Number"
                                    [form]="form"
                                    controlName="quote_number"
                                    (onEnter)="submit()"
                ></input-stacked-text>
            </div>
        </div>
        <footer-buttons-component [buttons]="buttons"></footer-buttons-component>

    `
})
export class ImportQuoteDialog implements OnChanges {
    @Output() onClose: EventEmitter<any> = new EventEmitter<any>();
    @Input() contact: LaroContact;
    @Input() thread: Thread;
    existing_quote: Quote;
    existing_trip: Trip;
    path: string = 'import';
    maxWidth: string = '400px';
    form: UntypedFormGroup;

    results: QuoteRequest[];
    formatCurrency = formatCurrency;
    quote_number: string;
    buttons: IMenuItem[] = [];
    tabs: IMenuItem[];

    constructor(
        private cSvc: ClientService,
        private pSvc: PageService,
        private fb: UntypedFormBuilder,
        private fSvc: FireService,
        private router: Router
    ) {
    }

    ngOnChanges(changes?:SimpleChanges) {
        this.form = this.fb.group({
            quote_number: [this.existing_quote?.quote_number || this.existing_trip?.quote_number || this.quote_number || '']
        });
        this.buildButtons()
    }

    buildButtons() {

        this.buttons = [
            {
                label: 'Close',
                
                click: () => {
                    this.onClose.emit();
                }
            },
            {
                label:  'Link',
                class: 'btn-dark',
                show: () => {
                    return this.path === 'import';
                },
                click: () => {
                    this.submit();
                }
            }
        ];


        this.buttons.push({
                label: 'View Quote',
                class: 'btn-light',
                show: () => {
                    return !!this.existing_quote?.id && !!this.existing_trip?.id && !window.location.pathname.match(this.existing_quote?.id);
                },
                click: () => {
                    this.onClose.emit();
                    this.router.navigate([`/${this.cSvc.name_key}/quotes/${this.existing_quote.id}`]);
                }
            });

    }

    clear() {
        this.results = null;
    }

    handleResults(results: [QuoteRequest[], boolean]) {
        console.log('WTF',results);

        this.pSvc.blocking$.next(false);
        this.results = results?.length ? results[0] : null;
    }

    async submit() {
        if (this.form.get('quote_number').value) {

            this.pSvc.blocking$.next(true);
            try {

                let result: any = await this.cSvc.callAPI('/laro/quote/import', 'post', {
                    parent: this.contact?.toMinJSON ? this.contact?.toMinJSON() : this.contact || null,
                    thread: this.thread?.toJSON() || null,
                    quote_number: this.form.get('quote_number').value,
                    trip_id: this.existing_quote?.trip_id || this.existing_trip?.id || '',
                    quote_id: this.existing_quote?.id || this.existing_trip?.quote_id || ''
                });

                this.onClose.emit(result);
                await this.router.navigate([`/${this.cSvc.name_key}/quotes/${result.id}`]);

            } catch (e) {
                if (e.error?.id) {
                    this.onClose.emit(( await this.fSvc.getObject(`clients/${this.cSvc.client_id}/quotes/${e.error.id}`)) as Quote);
                    await this.router.navigate([`/${this.cSvc.name_key}/quotes/${e.error.id}`]);
                } else {
                    this.pSvc.alert$.next(e);
                }
            }
            this.pSvc.blocking$.next(false);
        } else {
            this.form.markAllAsTouched();
            this.form.get('quote_number').setErrors({required: true});
        }
    }
}
