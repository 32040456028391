"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LaroGroupSubscription = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
class LaroGroupSubscription extends model_core_1.GroupSubscription {
    constructor(data, olMap) {
        super(data, olMap || index_1.olm, 'subscriptions', 9);
        this._s = ['home', 'destinations', 'contact', 'active'];
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, LaroGroupSubscription);
    }
    /**
     * Do a custom toSearchJSON to properly load all the airports and their geo locations for
     * proper subscription searches in the marketplace.
     * @param opts
     */
    async toSearchJSON(opts) {
        var _a, _b, _c, _d, _e;
        let r = await super.toSearchJSON(opts);
        if ((_b = (_a = this.preferences) === null || _a === void 0 ? void 0 : _a.home) === null || _b === void 0 ? void 0 : _b.id) {
            r.home = ((_c = this.preferences.home) === null || _c === void 0 ? void 0 : _c.icao) || '';
            r._geoloc = {
                lat: Number(this.preferences.home.latitude),
                lng: Number(this.preferences.home.longitude)
            };
        }
        r.destinations = ((_e = (_d = this.preferences) === null || _d === void 0 ? void 0 : _d.destinations) === null || _e === void 0 ? void 0 : _e.map(d => d.icao || d)) || [];
        return r;
    }
}
exports.LaroGroupSubscription = LaroGroupSubscription;
index_1.olm.subscriptions = (ref, map) => {
    return new LaroGroupSubscription(ref, map);
};
