import {
    Component, ElementRef, EventEmitter, Inject,
    Input, OnChanges, Output, SimpleChanges
} from '@angular/core';
import {ReactiveFormsModule, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';

import {
    EMedium,
    Thread,
    ThreadMessage,
    IMessagePost,
    Document,
    IMenuItem,
    Contact,
    Group,
    CloudFile, Base
} from '@nxt/model-core';
import {HtmlViewerDialog} from './html-viewer.dialog';
import {UserService} from '@library/nxt/_services/user.service';
import {ClientService} from '@library/shared/_services/client.service';
import {PageService} from '@library/shared/_services/page.service';
import {FireService} from '@library/nxt/_services/fire.service';
import {EThreadContext, MessagingService} from '@library/nxt/_services/messaging.service';
import {OnDestroyPage} from '@library/shared/_inherited/ondestroy.page';
import {CommonModule} from '@angular/common';
import {InputContactsComponent} from '@library/shared/input/input-contacts.component';
import {InputStackedTextComponent} from '@library/shared/input/input-stacked-text.component';
import {AttachmentEditorComponent} from './attachment-editor.component';
import {InputCheckboxComponent} from '@library/shared/input/input-checkbox.component';

import {PopButtonComponent} from '@library/shared/buttons/pop-button.component';
import {InputHtmlComponent} from '@library/shared/input/input-html.component';

import {InputStackedTextAreaComponent} from '@library/shared/input/input-stacked-textarea.component';
import {TabBarComponent} from '@library/nxt/tabs/tab-bar.component';
import {AccountService} from '@library/nxt/_services/account.service';
import {IconsComponent} from '@library/shared/icons/icons.component';
import {InputToggleComponent} from "@library/shared/input/input-toggle.component";
import {RichTextEditorComponent} from "../../_components/rich-text-editor/rich-text-editor.component";

@Component({
    standalone: true,
    imports: [
        CommonModule, InputContactsComponent, ReactiveFormsModule,
        InputStackedTextComponent, AttachmentEditorComponent,
        InputCheckboxComponent, PopButtonComponent,
        InputHtmlComponent, InputStackedTextAreaComponent, TabBarComponent,
        IconsComponent, InputToggleComponent, RichTextEditorComponent
    ],
    selector: 'compose-msg',
    template: `

        <div class="flex p-1 justify-between" *ngIf="thread.medium !== EMedium.CHAT && thread.medium !== EMedium.ARCHIVE">
            <div class="flex">
                <ng-container *ngIf="!group">
                    <button class="btn-clear btn-xs" title="New Message"
                            (click)="startMsg();">
                        <icon name="heroicon-solid-paper-airplane"
                                class="w-3 h-3 rotate-45 mr-1"></icon>
                        <span class="hidden md:block">Message</span>
                    </button>
                    <button class="btn-clear btn-xs" title="Internal Note"
                            (click)="startMsg(EMedium.NOTE);">
                        <icon name="heroicon-outline-chat-alt-2" class="h-3 w-3 mr-1"></icon>
                        <span class="hidden md:block">Note</span>
                    </button>
                    <button class="btn-clear btn-xs" title="Text Message"
                            (click)="startMsg(EMedium.TEXT);">
                        <icon name="heroicon-outline-chat-alt" class="h-3 w-3 mr-1"></icon>
                        <span class="hidden md:block">Text</span>
                    </button>
                </ng-container>
            </div>
            <ng-container *ngIf="thread?._exists">
                <pop-button [items]="threadOptions"
                            btnClass="btn-clear btn-xs"
                            label="Options">
                </pop-button>
            </ng-container>
        </div>

        <ng-container *ngIf="message">
            <div class="m-2 mb-0 pb-0 bg-white">
                <ng-container *ngIf="message?.medium !== EMedium.CHAT && message?.medium !== EMedium.NOTE && (!group || (group && message.recipients?.length))">
                    <tab-bar [collapsible]="false" [tabs]="toTabs"></tab-bar>

                    <div class="w-full flex pt-3" *ngIf="toPath">
                        <input-contacts-autocomplete
                                [placeholder]="'add '+toPath.toUpperCase()"
                                [displayStyle]="message.medium === EMedium.TEXT ? 'phone' : 'email'"
                                [contacts]="(toPath==='to') ? message.recipients : message[toPath]"
                                [medium]="message.medium"
                                (onChange)="update((toPath==='to') ? 'recipients': toPath,$event)">
                        </input-contacts-autocomplete>
                    </div>
                </ng-container>

                <div class="p-2 flex" *ngIf="message.medium===EMedium.EMAIL">
                    <pop-button
                            [label]="(mSvc.templateDocument$|async)?.name || 'Templates'"
                            [items]="templates"
                            btnClass="btn-clear btn-xs"
                            menuPlacement="bottom-start"
                    ></pop-button>

                    <button class="btn-clear btn-xs"
                            [disabled]="!(mSvc.templateDocument$|async)"
                            (click)="previewMessage(true)">
                        Preview
                    </button>
                </div>

                <form *ngIf="form" [formGroup]="form">

                    <input-stacked-text
                            [form]="form"
                            [class]="message.medium===EMedium.EMAIL ? 'block' : 'hidden'"
                            label="Subject"
                            class="w-full"
                            controlName="subject"
                            placeholder="Subject"
                    ></input-stacked-text>

                    <div [class]="(message.medium !== EMedium.TEXT) ? 'inline':'hidden'">
                        <rich-text-editor
                            [label]="(mSvc.templateDocument$|async)?.allow_note ? 'Optional Note...' : 'Start writing message (or drag and drop a file)...'"
                            controlName="messageHtml"
                            [form]="form"
                            [height]="editorHeight"
                            [randomizeFilePath]="true"
                            (onKeyup)="handleKeyUp($event)"
                            [parent]="message"
                            [path]="path"
                        ></rich-text-editor>
                    </div>
                    <div [class]="(message.medium === EMedium.TEXT) ? 'inline':'hidden'">
                        <input-stacked-textarea
                                minHeight="120px"
                                class="w-full"
                                [form]="form"
                                label="Text Message"
                                controlName="message"
                        ></input-stacked-textarea>
                    </div>

                    <p *ngIf="form?.get('message')?.touched && form.get('message')?.hasError('required')"
                       class="px-3 pt-1 italic text-xs text-red-500">
                        Message is required (unless attaching files)
                    </p>

                    <div class="flex w-full">
                        <div class="w-24 mr-2" *ngIf="(uSvc.signature$|async) && message.medium === EMedium.EMAIL">
                            <input-checkbox [form]="form" controlName="signature" label="Signature"></input-checkbox>
                        </div>
                        <div class="w-full">
                            <attachment-editor
                                    [object]="thread?.object"
                                    [hideUploader]="true"
                                    [parent]="message"
                            ></attachment-editor>
                        </div>
                    </div>

                </form>

            </div>
            <div class="flex flex-wrap p-1 w-full" *ngIf="chicklets?.length || almost?.length">
                <span *ngFor="let user of chicklets; let i = index;"
                      class="chicklet flex m-1 bg-dark text-light text-xs">
                    <ng-container *ngIf="!user.label">
                        <strong class="mr-2">{{user.nick_name}}</strong> {{user.name}}
                    </ng-container>
                    <ng-container *ngIf="user.label">
                        <strong>{{user.label}}</strong>
                    </ng-container>
                </span>
                <span *ngFor="let user of almost; let i = index;"
                      class="chicklet flex m-1 bg-gray-200 text-white text-xs">
                    <ng-container *ngIf="!user.label">
                        <strong class="mr-2">{{user.nick_name}}</strong> {{user.name}}
                    </ng-container>
                    <ng-container *ngIf="user.label">
                        <strong>{{user.label}}</strong>
                    </ng-container>
                </span>
            </div>

            <div class="w-full p-2 mx-2 mb-4 flex justify-between">
                <div class="flex">
                    <button class="btn-dark btn-xs md:btn-sm" (click)="save()">
                        Send
                        <icon name="heroicon-solid-paper-airplane" class="w-3 h-3 md:h-4 md:w-4 rotate-45 ml-1"></icon>
                    </button>
<!--                    <button [class]="showAttachments ? 'btn-dark text-light' : 'btn-light text-dark'" (click)="showAttachments=!showAttachments">-->
<!--                        <icon name="heroicon-outline-paper-clip" class="h-5 w-5"></icon>-->
<!--                    </button>-->
                    <button class="btn-light btn-xs md:btn-sm" *ngIf="message.medium === EMedium.EMAIL" (click)="save(true)">
                        Save Draft
                    </button>
                    <button class="btn-light btn-xs md:btn-sm" *ngIf="thread.object?._type !== 'tasks' && thread.object?._type !== 'deployments' && message.medium !== EMedium.CHAT" (click)="discard()">
                        Discard
                    </button>
                </div>
            </div>
        </ng-container>
    `
})
export class ComposeMsgComponent extends OnDestroyPage implements OnChanges {
    @Output() onMessage: EventEmitter<ThreadMessage> = new EventEmitter<ThreadMessage>();
    @Output() onThread: EventEmitter<string> = new EventEmitter<string>();
    @Output() onLoaded: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();
    @Input() message: ThreadMessage;
    @Input() thread: Thread;
    @Input() parent: Base;
    @Input() context: EThreadContext;
    @Input() templateDocument: Document;
    @Input() isTemplateModal: boolean = true;
    @Input() editorHeight: string;
    path:string = `clients/${this.cSvc.client_id}/attachments/`
    group: Group;
    threadOptions: IMenuItem[];
    templates: IMenuItem[];
    form: UntypedFormGroup;
    EMedium = EMedium;
    toTabs: IMenuItem[];
    toPath: string;

    constructor(
        protected cSvc: ClientService,
        public uSvc: UserService,
        private pSvc: PageService,
        private aSvc: AccountService,
        private fSvc: FireService,
        public mSvc: MessagingService,
        private fb: UntypedFormBuilder,
        public ref: ElementRef,
        @Inject('TemplateLoaderService') private tSvc: any
    ) {
        super();
    }

    async ngOnChanges(changes: SimpleChanges) {
        try {

            if (this.thread) {
                this.threadOptions = await this.mSvc.buildThreadOptions(this.thread, this.context);
            }

            if (this.message?.medium && (changes.thread||changes.message||!this.form)) {
                this.form = this.fb.group({
                    subject: [this.message.subject],
                    message: [this.message.message],
                    messageHtml: [this.message?.template_id ? this.message.messageHtml||this.message.message : this.message.html||this.message.messageHtml]
                });

                if (this.uSvc.signature$.getValue() && this.message.medium === EMedium.EMAIL) {
                    this.form.addControl('signature', new UntypedFormControl(true));
                }

                this.templates = [];
                if (this.parent||this.thread.object) {
                    this.templates = await this.tSvc.loadTemplates((this.parent||this.thread.object)._type,this.isTemplateModal);
                }

                if (this.message?.template_id && !this.templateDocument) {
                    let tmpl: IMenuItem = this.templates.find(t => t.value.template_id === this.message.template_id);
                    if (tmpl?.value) {
                        this.mSvc.templateDocument$.next(tmpl.value);
                    }
                }

                this.chicklets = [];
                this.almost = [];
                this.buildToTabs();

                this.form.valueChanges
                    .subscribe(d => {
                        this.autoSave();
                    });

                this.onLoaded.emit(this.ref);
            }

            if (this.mSvc.context$.getValue() instanceof Group) {
                this.group = this.mSvc.context$.getValue();
            } else {
                this.group = null;
            }

        } catch (e) {
            console.warn(e);
        }
    }

    buildToTabs() {
        if (
            this.message
            && this.message?.medium !== EMedium.CHAT
            && this.message?.medium !== EMedium.NOTE
            && (!this.group || (this.group && this.message.recipients?.length))) {

            this.toPath = this.toPath || 'to';
            if (this.message.medium === EMedium.EMAIL) {
                this.toTabs = [
                    {
                        label: `TO (${this.message.recipients?.length})`,
                        active: this.toPath === 'to',
                        click: () => {
                            this.toPath = 'to';
                            this.buildToTabs();
                        }
                    },
                    {
                        label: `CC (${this.message.cc?.length})`,
                        active: this.toPath === 'cc',
                        click: () => {
                            this.toPath = 'cc';
                            this.buildToTabs();
                        }
                    },
                    {
                        label: `BCC (${this.message.bcc?.length})`,
                        active: this.toPath === 'bcc',
                        click: () => {
                            this.toPath = 'bcc';
                            this.buildToTabs();
                        }
                    }
                ];
            } else {
                this.toTabs = null;
            }


        }
    }

    update(p: string, value: Contact[]) {
        this.message[p] = value;
        this.buildToTabs();
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    autoSave(save?:boolean) {
        if (this.thread && this.message && this.form) {

            Object.assign(this.message, this.form.value);
            if (!save) {
                if (this.message.medium === EMedium.TEXT && this.message.message) {
                    save = true;
                } else if (this.message.messageHtml || this.message.subject) {
                    save = true;
                }
            }
            if (save) {
                this.mSvc.saveMsg(this.parent?.id||this.thread.object?.id||this.thread.id, this.message);
            }
            return save;
        }
    }

    async previewMessage(show?: boolean, doImport?: boolean) {
        if (this.form && this.thread && this.message) {
            if (this.mSvc.templateDocument$.getValue()?.template_id) {

                let webhooks: any[] = [];
                try {
                    webhooks = this.cSvc.client$.getValue().config['software']['webhooks']
                } catch (e) {}

                let importOptionNeeded: boolean = (
                    this.mSvc.templateDocument$.getValue()?.metadata?.import
                    && !webhooks?.find(h => h.selected && h.id === this.mSvc.templateDocument$.getValue().data_argument+'s')
                    && doImport === undefined
                    && !this.message.draft
                )

                if (importOptionNeeded) {

                    this.pSvc.alert$.next({
                        title: 'Run Import Before Preview?',
                        message: 'Template will perform a data import unless you select No below.',
                        buttons: [
                            {
                                label: 'No',
                                closeOnClick: true,
                                class: 'btn-dark',
                                click: () => {
                                    this.previewMessage(show, false)
                                }
                            },
                            {
                                label: 'Yes',
                                closeOnClick: true,
                                class: 'btn-light',
                                click: () => {
                                    this.previewMessage(show, true)
                                }
                            }
                        ]
                    })

                } else {

                    this.pSvc.blocking$.next(true);
                    try {

                        let data: any = Object.assign({}, this.form.value);


                        let result = await this.mSvc.generatePreview(
                            data.subject || '',
                            data.messageHtml || data.message || '',
                            data.signature || false,
                            this.message,
                            this.thread,
                            doImport
                        );

                        if (result?.subject) {
                            this.form.get('subject').setValue(result?.subject);
                        }

                        if (show) {
                            this.pSvc.modal$.next({
                                component: HtmlViewerDialog,
                                styles: {width: '100%', height: '100%'},
                                onLoaded: (comp: HtmlViewerDialog) => {
                                    comp.html = result?.html || '';
                                    comp.ngOnChanges();
                                }
                            });
                        }

                        if (this.message) {
                            if (result?.recipients?.length && !this.message.recipients?.length) {
                                this.message.add('recipients', result.recipients?.map(r => new Contact(r)));
                            }
                            if (result?.cc?.length&& !this.message.cc?.length) {
                                this.message.add('cc', result.cc?.map(r => new Contact(r)));
                                this.message.remove('cc', this.message.recipients);
                            }
                            if (result?.bcc?.length&& !this.message.bcc?.length) {
                                this.message.add('bcc', result.bcc?.map(r => new Contact(r)));
                                this.message.remove('bcc', this.message.recipients);
                                this.message.remove('bcc', this.message.cc);
                            }
                        }

                    } catch (e) {
                        console.warn(e);
                        this.pSvc.alert$.next(e);
                    }
                    this.pSvc.blocking$.next(false);

                }

            }
        }
        // this.cdRef.detectChanges();
    }

    async discard() {
        await this.mSvc.discard(this.message);
        this.message = null;
    }

    // matches: string[] = [];
    uBR: any;
    chicklets: any[] = [];
    almost: any[] = [];
    async handleKeyUp(html: string) {
        if (html && (this.message?.medium === EMedium.CHAT || this.message?.medium === EMedium.NOTE)) {
            html = html.replace('&#160;', ' ');
            let chicklets: any[] = [];
            let almost: any[] = [];
            let matches = html.match(/@\w+[^\W+]/g);
            if (matches?.length) {
                if (!this.uBR) {
                    this.uBR = await this.aSvc.getUsersByRole();
                }
                matches.forEach(m => {
                    m = m.toLowerCase();
                    // Find the user with this handle and add them as a follower.
                    for (let u of this.uBR.user.concat(this.uBR.agents||[])) {
                        if (u.nick_name?.toLowerCase() === m) {
                            chicklets.push(u);
                            matches.push(m);
                            break;
                        } else if (u.nick_name?.toLowerCase().match(m)) {
                            almost.push(u);
                            matches.push(m);
                        }
                    }
                    // Look for roles that may match
                    for (let role of this.cSvc.client$.getValue().config.roles) {
                        if (role.id !== 'user' && this.uBR[role.id] && this.uBR[role.id].length) {
                            if (`@${role.label?.toLowerCase()}` === m) {
                                chicklets.push(role);
                                matches.push(m);
                                break;
                            } else if ((`@${role.label?.toLowerCase()}`).match(m)) {
                                almost.push(role);
                                matches.push(m);
                            }
                        }
                    }
                });
                this.chicklets = chicklets;
                this.almost = almost;
            }
        }
    }

    async save(draft?: boolean, confirm?: boolean) {
        let valid: boolean = this.form.valid && (this.form.get('message').value || this.form.get('messageHtml').value || this.message?.files?.length || this.mSvc.templateDocument$.getValue()?.template_id);
        if (this.message) {

            if (valid && !draft && this.message.medium === EMedium.EMAIL && !this.message.subject && !confirm) {
                this.pSvc.notification$.next({
                    title: 'Send With No Subject?',
                    message: 'Click Send to send this email without a subject.',
                    buttons: [
                        {
                            label: 'Cancel'
                        },
                        {
                            label: 'Send w/o Subject',
                            click: () => {
                                this.save(null, true);
                            }
                        }
                    ]
                });
                return;
            }

            // Put @mentions as followers on the MESSAGE (NOT THE THREAD)
            this.message.mentions = this.chicklets;

            if (valid || draft) {

                // Save the thread and message here.
                this.pSvc.loading$.next(true);
                try {
                    this.message.draft = draft || false;
                    await this.saveMsg();
                } catch (e) {
                    console.warn(e);
                    this.pSvc.alert$.next(e);
                }
                this.pSvc.loading$.next(false);

            } else if (!this.form.valid) {
                this.form.markAllAsTouched();
            } else if (!valid) {
                this.pSvc.notification$.next({
                    title: 'Cannot Send!',
                    message: 'Must have a message or attachments, along with your subject. Unless you are sending a templated message.'
                });
            }

        }
    }

    async saveMsg(doImport?: boolean) {

        if (this.message.medium !== EMedium.EMAIL && this.mSvc.templateDocument$.getValue()) {
            this.mSvc.templateDocument$.next(null);
            this.message.subject = '';
        }

        let webhooks: any[] = [];
        try {
            webhooks = this.cSvc.client$.getValue().config['software']['webhooks']||[];
        } catch (e) {}

        let importOptionNeeded: boolean = (
            this.mSvc.templateDocument$.getValue()?.metadata?.import
            && !webhooks?.find(h => h.selected && h.id === this.mSvc.templateDocument$.getValue().data_argument+'s')
            && doImport === undefined
            && !this.message.draft
        )

        if (importOptionNeeded) {

            this.pSvc.alert$.next({
                title: 'Run Import Before Preview?',
                message: 'Template will perform a data import unless you select No below.',
                buttons: [
                    {
                        label: 'No',
                        closeOnClick: true,
                        class: 'btn-dark',
                        click: () => {
                            this.saveMsg(false)
                        }
                    },
                    {
                        label: 'Yes',
                        closeOnClick: true,
                        class: 'btn-light',
                        click: () => {
                            this.saveMsg(true)
                        }
                    }
                ]
            });

        } else if (!this.message.draft && !(this.mSvc.context$.getValue() instanceof Group) && !this.message.recipients?.length && !this.message.cc?.length && !this.message.bcc?.length && (this.message.medium === EMedium.TEXT || this.message.medium === EMedium.EMAIL)) {

            this.pSvc.notification$.next({
                title: 'No Recipients!',
                message: 'Cannot send message that has no recipients.'
            });

        } else {

            let request: IMessagePost = {
                subject: this.message.subject,
                messageHtml: this.message.messageHtml,
                message: this.message.message,
                html: this.message.html,
                signature: this.message.signature,
                draft: this.message.draft || false,
                ref: this.parent?._docRef?.path || this.thread.ref?.path || this.thread.object?._docRef?.path,
                medium: this.message.medium,
                template_id: this.mSvc.templateDocument$.getValue() ? this.mSvc.templateDocument$.getValue().template_id : '',
                recipients: this.message.recipients?.map(r => r.toMinJSON ? r.toMinJSON() : r),
                mentions: this.message.mentions?.map(r => r.toMinJSON ? r.toMinJSON() : r),
                threadId: this.thread.id,
                files: this.message.files?.map(f => f.toJSON ? f.toJSON() : f) || [],
                messageId: this.message.id,
                replyingTo: this.message.replyingTo || null,
                cc: this.message.cc?.map(r => r.toMinJSON ? r.toMinJSON() : r) || [],
                bcc: this.message.bcc?.map(r => r.toMinJSON ? r.toMinJSON() : r) || [],
                import: doImport,
                data: this.message.data,
                skipTracking: this.message.skipTracking
            };

            let tmp = document.createElement("DIV");
            tmp.innerHTML = request.messageHtml;
            request.summary = tmp.textContent || tmp.innerText || "";

            if (request.template_id) {
                let output = await this.mSvc.generatePreview(
                    request.subject,
                    request.messageHtml||request.message,
                    request.signature || false,
                    this.message,
                    this.thread,
                    doImport
                );
                if (output?.subject) {
                    request.subject = output.subject;
                    request.html = output.html;
                }
            }

            let result = await this.mSvc.postMessage(request);
            if (result) {
                this.onThread.emit(result.thread);
                this.onMessage.emit(new ThreadMessage(result.message, this.fSvc.olm));
                this.form.get('subject').setValue('');
                this.form.get('message').setValue('');
                this.form.get('messageHtml').setValue('');
                this.message = null;
                this.chicklets = [];
            }

            if (this.thread?.medium === EMedium.CHAT) {
                await this.mSvc.startMessage(this.parent||this.thread.object, this.thread, [], [], EMedium.CHAT);
            }

        }

    }

    async startMsg(medium?: EMedium) {
        medium = medium || EMedium.EMAIL;
        let recips:any[] = (medium !== EMedium.NOTE) ? this.getRecips() : [];
        let msg = await this.mSvc.startMessage(this.parent||this.thread.object, this.thread, recips, null, medium);
        await this.mSvc.checkForMismatch(msg,this.parent);

    }

    getRecips(): any[] {
        let result: any[] = [];
        let p: string = (this.thread.medium === EMedium.TEXT) ? 'phone': ((this.thread.medium||'').match(/email|web|/)) ? 'email' : '';
        if (p) {
            let c: Contact = this.parent ? (this.parent instanceof Contact) ? this.parent : this.parent['contact'] : null;
            if (!c && this.thread.object) {
                c = (this.thread.object instanceof Contact) ? this.thread.object : this.thread.object['contact'];
            }
            if (c && c[p]) {
                result.push(c);
            }
        }
        if (this.thread.medium === EMedium.TEXT && !result.length) {
            this.pSvc.notification$.next({
                title: 'No Contact Found',
                message: `You will have to enter recipient numbers manually. It is best to make sure existing contact records are updated with phone numbers.`
            });
        }
        return result;
    }

}

