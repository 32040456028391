import {AfterViewChecked, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {CommonModule} from '@angular/common';

import {Quote, Trip, Flight, IFlightDateTime} from '@nxt/model-laro';
import {InternalItineraryLegComponent} from './internal-itinerary-leg.component';

@Component({
    standalone: true,
    imports: [
        CommonModule, InternalItineraryLegComponent
    ],
    selector: 'internal-itinerary-table',
    template: `
        <div class="w-full text-left border-none" style="box-shadow:none;background:none !important;">
            <div class="grid grid-cols-4">
                <div class="px-4 py-2 whitespace-nowrap text-right col-span-1">
                    <div class="text-sm text-gray-900">
                        {{ firstLegTimes?.takeoffDate }}
                    </div>
                    <div class="text-sm text-gray-700">
                        {{ firstLegTimes?.takeoffTime }} {{ firstLegTimes?.takeoffTZ }}
                    </div>
                    <div class="text-xs italic text-gray-400">{{ tailNumbers  }}</div>
                </div>
                <td class="px-4 py-2 col-span-3">
                    <div class="text-sm text-gray-900">
                        <ng-container *ngFor="let leg of trip?.legs; let x = index">
                            <ng-container *ngIf="!hideHiddenEmpty || !leg.isEmptyLeg || leg.active">
                                <div class="flex mt-2">
                                    <div class="mr-2 text-xs italic text-gray-400">leg {{ x + 1 }}</div>
                                    <div>
                                        <span class="text-sm text-gray-900">{{leg.departureAirport?.icao}}</span> -
                                        <span class="text-xs text-gray-500">{{leg.arrivalAirport?.icao}}</span>
                                        <span *ngIf="leg.isEmptyLeg" class="text-xs italic text-gray-400">(empty)</span>
                                        <span *ngIf="!leg.isEmptyLeg && !leg.active"
                                              class="text-xs italic text-gray-400">(hidden)</span>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>
                </td>
            </div>
        </div>
    `
})
export class InternalItineraryTableComponent implements OnChanges {
    @Input() trip: Quote | Trip | Flight;
    @Input() hideHiddenEmpty: boolean;
    firstLegTimes: IFlightDateTime;
    tailNumbers: string;

    ngOnChanges(changes:SimpleChanges) {
        if (this.trip) {
            try {
                this.firstLegTimes = this.trip.firstLegTimes(this.hideHiddenEmpty);
                this.tailNumbers = this.trip.allTailNumbers(this.hideHiddenEmpty);
            } catch (e) {
                console.warn(e);
            }
        }
    }
}
