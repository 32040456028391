import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {EPriceItemTypes, IPrice, PriceItem, Trip} from '@nxt/model-laro';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {IconsComponent} from '@library/shared/icons/icons.component';
import {PageService} from '@library/shared/_services/page.service';
import {getSID} from '@nxt/model-core';

@Component({
    selector: 'laro-price-breakdown',
    standalone: true,
    imports: [
        CurrencyPipe,
        FormsModule,
        CommonModule,
        IconsComponent
    ],
    template: `

        <div class="p-4" *ngIf="price?.error">
            <div class="rounded-md bg-yellow-50 p-4">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <icon name="heroicon-solid-exclamation"class="h-5 w-5 text-yellow-400"></icon>
                    </div>
                    <div class="ml-3">
                        <h3 class="text-sm font-medium text-yellow-800">No Price Calculated</h3>
                        <div class="mt-2 text-sm text-yellow-700">
                            <p>{{price.error}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-col mt-3">
            <div class="-my-2 md:overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle inline-block md:min-w-full sm:px-6 lg:px-8">
                    <div class="shadow md:overflow-hidden border-b border-gray-200 sm:rounded-lg">
                        <table class="w-full divide-y divide-gray-200">
                            <tbody>
                            <ng-container *ngFor="let item of price?.items; let i = index;">
                                <tr *ngIf="!item.tax&&!item.discount" [class]="!(i%2) ? 'bg-white' : 'bg-gray-50'">
                                    <td class="px-6 py-4 md:whitespace-nowrap text-sm font-medium text-dark">
                                        {{item.label}}
                                        <div class="text-gray-300"
                                             *ngIf="path==='estimate' && (item.multiplier || (item.multiplier && item.denomination))">
                                            <i *ngIf="item.type!==EPriceItemTypes.FLAT && item.type!==EPriceItemTypes.PERCENT">{{ item.quantity }} {{item.denomination ? item.denomination : ''}}</i>
                                            <i *ngIf="item.type===EPriceItemTypes.PERCENT">{{item.multiplier}}%</i>
                                            <span *ngIf="item.denomination">
                                                    @ {{ item.multiplier | currency:'USD':'symbol'}}{{item.denomination ? '/' + item.denomination : ''}}
                                                </span>
                                            <span class="ml-2" *ngIf="item.notes">
                                                    ({{item.notes}})
                                                </span>
                                        </div>
                                    </td>
                                    <td scope="col"
                                        class="px-6 py-4 flex whitespace-nowrap text-sm font-medium text-gray-900 text-right italic">
                                        <span *ngIf="!editable">{{item.amount | currency:'USD':'symbol'}}</span>
                                        <input type="number"
                                               *ngIf="editable"
                                               class="input-default"
                                               [(ngModel)]="item.amount"
                                               (ngModelChange)="save()"
                                               placeholder="Amount"/>
                                        <button class="btn-clear btn-xs ml-3" (click)="removeItem(i)">
                                            <icon name="heroicon-outline-minus" class="h-5 w-5"></icon>
                                        </button>
                                    </td>
                                </tr>
                            </ng-container>
                            <tr *ngIf="editable" [class]="!(((price?.items?.length||0)+1)%2) ? 'bg-white' : 'bg-gray-50'">
                                <td class="px-6 py-4 md:whitespace-nowrap text-sm font-medium text-dark">
                                    <input type="text"
                                           class="input-default"
                                           [(ngModel)]="newPriceItem.label"
                                           placeholder="Description"/>
                                </td>
                                <td scope="col"
                                    class="px-6 py-4 flex whitespace-nowrap text-sm font-medium text-gray-900 text-right italic">
                                    <input type="number"
                                           class="input-default"
                                           [(ngModel)]="newPriceItem.amount"
                                           placeholder="Amount"/>
                                    <button class="btn-clear btn-xs ml-3" (click)="addNewItem()">
                                        <icon name="heroicon-outline-plus" class="h-5 w-5"></icon>
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                            <tfoot class="bg-gray-50">
                                <tr *ngIf="price.total">
                                    <th scope="col"
                                        class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Subtotal
                                    </th>
                                    <th scope="col"
                                        class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {{price.totalPreTax | currency:'USD':'symbol'}}
                                    </th>
                                </tr>
                                <tr *ngIf="price.totalDiscounts">
                                    <th scope="col"
                                        class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Discounts
                                    </th>
                                    <th scope="col"
                                        class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {{price.totalDiscounts | currency:'USD':'symbol'}}
                                    </th>
                                </tr>
                                <tr *ngIf="price.total">
                                    <th scope="col"
                                        class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        Total Tax
                                    </th>
                                    <th scope="col"
                                        class="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        {{price.totalTax | currency:'USD':'symbol'}}
                                    </th>
                                </tr>
                                <tr *ngIf="price.total">
                                    <th scope="col"
                                        class="px-6 py-3 text-right text-xs font-medium text-black uppercase tracking-wider">
                                        Total
                                    </th>
                                    <th scope="col"
                                        class="px-6 py-3 text-right text-xs font-medium text-black uppercase tracking-wider">
                                        {{price.total | currency:'USD':'symbol'}}
                                    </th>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    `
})
export class LaroPriceBreakdown implements OnChanges {
    @Output() onChange: EventEmitter<IPrice> = new EventEmitter<IPrice>();
    @Input() price: IPrice;
    @Input() editable: boolean;
    newPriceItem: PriceItem = new PriceItem();
    EPriceItemTypes = EPriceItemTypes;

    constructor(
        private pSvc: PageService
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.price) {
            this.price.items = this.price.items || [];
        }
    }

    async save() {
        this.pSvc.loading$.next(true);
        try {
            this.price.items.forEach((item,n) => {
                if (item.amount === 0) {
                    this.price.items[n].multiplier = 0;
                }
            });
            this.onChange.emit(this.price);
        } catch (e) {
            this.pSvc.alert$.next(e);
        }
        this.pSvc.loading$.next(false);
    }

    removeItem(i: number) {
        this.price.items.splice(i, 1);
        this.save();
    }

    addNewItem() {
        if (this.newPriceItem?.label && this.newPriceItem?.amount) {
            this.newPriceItem.id = getSID(5);
            this.newPriceItem.multiplier = 1;
            this.newPriceItem.quantity = this.newPriceItem.amount;
            this.newPriceItem.taxable = true;
            this.newPriceItem.tax = false;
            this.newPriceItem.discount = false;
            this.price.items.push(this.newPriceItem.toMinJSON());
            this.save();
            this.newPriceItem = new PriceItem();
        }
    }
}
