import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {EMedium, IMenuItem, prettifyPhone, Thread, ThreadMessage} from '@nxt/model-core';

import {EThreadContext, MessagingService} from '@library/nxt/_services/messaging.service';
import {ClientService} from '@library/shared/_services/client.service';
import {CommonModule} from '@angular/common';
import {MessageMediumIcon} from '@library/nxt/list/message-medium-icon';
import {PopButtonComponent} from '@library/shared/buttons/pop-button.component';
import {FilterMatches} from './filter-matches';
import {ThreadRightClickMenu} from './thread-right-click.menu';
import {PageService} from '@library/shared/_services/page.service';
import {AttachmentViewerComponent} from './attachment-viewer.component';
import {AvatarComponent} from '@library/shared/avatars/avatar.component';
import {AvatarStackComponent} from '@library/shared/avatars/avatar.stack.component';
import {ObjectTypeIcon} from '@library/nxt/list/object-type-icon';
import {FollowersComponent} from '@library/nxt/followers/followers.component';
import {FromComponent} from './_subcomponents/from.component';
import {ContextComponent} from './_subcomponents/context.component';
import {SubjectComponent} from './_subcomponents/subject.component';
import {MessageBodyComponent} from './_subcomponents/message-body.component';
import {AgentsFollowersTimeComponent} from './_subcomponents/agents-followers-time.component';
import {Subscription} from 'rxjs';
import {FireService} from '@library/nxt/_services/fire.service';
import {OnDestroyPage} from '@library/shared/_inherited/ondestroy.page';

@Component({
    standalone: true,
    imports: [
        CommonModule, MessageMediumIcon, PopButtonComponent, FilterMatches, ThreadRightClickMenu,
        AttachmentViewerComponent, AvatarComponent, AvatarStackComponent, ObjectTypeIcon, FollowersComponent, FromComponent, ContextComponent, SubjectComponent, MessageBodyComponent, ContextComponent, ContextComponent, AgentsFollowersTimeComponent, ContextComponent
    ],
    selector: 'message-item-homepage',
    template: `
        <ng-container [ngSwitch]="message?.medium">
            <div *ngSwitchDefault
                 [id]="message?.id"
                 [style]="itemStyle"
                 [class]="itemClass"
                 (contextmenu)="rightClick($event)"
            >
                <div class="flex flex-col place-items-center p-2">
                    <message-medium-icon class="w-4 h-4 text-gray-500" [item]="message"></message-medium-icon>
                </div>
                <div class="grow flex flex-col p-2" (click)="onSelect.emit(message)">
                    <from [parent]="message"></from>
                    <context [parent]="message"></context>
                    <subject [parent]="message"></subject>
                    <message-body [parent]="message"></message-body>
                    <filter-matches [parent]="message"></filter-matches>
                    <agents-followers-time [parent]="message" [timestamp]="message.date"></agents-followers-time>
                </div>

            </div>
        </ng-container>
    `
})
export class MessageItemHomepage extends OnDestroyPage implements OnChanges {
    @Output() onSelect: EventEmitter<ThreadMessage> = new EventEmitter<ThreadMessage>();
    @Input() parent: any;
    @Input() message: ThreadMessage;
    @Input() selected: boolean;
    @Input() context: EThreadContext;
    thread: Thread;
    EMedium = EMedium;
    EThreadContext = EThreadContext;
    prettifyPhone = prettifyPhone;
    itemStyle: string;
    sub: Subscription;

    get itemClass(): string {
        if (this.message.medium === EMedium.NOTE || this.message.medium === EMedium.CHAT) {
            return this.selected ? 'flex p-1 bg-yellow-200 hover:bg-yellow-300' :  'flex p-1 bg-yellow-100 hover:bg-yellow-200';
        } else {
            return this.selected ? 'flex p-1 bg-blue-100 hover:bg-blue-200' : this.message.unassigned ? 'flex p-1 bg-orange-100 hover:bg-orange-200' :  'flex p-1';
        }
    }

    constructor(
        public cSvc: ClientService,
        public mSvc: MessagingService,
        private pSvc: PageService,
        private fSvc: FireService
    ) {
        super();
    }

    async ngOnChanges(changes: SimpleChanges) {
        this.itemStyle = (this.message?.unread) ? 'border-left: 2px solid red' : '';
        if (this.message) {
            this.watchMessage();
        }
    }

    async watchMessage() {
        if (this.message._docRef && !this.message._exists) {
            this.sub?.unsubscribe();
            this.sub = this.fSvc.watchObject(this.message._docRef, this.d$)
                .subscribe(([m,d]) => {
                    this.message = m as ThreadMessage;
                });
        }
    }

    log(msg: ThreadMessage) {
        console.log(msg._docRef.path);
    }

    async rightClick(e: MouseEvent) {
        // Prevent default right-click behavior FIRST.
        e.preventDefault();
        // Load the related thread.
        if (this.message?.tRef?.get && (!this.thread || this.thread._docRef?.path !== this.message.tRef?.path)) {
            this.thread = new Thread( (await this.message.tRef.get()), this.mSvc.olm );
        }
        this.pSvc.modal$.next({
            component: ThreadRightClickMenu,
            onLoaded: (comp: ThreadRightClickMenu) => {
                comp.thread = this.thread;
                comp.message = this.message;
                comp.event = e;
                comp.context = this.context;
                comp.init();
            }
        })
    }
}
