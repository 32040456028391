import {
    Component,
    Input,
    OnInit,
    ViewChild,
    ViewContainerRef,
    Inject,
    AfterViewInit,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InputImageComponent} from '@library/nxt/images/input-image.component';
import {IconsComponent} from '@library/shared/icons/icons.component';
import {CloudFile, ThreadMessage} from '@nxt/model-core';
import {ClientService} from '@library/shared/_services/client.service';
import {FireService} from '@library/nxt/_services/fire.service';
import {PageService} from '@library/shared/_services/page.service';
import {OnDestroyPage} from '@library/shared/_inherited/ondestroy.page';
import {Subscription} from 'rxjs';
import {AttachmentModalComponent} from './attachment-modal.component';

@Component({
    selector: 'attachment-editor',
    standalone: true,
    imports: [CommonModule, InputImageComponent, IconsComponent],
    template: `
        <ng-container #attachmentContainer></ng-container>
        <ng-template #defaultTemplate>
            <div class="mt-2">
                <span *ngFor="let file of files; let i = index;"
                      class="cursor-pointer inline-flex rounded-full items-center py-0.5 pl-2.5 pr-1 text-sm font-medium bg-dark-100 text-dark-700">
                    <a (click)="openAttachment(i)">{{ file.name }}</a>
                    <button type="button" (click)="removeFile(file)"
                            class="flex-shrink-0 ml-0.5 h-4 w-4 rounded-full inline-flex items-center justify-center text-dark-400 hover:bg-dark-200 hover:text-dark-500 focus:outline-none focus:bg-dark-500 focus:text-white">
                        <span class="sr-only">Remove</span>
                        <icon name="heroicon-outline-x" class="h-2 w-2"></icon>
                    </button>
                </span>
                <ng-container *ngIf="!hideUploader">
                    <input-image path="clients/{{cSvc.client_id}}/attachments/{{parent?._type}}/{{parent?.id}}/"
                                 label="Attachments"
                                 [crop]="false"
                                 [collapse]="collapse"
                                 [multiple]="true"
                                 (uploadComplete)="updateFiles($event)">
                    </input-image>
                </ng-container>
            </div>
        </ng-template>
    `
})
export class AttachmentEditorComponent extends OnDestroyPage implements AfterViewInit, OnChanges {
    @Input() parent: any;
    @Input() collapse: boolean;
    @Input() object?: any;
    @Input() hideUploader: boolean = false;
    @ViewChild('attachmentContainer', {read: ViewContainerRef, static: false}) attachmentContainer: ViewContainerRef;
    @ViewChild('defaultTemplate', {static: false}) defaultTemplate;
    attachmentComp: AttachmentEditorComponent;
    files: CloudFile[];
    sub: Subscription;

    constructor(
        protected fSvc: FireService,
        public cSvc: ClientService,
        public pSvc: PageService,
        @Inject('LazyComponents') protected lazyComponents: any[]
    ) {
        super();
    }

    async ngOnChanges(changes: SimpleChanges) {
        // This is for listening for files on drafts, which are loaded from localStorage,
        // and therefore do not have a _docRef at this point.
        if (this.parent instanceof ThreadMessage && !this.parent._docRef?.path && this.parent?.id) {
            this.parent._docRef = this.cSvc.client$.getValue()._docRef.collection('threadsmsgs').doc(this.parent.id);
        }
        if (this.parent?._docRef?.path) {
            this.watchFiles();
        }
    }

    watchFiles() {
        this.sub?.unsubscribe();
        this.sub = this.fSvc.watchColl(`${this.parent._docRef.path}/files`, null, this.d$)
            .subscribe(res => {
                this.files = res?.map(d => new CloudFile(d)) || [];
            });
    }

    async ngAfterViewInit() {
        if(this.defaultTemplate || this.defaultTemplate) {
            await this.loadComponent();
        }
    }

    async loadComponent() {
        const selector: string = `${this.cSvc.name_key.toLowerCase()}-attachment-editor`;

        if (!this.attachmentComp || this.attachmentComp['_id'] !== selector) {
            let component: any;
            for (let c of this.lazyComponents) {
                for (let key of Object.keys(c)) {
                    if (key === selector) {
                        component = c[key];
                        break;
                    }
                }
            }

            if (!component?.loader) {
                this.loadDefaultTemplate();
                return;
            } else {
                try {
                    let imported = await component.loader();
                    const key = Object.keys(imported).find(key => !key.match(/Module/));
                    if (!key) {
                        this.loadDefaultTemplate();
                        return;
                    }
                    let module = imported[key];
                    let ref = this.attachmentContainer.createComponent(module);
                    this.attachmentComp = ref.instance as AttachmentEditorComponent;
                    this.attachmentComp['_id'] = selector;
                    this.attachmentComp.parent = this.parent;
                    this.attachmentComp.collapse = this.collapse;
                    this.attachmentComp.object =this.object;
                } catch (e) {
                    console.error(`Failed to load component ${selector}:`, e);
                    this.loadDefaultTemplate();
                }
            }
        } else {
            this.attachmentComp.parent = this.parent;
            this.attachmentComp.collapse = this.collapse;
            this.attachmentComp.object =this.object;
        }
    }

    loadDefaultTemplate() {
        if (this.defaultTemplate) {
            this.attachmentContainer.createEmbeddedView(this.defaultTemplate);
        } else {
            console.error('defaultTemplate is undefined');
        }
    }

    async updateFiles(files: any[]) {
        if (!this.parent.files) {
            this.parent.files = [];
        }
        for (let file of files) {
            try {
                let att: CloudFile = new CloudFile(file);
                att.setID();
                this.parent.files.push(att);
                if (!this.parent._docRef) {
                    this.parent._docRef = this.cSvc.client$.getValue()._docRef.collection(this.parent._type).doc(this.parent.id);
                }
                await att.save(this.parent);
            } catch (e) {
                console.error(e);
            }
        }
    }

    async removeFile(file: CloudFile) {
        if (file._docRef && file._exists && file.delete) {
            await file.delete();
        }
        await this.fSvc.delete(file.url);
    }

    openAttachment(index: number) {
        this.pSvc.modal$.next({
            component: AttachmentModalComponent,
            onLoaded: (comp: any) => {
                comp.files = this.files;
                comp.contact = this.parent.contact;
                comp.saveToParent = this.cSvc.name_key === 'EQIP';
                comp.parent = this.parent?.object;
                comp.currentIndex = index;
            },
            styles: {
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                'z-index': '1000',
                width: '80vw',
                height: '90vh',
                display: 'flex',
                flexDirection: 'column',
            },
        });
    }
}
