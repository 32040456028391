import {NgModule, Component} from '@angular/core';
import {ActivatedRoute, RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';

import {PageService} from '../_services/page.service';
import {FlexComponent} from './flex.component';
import {ClientService} from '../_services/client.service';

@Component({
    template: `
        <flex-page-component [page]="(pSvc.page$|async)?.header" *ngIf="data?.header"></flex-page-component>
        <flex-page-component [page]="pSvc.page$|async"></flex-page-component>
        <main *ngIf="(pSvc.pageNotFound$|async) && !(pSvc.pageLoading$|async)" class="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8">
            <div class="text-center">
                <p class="text-base font-semibold text-accent-600">404</p>
                <h1 class="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">Page not found</h1>
                <p class="mt-6 text-base leading-7 text-gray-600">Sorry, we couldn’t find the page you’re looking
                    for.</p>
                <div class="mt-10 flex items-center justify-center gap-x-6">
                    <a href="#"
                       class="rounded-md bg-accent-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-accent-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-accent-600">Go
                        back home</a>
                    <a href="mailto:{{(cSvc.c$|async)?.email}}" class="text-sm font-semibold text-gray-900">Contact support <span aria-hidden="true">&rarr;</span></a>
                </div>
            </div>
        </main>
        <flex-page-component [page]="(pSvc.page$|async)?.footer" *ngIf="data?.footer"></flex-page-component>
    `
})
export class FlexPage {
    data: any

    constructor(
        public pSvc: PageService,
        private aR: ActivatedRoute,
        public cSvc: ClientService
    ) {

        this.aR.data.subscribe(d => {
            this.data = d;
        });


    }
}


@NgModule({
    imports: [
        CommonModule, FlexComponent,
        RouterModule.forChild([{path: '', component: FlexPage}])
    ],
    declarations: [FlexPage]
})
export class FlexPageModule {
}
