"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Contact = void 0;
const model_core_1 = require("@nxt/model-core");
const Flight_1 = require("./Flight");
const index_1 = require("../index");
class Contact extends model_core_1.Contact {
    get pax_type() {
        if (!this.t) {
            if (this.birthdate) {
                let age = (Date.now() - new Date(this.birthdate).getTime()) / (86400000 * 365);
                if (age < 3) {
                    this.t = Flight_1.EPaxType.INFANT;
                }
                else if (age < 13) {
                    this.t = Flight_1.EPaxType.CHILD;
                }
                else {
                    this.t = Flight_1.EPaxType.ADULT;
                }
            }
            else {
                return Flight_1.EPaxType.ADULT;
            }
        }
        return this.t;
    }
    set pax_type(t) {
        this.t = t;
    }
    get account_ids() {
        var _a;
        return ((_a = this.accounts) === null || _a === void 0 ? void 0 : _a.length) ? this.accounts.map(i => i.id) : [];
    }
    constructor(data, olMap) {
        super(data, olMap || index_1.olm);
        this.birthdate = '';
        this.weight = null;
        this.gender = '';
        this.passport = '';
        this.accounts = [];
        this._m = ['name', 'first_name', 'last_name', 'email', 'counts', 'company', 'phone', 'type', 'birthdate', 'passport', 'weight', 'account_ids'];
        this.t = null;
        data = (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, Contact);
        if (data === null || data === void 0 ? void 0 : data.name) {
            this.name = data.name;
        }
    }
    async loadAll(opts, stack, p) {
        opts = opts || { default_client_id: '' };
        opts.loadAllFn = opts.loadAllFn || index_1.loadAll;
        opts.olm = opts.olm || index_1.olm;
        return super.loadAll(opts, stack, p);
    }
    async save(parent) {
        var _a;
        if (((_a = this.third_party) === null || _a === void 0 ? void 0 : _a.length) && this.third_party instanceof Array) {
            for (let i = this.third_party.length - 1; i >= 0; i--) {
                let id = this.third_party[i];
                if (!id.match(/-/) && this.third_party.find(item => item.match(`-${id}`))) {
                    this.third_party.splice(i, 1);
                }
            }
        }
        return super.save(parent);
    }
    toJSON() {
        let r = super.toJSON();
        r.account_ids = this.account_ids;
        return r;
    }
    async toSearchJSON(opts) {
        var _a;
        let r = await super.toSearchJSON(opts);
        r.role = ((_a = this.metadata) === null || _a === void 0 ? void 0 : _a.role) || '';
        return r;
    }
}
exports.Contact = Contact;
index_1.olm.contacts = (ref, map) => {
    return new Contact(ref, map);
};
