"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Schedule = void 0;
const model_core_1 = require("@nxt/model-core");
const index_1 = require("../index");
class Schedule extends model_core_1.Base {
    get name() {
        var _a, _b, _c, _d, _e, _f, _g;
        if (!this.n) {
            if (!this.type) {
                this.n = 'Nothing Scheduled';
            }
            else if ((_a = this.metadata) === null || _a === void 0 ? void 0 : _a.departureICAO) {
                this.n = '';
                if ((_b = this.metadata) === null || _b === void 0 ? void 0 : _b.tripNumber) {
                    this.n = `Trip #${(_c = this.metadata) === null || _c === void 0 ? void 0 : _c.tripNumber}: `;
                }
                this.n += `${(_d = this.metadata) === null || _d === void 0 ? void 0 : _d.departureICAO} > ${(_e = this.metadata) === null || _e === void 0 ? void 0 : _e.arrivalICAO}`;
            }
            else {
                this.n = `${(_f = this.metadata) === null || _f === void 0 ? void 0 : _f.container}/${(_g = this.metadata) === null || _g === void 0 ? void 0 : _g.dutyEventCategory}`;
            }
        }
        return this.n;
    }
    set name(n) {
        this.n = n;
    }
    get description() {
        var _a, _b, _c;
        if (!this.d) {
            if ((_a = this.metadata) === null || _a === void 0 ? void 0 : _a.accounts) {
                this.d = `${(_b = this.metadata) === null || _b === void 0 ? void 0 : _b.accounts.join(', ')}`;
            }
            else {
                this.d = ((_c = this.metadata) === null || _c === void 0 ? void 0 : _c.subject) || '';
            }
        }
        return this.d;
    }
    set description(d) {
        this.d = d;
    }
    constructor(data, olMap) {
        super('schedule', 13);
        this.aircraft_id = '';
        this.start = null;
        this.end = null;
        this.type = '';
        this.status = '';
        this.metadata = {};
        (0, model_core_1.setObjectProperties)(this, data, olMap || index_1.olm, Schedule);
    }
}
exports.Schedule = Schedule;
index_1.olm.schedule = (ref, map) => {
    return new Schedule(ref, map);
};
