export const laroOperatorLazyComponents: any = {
    'home-page-component': {
        t: 'Levo Home Page Hero',
        d: 'Main homepage title section for levo.aero',
        type: 'any',
        loader: () => import('@library/consumer/laro/home-hero/laro-home-hero'),
        config: () => import('@library/consumer/laro/home-hero/laro-home-hero.config')
    },
    'benefits-component': {
        t: 'Benefits',
        d: 'Grid listing benefits of the product',
        type: 'any',
        loader: () => import('@library/consumer/laro/benefits/laro-benefits'),
        editor: () => import('@library/consumer/laro/benefits/laro-benefits.editor')
    },
    'home-page-feature-toggle': {
        t: 'Levo Home Page Features Toggle',
        d: 'Toggle-able feature listing for homepage',
        type: 'any',
        loader: () => import('@library/consumer/laro/features-toggle/laro-features-toggle'),
        editor: () => import('@library/consumer/laro/features-toggle/laro-features-toggle-editor')
    },
    'get-started-component': {
        t: 'Get Started',
        d: 'Call-to-action section for levo.aero',
        type: 'any',
        loader: () => import('@library/consumer/laro/get-started/get-started'),
        config: () => import('@library/consumer/laro/get-started/get-started.config')
    },
    'operator-signup-component': {
        t: 'Operator Sign-up/Interest Form',
        d: 'Form for operators to submit when interested in levo.',
        type: 'any',
        loader: () => import('@library/consumer/laro/sign-up/operator-signup'),
        config: () => import('@library/consumer/laro/sign-up/operator-signup.config')
    },
    'transparent-nav-component': {
        t: 'Transparent Navbar',
        d: 'Transparent navbar/header for public-facing levo.aero pages.',
        type: 'header',
        loader: () => import('./_components/header/transparent-nav/transparent-nav')
    },
    'laro-operator-solid-header': {
        t: 'Solid Navbar',
        d: 'Solid navbar/header for public-facing levo.aero pages.',
        type: 'header',
        loader: () => import('./_components/header/solid-header/laro-operator-solid-header'),
        config: () => import('./_components/header/solid-header/laro-operator-solid-header.config')
    },
    'laro-operator-header': {
        t: 'Updated Consumer Navbar',
        d: 'Solid navbar/header for public-facing levo.aero pages. Replaces transparent & old solid navbars.',
        type: 'header',
        loader: () => import('./_components/header/laro-operator-header'),
        config: () => import('./_components/header/laro-operator-header.config')
    },
    'old-levo-footer-component': {
        t: 'Black Footer',
        d: 'Plain black footer for levo.aero.',
        type: 'footer',
        loader: () => import('@library/consumer/laro/footer/laro-footer.component'),
        config: () => import('@library/consumer/laro/footer/laro-footer-config.component')
    },
    'charter-company-button': {
        t: 'Charter Company Join component',
        d: 'Hardcoded component for charter companies to join up',
        loader: () => import('../_components/charter-company-button/charter-company-button'),
        config: () => import('../_components/charter-company-button/charter-company-button-config')
    },
    'laro-nxt-order-details': {
        t: 'Order details component used in LEVO only.',
        client: 'LARO',
        loader: () => import('../operator/_components/orders/laro-order-details.component')
    },
    'laro-nxt-product-details': {
        t: 'Product editor component used in LEVO only.',
        client: 'LARO',
        loader: () => import('../operator/_components/orders/laro-product-details.component')
    }
}
